import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getSalesService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/sales${query}`, token);
};

export const searchSalesService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/sales/search${query}`, token);
};

export const updateSalesService = async (
  salesId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/sales/${salesId}`,
    data,
    token
  );
};

export const addSalesService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/sales`, data, token);
};

export const deleteSalesService = async (salesId: string, data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/sales/delete/${salesId}`, data, token);
};
