import { User } from "../Application/user";
import { Birth } from "./birth";
import { Pig } from "./pig";
import i18n from "../../i18n";

export interface DeathColumn {
  id: "date" | "category" | "pigOrBirth" | "gender" | "reason" | "action";
  label: string;
  minWidth?: number;
  align?: "center";
}

export const deathColumns: DeathColumn[] = [
  { id: "date", label: i18n.t("dateOfDeath"), align: "center" },
  { id: "category", label: i18n.t("category"), align: "center" },
  { id: "pigOrBirth", label: i18n.t("pigOrBirth"), align: "center" },
  { id: "gender", label: i18n.t("gender"), align: "center" },
  { id: "reason", label: i18n.t("reason"), align: "center" },
  { id: "action", label: i18n.t("action"), align: "center" },
];

export interface DeathRow {
  date: string;
  category: string;
  pigOrBirth: string;
  gender: string;
  reason: string;
  action: any;
}

export interface Death {
  _id: string;
  category: string;
  pig: Pig;
  birth: Birth;
  gender: string;
  reason: string;
  date: string;
  observation: string;
  createdBy?: User;
  createdAt: string;
  updatedAt: string;
}
