import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { primaryBlue, primaryLightBlue } from "../assets/colors/colors";
import { appActions } from "../store/app";
import styles from "./index.module.scss";

export interface NavItemProps {
  selected: boolean;
  nav: any;
  index: number;
}

const NavItem = (props: NavItemProps) => {
  const dispatch = useDispatch();
  return (
    <ListItem
      selected={props.selected}
      classes={{ root: styles.root, selected: styles.listItemSelected }}
      onClick={() => dispatch(appActions.setNavIndex(props.index))}
    >
      <ListItemIcon
        style={{
          color: props.selected ? primaryLightBlue : primaryBlue,
          minWidth: "40px",
        }}
      >
        {props.nav.icon}
      </ListItemIcon>
      <ListItemText>
        <Typography className={styles.listItemText}>
          {props.nav.label}
        </Typography>
      </ListItemText>
    </ListItem>
  );
};

export default NavItem;
