import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getFeedingsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/feeding${query}`, token);
};

export const searchFeedingsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/feeding/search${query}`, token);
};

export const updateFeedingService = async (
  feedingId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/feeding/${feedingId}`,
    data,
    token
  );
};

export const addFeedingService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/feeding`, data, token);
};

export const deleteFeedingService = async (
  feedingId: string,
  token: string
) => {
  return await HttpRequest.delete(`${SERVER_URL}/feeding/${feedingId}`, token);
};
