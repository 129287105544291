import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getTreatmentsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/treatment${query}`, token);
};

export const searchTreatmentsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/treatment/search${query}`, token);
};

export const updateTreatmentService = async (
  treatmentId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/treatment/${treatmentId}`,
    data,
    token
  );
};

export const addTreatmentService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/treatment`, data, token);
};

export const deleteTreatmentService = async (
  treatmentId: string,
  token: string
) => {
  return await HttpRequest.delete(
    `${SERVER_URL}/treatment/${treatmentId}`,
    token
  );
};
