import { User } from "../Application/user";
import i18n from "../../i18n";

export interface DiseaseColumn {
  id: "name" | "type" | "severity" | "action";
  label: string;
  minWidth?: number;
  align?: "center";
}

export const diseaseColumns: DiseaseColumn[] = [
  { id: "name", label: i18n.t("diseaseName"), align: "center" },
  { id: "type", label: i18n.t("category"), align: "center" },
  { id: "severity", label: i18n.t("severity"), align: "center" },
  { id: "action", label: i18n.t("action"), align: "center" },
];

export interface DiseaseRow {
  name: string;
  type: string;
  severity: string;
  action: any;
}

export interface DiseaseCategory {
  name:
    | "Indwara z'uruhu"
    | "Infections"
    | "Ibisebe"
    | "Impiswi"
    | "Inzoka"
    | "Ibyorezo"
    | "Indwara zo mu mubiri";
}

export const diseaseCategories: DiseaseCategory[] = [
  { name: "Indwara z'uruhu" },
  { name: "Infections" },
  { name: "Ibisebe" },
  { name: "Impiswi" },
  { name: "Inzoka" },
  { name: "Ibyorezo" },
  { name: "Indwara zo mu mubiri" },
];

export interface DiseaseSeverity {
  name: "Mbi bikabije" | "Mbi cyane" | "Mbi byo kwihanganirwa" | "Ntacyo itwaye";
}

export const diseaseSeverities: DiseaseSeverity[] = [
  { name: "Mbi bikabije" },
  { name: "Mbi cyane" },
  { name: "Mbi byo kwihanganirwa" },
  { name: "Ntacyo itwaye" },
];

export interface Disease {
  _id: string;
  name: string;
  category: string;
  description: string;
  severity: string;
  victimAge: Number;
  symptoms: Symptom[];
  treatment: string;
  prevention: string;
  createdBy?: User;
  createdAt: string;
  updatedAt: string;
}

export interface Symptom {
  _id: string;
  name: string;
  createdBy?: User;
  createdAt: string;
  updatedAt: string;
}
