import { User } from "../Application/user";
import i18n from "../../i18n";

export interface ExpenseColumn {
  id: "date" | "description" | "category" | "amount" | "action";
  label: string;
  minWidth?: number;
  align?: "center";
}

export const expenseColumns: ExpenseColumn[] = [
  { id: "date", label: i18n.t("date"), align: "center" },
  { id: "description", label: i18n.t("description"), align: "center" },
  { id: "category", label: i18n.t("category"), align: "center" },
  { id: "amount", label: i18n.t("amount"), align: "center" },
  { id: "action", label: i18n.t("action"), align: "center" },
];

export interface ExpenseRow {
  date: string;
  description: string;
  category: string;
  amount: number;
  action: any;
}

// Wages, vet services, medecine, feeding...
export interface ExpenseCategory {
  id: "wages" | "vetServices" | "medecine" | "feeding" | 'other';
  name: string;
}

export const expenseCategories: ExpenseCategory[] = [
  { id: "wages", name: "Imishahara" },
  { id: "vetServices", name: "Veterinary Services" },
  { id: "medecine", name: "Ubuzima, Imiti" },
  { id: "feeding", name: "Ibyokurya" },
  { id: "other", name: "Ibindi" },
];

export interface Expense {
  _id: string;
  category: string;
  description: string;
  amount: number;
  paid: number;
  date: string;
  paymentMethod: string;
  createdBy?: User;
  createdAt: string;
  updatedAt: string;
}

export interface PaymentMethod {
  name: 'Momo' | 'Cash' | 'Cheque' | 'Bank',
  id: 'momo' | 'cash' | 'cheque' | 'bank'
}

export const paymentMethods: PaymentMethod[] = [
  {
    name: 'Cash',
    id: 'cash',
  },
  {
    name: 'Momo',
    id: 'momo',
  },
  {
    name: 'Cheque',
    id: 'cheque',
  },
  {
    name: 'Bank',
    id: 'bank',
  },
];
