import { User } from "../Application/user";
import { Reproduction } from "./reproduction";
import i18n from "../../i18n";

export interface BirthColumn {
  id:
    | "date"
    | "sow"
    | "birth"
    | "remaining"
    | "boarsSowsRemaining"
    | "days"
    | "cost"
    | "action";
  label: string;
  minWidth?: number;
  align?: "center";
}

export const birthColumns: BirthColumn[] = [
  { id: "date", label: i18n.t("date"), align: "center" },
  { id: "sow", label: i18n.t("sow"), align: "center" },
  { id: "birth", label: i18n.t("birth"), align: "center" },
  { id: "remaining", label: i18n.t("remaining"), align: "center" },
  { id: "boarsSowsRemaining", label: i18n.t("boarsSowsRemaining"), align: "center" },
  { id: "days", label: i18n.t("days"), align: "center" },
  { id: "cost", label: i18n.t("cost"), align: "center" },
  { id: "action", label: i18n.t("action"), align: "center" },
];

export interface BirthRow {
  date: string;
  sow: string;
  birth: number;
  remaining: number;
  boarsSowsRemaining: string;
  days: number;
  cost: string;
  action: any;
}

export interface Birth {
  _id: string;
  reproduction: Reproduction;
  date: string;
  boars: number;
  race: String;
  sows: number;
  weightEstimate: number;
  costEstimate: number;
  boarsLeft: number;
  sowsLeft: number;
  grown: boolean;
  createdBy?: User;
  createdAt: string;
  updatedAt: string;
}
