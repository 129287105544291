import { Box } from "@mui/material";
import ViewDrawer, { DetailItem } from "../../../components/ViewDrawer";
import { useTranslation } from "../../../i18n";
import { Expense } from "../../../interfaces/Expenses/expense";
import styles from "../../index.module.scss";

export interface ExpenseViewProps {
  open: boolean;
  onClose: Function;
  expense: Expense;
}

const ExpenseView = (props: ExpenseViewProps) => {
  const { t } = useTranslation();
  const expense: Expense = props.expense;
  let count = 1;

  return (
    <ViewDrawer open={props.open} onClose={props.onClose}>
      <Box className={styles.item_details}>
        <DetailItem
          index={count}
          key={count++}
          label={t("description")}
          value={expense.description}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("expenseCategory")}
          value={expense.category}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("amount")}
          value={expense?.amount}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("paid")}
          value={expense?.paid}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("date")}
          value={expense.date}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("paymentMethod")}
          value={expense.paymentMethod}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("createdBy")}
          value={expense?.createdBy}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("createdAt")}
          value={expense?.createdAt.substring(0, 10)}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("updatedAt")}
          value={expense?.updatedAt.substring(0, 10)}
        />
      </Box>
    </ViewDrawer>
  );
};

export default ExpenseView;
