import { requestFailed, setResponseAction } from "../../../utils/functions";
import { appActions } from "../../app";
import { expensesActions } from "../../expenses";
import { getDocumentsService } from "./documentsService";

// GET ALL
export const getDocumentsAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getDocumentsService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(expensesActions.setDocuments(res.data));
        dispatch(appActions.setCount({ count: res.count }));
        dispatch(expensesActions.setFetchedDocuments(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
