import { List, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import DashboardIcon from "@mui/icons-material/Dashboard";
import styles from "./index.module.scss";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import store from "store";
import { useDispatch, useSelector } from "react-redux";
import NavItem from "./NavItem";
import { authActions } from "../store/auth";
import { useTranslation } from "../i18n";
import { logoutAction } from "../store/auth/authActions";

const NavMenu = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const pathName = location.pathname;
  const select = useSelector((state: any) => state.app.navIndex);
  let index = -1;

  const generalNavItems = [
    {
      label: t("dashboard"),
      icon: <DashboardIcon style={{ color: "currentColor" }} />,
      path: "/home",
    },
    {
      label: t("staff"),
      icon: <PeopleAltIcon style={{ color: "currentColor" }} />,
      path: "/staff",
    },
    {
      label: t("forms"),
      icon: <RecentActorsIcon style={{ color: "currentColor" }} />,
      path: "/forms",
    },
    {
      label: t("report"),
      icon: <MoneyOffIcon style={{ color: "currentColor" }} />,
      path: "/report",
    },
    {
      label: t("pigs"),
      icon: <PersonIcon style={{ color: "currentColor" }} />,
      path: "/pigs/pigs",
    },
    {
      label: t("expenses"),
      icon: <PersonIcon style={{ color: "currentColor" }} />,
      path: "/expenses/expenses",
    },
    {
      label: t("business"),
      icon: <PersonIcon style={{ color: "currentColor" }} />,
      path: "/business/sales",
    },
    {
      label: t("logout"),
      icon: <ExitToAppIcon style={{ color: "currentColor" }} />,
      path: "/login",
    },
  ];

  return (
    <>
      <List>
        {generalNavItems.map((nav) => {
          index++;
          return (
            <Link
              key={index}
              to={nav.path}
              className={styles.link}
              onClick={() => {
                if (nav.path === "/login") {
                  dispatch(logoutAction(() => navigate("/login")));
                }
              }}
            >
              <NavItem
                nav={nav}
                selected={pathName.includes(nav.path.split("/")[1])}
                index={index}
              />
            </Link>
          );
        })}
      </List>
    </>
  );
};

export default NavMenu;
