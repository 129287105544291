import { Avatar, Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import DashboardCard from "../../General/dashboard/DashboardCard";
import DiamondIcon from "@mui/icons-material/Diamond";
import styles from "../../index.module.scss";
import {
  deleteRed,
  primaryBlue,
  primaryColor,
  primaryGreen,
} from "../../../assets/colors/colors";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Storage from "../../../utils/storage";
import { useTranslation } from "../../../i18n";
import PieChart from "../../../components/charts/PieChart";
import { AppState } from "../../../store/app";
import { getPigsStatsAction } from "../../../store/pigSlice/pigActions";
import { PigState } from "../../../store/pigSlice";

const PigsStats = () => {
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const appState: AppState = useSelector((state: any) => state.app);
  const pigState: PigState = useSelector(
    (state: any) => state.liveStock
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (!pigState.fetchedPigs) {
      dispatch(getPigsStatsAction("", token));
    }
  }, [pigState.fetchedPigs]);

  const data = {
    labels: appState.pigStats?.gender?.map((gender: any) =>
      t(`${gender.name}`)
    ),
    datasets: [
      {
        label: "My First Dataset",
        data: appState.pigStats?.gender?.map((gender: any) => gender.number),
        backgroundColor: ["#F4B3D1", primaryBlue],
        hoverOffset: 4,
      },
    ],
  };

  const ageData = {
    labels: appState.pigStats?.age?.map((age: any) => t(`${age.name}`)),
    datasets: [
      {
        label: "My First Dataset",
        data: appState.pigStats?.age?.map((age: any) => age.number),
        backgroundColor: [deleteRed, primaryGreen],
        hoverOffset: 4,
      },
    ],
  };

  return (
    <>
      {!appState.isFetchingDashboard && appState?.pigStats?.pigs >= 0 && (
        <Grid className={styles.statsCardContainer} container spacing={2}>
          <Grid item xs={3}>
            <DashboardCard
              title="Amatungo Yose"
              value={appState?.pigStats?.pigs}
              accentColor="#aeb5eb"
              icon={<DiamondIcon style={{ color: primaryColor }} />}
            ></DashboardCard>
          </Grid>
          <Grid item xs={3}>
            <Box className={styles.dashboardCard}>
              <Box className={styles.dashboardCard_top}>
                <Typography className={styles.dashboardCard_label}>
                  Igitsina
                </Typography>
              </Box>
              <div style={{ width: "100%" }}>
                <div
                  style={{ margin: "5px auto", height: "7rem", width: "7rem" }}
                >
                  <PieChart chartData={data} />
                </div>
              </div>
              <Box className={styles.dashboardCard_top}>
                <Box className={styles.dashboardCard_text_metric}>
                  <Avatar
                    style={{
                      height: "8px",
                      width: "8px",
                      backgroundColor: "#F4B3D1",
                    }}
                  >
                    {" "}
                  </Avatar>
                  <Typography className={styles.dashboardCard_text}>
                    Isekurume: {appState?.pigStats?.gender[0]?.number}
                  </Typography>
                </Box>
                <Box className={styles.dashboardCard_text_metric}>
                  <Avatar
                    style={{
                      height: "8px",
                      width: "8px",
                      backgroundColor: primaryBlue,
                    }}
                  >
                    {" "}
                  </Avatar>
                  {appState?.pigStats?.gender && (
                    <Typography className={styles.dashboardCard_text}>
                      {/* Inyagazi: 22 */}
                      Inyagazi: {appState?.pigStats?.gender[1]?.number}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box className={styles.dashboardCard}>
              <Box className={styles.dashboardCard_top}>
                <Typography className={styles.dashboardCard_label}>
                  Ubukure
                </Typography>
              </Box>
              <div style={{ width: "100%" }}>
                <div
                  style={{ margin: "5px auto", height: "7rem", width: "7rem" }}
                >
                  <PieChart chartData={ageData} />
                </div>
              </div>
              <Box className={styles.dashboardCard_top}>
                <Box className={styles.dashboardCard_text_metric}>
                  <Avatar
                    style={{
                      height: "8px",
                      width: "8px",
                      backgroundColor: primaryGreen,
                    }}
                  >
                    {" "}
                  </Avatar>
                  <Typography className={styles.dashboardCard_text}>
                    Akuze: {appState?.pigStats?.age[1]?.number}
                  </Typography>
                </Box>
                <Box className={styles.dashboardCard_text_metric}>
                  <Avatar
                    style={{
                      height: "8px",
                      width: "8px",
                      backgroundColor: deleteRed,
                    }}
                  >
                    {" "}
                  </Avatar>
                  <Typography className={styles.dashboardCard_text}>
                    Abana: {appState?.pigStats?.age[0]?.number}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box className={styles.dashboardCard}>
              <Box className={styles.dashboardCard_top}>
                <Stack direction="row">
                  <Avatar
                    style={{
                      height: "2.5rem",
                      width: "2.5rem",
                      backgroundColor: "#bfc2f5",
                      margin: "0 0.5rem",
                    }}
                  >
                    <DiamondIcon style={{ color: primaryBlue }} />
                  </Avatar>
                  <Typography className={styles.dashboardCard_label}>
                    Ubwoko
                  </Typography>
                </Stack>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {appState?.pigStats?.race.map((race: any) => (
                  <Box
                    key={race?.name}
                    className={styles.dashboardCard_text_metric}
                  >
                    <Avatar
                      style={{
                        height: "8px",
                        width: "8px",
                        backgroundColor: primaryGreen,
                      }}
                    >
                      {" "}
                    </Avatar>
                    <Typography className={styles.dashboardCard_text}>
                      {`${race?.name}: ${race?.number}`}
                    </Typography>
                  </Box>
                ))}
                {/* <Box className={styles.dashboardCard_text_metric}>
                  <Avatar
                    style={{
                      height: "8px",
                      width: "8px",
                      backgroundColor: primaryGreen,
                    }}
                  >
                    {" "}
                  </Avatar>
                  <Typography className={styles.dashboardCard_text}>
                    Duroc: 21
                  </Typography>
                </Box>
                <Box className={styles.dashboardCard_text_metric}>
                  <Avatar
                    style={{
                      height: "8px",
                      width: "8px",
                      backgroundColor: deleteRed,
                    }}
                  >
                    {" "}
                  </Avatar>
                  <Typography className={styles.dashboardCard_text}>
                    Pietrain: 21
                  </Typography>
                </Box>
                <Box className={styles.dashboardCard_text_metric}>
                  <Avatar
                    style={{
                      height: "8px",
                      width: "8px",
                      backgroundColor: "#bfc2f5",
                    }}
                  >
                    {" "}
                  </Avatar>
                  <Typography className={styles.dashboardCard_text}>
                    Large White: 21
                  </Typography>
                </Box>
                <Box className={styles.dashboardCard_text_metric}>
                  <Avatar
                    style={{
                      height: "8px",
                      width: "8px",
                      backgroundColor: primaryGreen,
                    }}
                  >
                    {" "}
                  </Avatar>
                  <Typography className={styles.dashboardCard_text}>
                    Land Race: 21
                  </Typography>
                </Box> */}
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
      {appState.isFetchingDashboard && (
        <Box className={styles.dashboard_skeleton_cards}>
          <Skeleton className={styles.dashboard_skeleton_card} />
          <Skeleton className={styles.dashboard_skeleton_card} />
          <Skeleton className={styles.dashboard_skeleton_card} />
          <Skeleton className={styles.dashboard_skeleton_card} />
        </Box>
      )}
    </>
  );
};

export default PigsStats;
