import { Avatar, Box } from "@mui/material";
import ViewDrawer, { DetailItem } from "../../../components/ViewDrawer";
import { useTranslation } from "../../../i18n";
import { Pig } from "../../../interfaces/Pig/pig";
import styles from "../../index.module.scss";

export interface PigViewProps {
  open: boolean;
  onClose: Function;
  pig: Pig;
}


const PigView = (props: PigViewProps) => {
  const { t } = useTranslation();
  const pig: Pig = props.pig;
  let count = 1;
  console.log("ttttttttttttt ", props.pig);

  return (
    <ViewDrawer open={props.open} onClose={props.onClose}>
      <Box className={styles.item_details}>
        <Box className={styles.center}>
          <Avatar
            style={{
              height: "7rem",
              width: "7rem",
            }}
          >
            <img
              className={styles.profilePicture}
              src={pig?.photo?.url}
              alt="pic"
            />
          </Avatar>
        </Box>
        <DetailItem
          index={count}
          key={count++}
          label={t("earTag")}
          value={pig?.earTag}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("gender")}
          value={t(`${pig?.gender}`)}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("father")}
          value={t(`${pig?.father?.earTag}`)}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("mother")}
          value={t(`${pig?.mother?.earTag}`)}
        />
        {/* <DetailItem
          index={count}
          key={count++}
          label={t("photo")}
          value={pig?.photo}
        /> */}
        <DetailItem
          index={count}
          key={count++}
          label={t("createdBy")}
          value={pig?.createdBy}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("createdAt")}
          value={pig?.createdAt.substring(0, 10)}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("updatedAt")}
          value={pig?.updatedAt.substring(0, 10)}
        />
      </Box>
    </ViewDrawer>
  );
};

export default PigView;
