import { Box } from "@mui/material";
import ViewDrawer, { DetailItem } from "../../../components/ViewDrawer";
import { useTranslation } from "../../../i18n";
import { Client } from "../../../interfaces/Business/client";
import styles from "../../index.module.scss";

export interface clientViewProps {
  open: boolean;
  onClose: Function;
  client: Client;
}

const ClientView = (props: clientViewProps) => {
  const client: Client = props.client;
  const { t } = useTranslation();
  let count = 1;

  return (
    <ViewDrawer open={props.open} onClose={props.onClose}>
      <Box className={styles.item_details}>
      <DetailItem
          index={count}
          key={count++}
          label={t("name")}
          value={client?.name}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("contact")}
          value={client?.contact}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("tinNumber")}
          value={client?.tinNumber}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("createdBy")}
          value={client?.createdBy}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("createdAt")}
          value={client?.createdAt.substring(0, 10)}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("updatedAt")}
          value={client?.updatedAt.substring(0, 10)}
        />
      </Box>
    </ViewDrawer>
  );
};

export default ClientView;
