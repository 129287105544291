import { User } from "../Application/user";
import { Pig } from "../Pig/pig";
import { Client } from "./client";
import i18n from "../../i18n";

export interface SalesColumn {
  id: "date" | "client" | "pig" | "weight" | "price" | "action";
  label: string;
  minWidth?: number;
  align?: "center";
}

export const salesColumns: SalesColumn[] = [
  { id: "date", label: i18n.t("date"), align: "center" },
  { id: "client", label: i18n.t("client"), align: "center" },
  { id: "pig", label: i18n.t("pig"), align: "center" },
  { id: "weight", label: i18n.t("weight"), align: "center" },
  { id: "price", label: i18n.t("price"), align: "center" },
  { id: "action", label: i18n.t("action"), align: "center" },
];

export interface SalesRow {
  date: string;
  client: string;
  pig: string;
  weight: string;
  price: string;
  action: any;
}

export interface Sale {
  _id: string;
  pig: Pig;
  date: string;
  weight: number;
  unitPrice: number;
  client: Client;
  createdBy?: User;
  createdAt: string;
  updatedAt: string;
}
