import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getVaccinationsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/vaccination${query}`, token);
};

export const searchVaccinationsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/vaccination/search${query}`, token);
};

export const updateVaccinationService = async (
  vaccinationId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/vaccination/${vaccinationId}`,
    data,
    token
  );
};

export const addVaccinationService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/vaccination`, data, token);
};

export const deleteVaccinationService = async (
  vaccinationId: string,
  token: string
) => {
  return await HttpRequest.delete(
    `${SERVER_URL}/vaccination/${vaccinationId}`,
    token
  );
};
