import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
} from "@mui/material";
import styles from "./index.module.scss";
import FormInputs from "./inputs/FormInputs";
import FormHeader from "./FormHeader";
import { useSelector } from "react-redux";
import { useTranslation } from "../i18n";

interface GenericFormProps {
  open: boolean;
  close: Function;
  title: string;
  formElements: any;
  onSubmit: Function;
  styles?: string;
  confirmText?: string;
}

const GenericForm = (props: GenericFormProps) => {
  const isSubmitting = useSelector((state: any) => state.app.isSubmitting);
  const { t } = useTranslation();

  return (
    <Dialog
      classes={{
        paper:
          props?.styles === "vertical"
            ? styles.genericFormVertical
            : styles.genericForm,
      }}
      open={props.open}
      onClose={() => props.close()}
    >
      <FormHeader title={props.title} close={props.close} />
      <DialogContent>
        <Box className={styles.genericForm_column}>
          {props.formElements.map((element: any, index: number) => {
            return (
              <FormInputs key={index} element={element} style={props?.styles} />
            );
          })}
        </Box>
        <Box className={styles.center}>
          {isSubmitting && (
            <Box className={styles.form_action}>
              <Button className={styles.confirmButton} variant="contained">
                <CircularProgress
                  size={30}
                  color="inherit"
                  className={styles.circularProgress}
                />
              </Button>
            </Box>
          )}

          {!isSubmitting && (
            <Box className={styles.form_action}>
              <Button
                onClick={() => props?.close()}
                className={styles.form_cancelButton}
                variant="contained"
              >
                {t("cancel")}
              </Button>
              <Button
                onClick={() => props?.onSubmit()}
                className={styles.form_confirmButton}
                variant="contained"
              >
                {props.confirmText || t("save")}
              </Button>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default GenericForm;
