import { Box } from "@mui/material";
import ViewDrawer, { DetailItem } from "../../../components/ViewDrawer";
import { useTranslation } from "../../../i18n";
import { Birth } from "../../../interfaces/Pig/birth";
import styles from "../../index.module.scss";

export interface ReproductionViewProps {
  open: boolean;
  onClose: Function;
  birth: Birth;
}

const ReproductionView = (props: ReproductionViewProps) => {
  const { t } = useTranslation();
  const birth: Birth = props.birth;
  let count = 1;

  return (
    <ViewDrawer open={props.open} onClose={props.onClose}>
      <Box className={styles.item_details}>
        <DetailItem
          index={count}
          key={count++}
          label={t("boar")}
          value={birth?.reproduction?.boar?.earTag}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("sow")}
          value={birth?.reproduction?.sow?.earTag}
        />
          <DetailItem
            index={count}
            key={count++}
            label={t("date")}
            value={birth?.date.substring(0, 10)}
          />
          <DetailItem
          index={count}
          key={count++}
          label={t("numberOfBoars")}
          value={birth?.boars}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("numberOfSows")}
          value={birth?.sows}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("birthWeight")}
          value={birth?.weightEstimate}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("createdBy")}
          value={birth?.createdBy}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("createdAt")}
          value={birth?.createdAt.substring(0, 10)}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("updatedAt")}
          value={birth?.updatedAt.substring(0, 10)}
        />
      </Box>
    </ViewDrawer>
  );
};

export default ReproductionView;
