import { expensesActions } from "..";
import {
  exportExcel,
  requestFailed,
  setResponseAction,
} from "../../../utils/functions";
import { appActions } from "../../app";
import { getItemsService } from "../items/itemService";
import { getFeedingsService, searchFeedingsService } from "./feedingService";

// GET ALL
export const getFeedingsAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getFeedingsService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        // dispatch(expensesActions.setFeedings(res.data));
        // dispatch(appActions.setCount({ count: res.count }));
        // dispatch(expensesActions.setFetchedFeedings(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err: any) {
      dispatch(setResponseAction(err));
      console.log("Mrdd ", err.message);
    }
  };
};

// EXPORT EXCEL
export const exportFeedingsAction = (
  query: string,
  token: string,
  excelName: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsExportingExcel(true));
      const res = await getFeedingsService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        var data = res.data.map((birth: any) => {
          return {
            ...birth,
            date: birth.date.substring(0, 10),
            createdAt: birth.createdAt.substring(0, 10),
            updatedAt: birth.updatedAt.substring(0, 10),
          };
        });
        exportExcel(data, excelName);
      }
      dispatch(appActions.setIsExportingExcel(false));
    } catch (err) {
      console.log(err);
    }
  };
};

//SEARCH
export const searchFeedingAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await searchFeedingsService(query, token);
      console.log("resssss ", res);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(expensesActions.setFeedings(res.data));
        dispatch(appActions.setCount({ count: res.count }));
        dispatch(expensesActions.setFetchedFeedings(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const fetchItemsAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setFetchingFormItems(true));
      const res = await getItemsService(query, token);
      console.log("resssss ", res);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(expensesActions.setItems(res.data));
        dispatch(appActions.setCount({ count: res.count }));
        dispatch(expensesActions.setFetchedItems(true));
      }
      dispatch(appActions.setFetchingFormItems(false));
    } catch (err) {
      console.log(err);
    }
  };
};
