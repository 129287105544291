import { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import store from "store";
import { useSelector } from "react-redux";
import PrintIcon from "@mui/icons-material/Print";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import styles from "../../../components/index.module.scss";
import log from "../../../assets/images/logoo.png";
import { useTranslation } from "../../../i18n";
import { LiveStockState } from "../../../store/pig";
import { PigWeight } from "../../../interfaces/Pig/weight";
import { AppState } from "../../../store/app";

const WeightsCard = (props: any) => {
  const [date, setDate] = useState(
    new Date().toLocaleString().substring(0, 17)
  );
  const { t } = useTranslation();
  const liveStockState: LiveStockState = useSelector((state: any) => state.liveStock);
  const appState: AppState = useSelector((state: any) => state.app);

  return (
    <>
      <Box className={styles.purchasesReceipt}>
        <h4 className={styles.receipt_title}>{props?.title}</h4>
        <Paper elevation={2} className={styles.purchasesReceipt_header}>
          <Box>
            <img className={styles.receipt_logo} src={log} alt="logo" />
          </Box>
          <Box>
            <Typography
              className={`${styles.centerText} ${styles.receipt_text}`}
            >
              {store.get("user")?.farm?.name}
            </Typography>
            <Typography
              className={`${styles.centerText} ${styles.receipt_text}`}
            >
              TEL: {store.get("user")?.farm?.contact}
            </Typography>
            <Typography
              className={`${styles.centerText} ${styles.receipt_text}`}
            >
              TIN: {store.get("user")?.farm?.tinNumber}
            </Typography>
          </Box>
        </Paper>
        <Paper elevation={2} className={styles.receipt_table}>
          {/* {newPurchase?.items[0]?.supplier?._id && (
            <>
              <Typography
                className={`${styles.centerText} ${styles.receipt_text}`}
              >
                Supplier: {newPurchase?.items[0]?.supplier?.name}
              </Typography>
            </>
          )} */}
          <Paper sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
            <Grid container>
              <Grid item xs={9}>
                <Typography
                  className={`${styles.centerText} ${styles.bold} ${styles.receipt_text}`}
                >
                  {t('pig')}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  className={`${styles.centerText} ${styles.bold} ${styles.receipt_text}`}
                >
                  {t('weight')}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          {liveStockState?.pigWeights?.map((pigWeight: PigWeight, index: number) => (
            <WeightCardItem
              key={index}
              index={index}
              pig={pigWeight.pig.earTag}
              weight={pigWeight.weight}
            />
          ))}
        </Paper>
        <Typography className={`${styles.centerText} ${styles.receipt_text}`}>
          Total Weight {liveStockState.pigWeightsTotal}
        </Typography>
        <Typography className={`${styles.centerText} ${styles.receipt_text}`}>
          Done at {date}
        </Typography>
      </Box>
      <Box className={styles.receiptActions}>
        <Button
          className={styles.receipt_print_btn}
          startIcon={<PrintIcon />}
          variant="contained"
        >
          <Typography>Print</Typography>
        </Button>
        {!appState.isSubmitting && (
          <Button
            className={styles.receipt_save_btn}
            startIcon={<CheckCircleOutlineIcon />}
            onClick={() => {
              props?.onSave();
            }}
            variant="contained"
          >
            <Typography>Save</Typography>
          </Button>
        )}
        {appState.isSubmitting && (
          <Button className={styles.receipt_save_btn} variant="contained">
            <CircularProgress
              size={30}
              color="inherit"
              className={styles.circularProgress}
            />
          </Button>
        )}
      </Box>
    </>
  );
};

export interface WeightCardItemProps {
  key: any;
  index: number;
  pig: string;
  weight: number;
}

export const WeightCardItem = (props: WeightCardItemProps) => {
  return (
    <Box className={styles.receiptItem}>
      <Grid container>
        <Grid item xs={9}>
          <Typography className={`${styles.receipt_items_text}`}>{`${
            props.index + 1
          }. ${props.pig}`}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={`${styles.centerText} ${styles.receipt_text}`}>
            {props.weight}Kg
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WeightsCard;
