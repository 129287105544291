import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getReportsService = async (query: any, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/report${query}`, token);
};

export const searchReportService = async (query: any, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/report/search${query}`, token);
};

export const updateReportService = async (
  reportId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/report/${reportId}`,
    data,
    token
  );
};

export const deleteReportService = async (reportId: String, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/report/${reportId}`, token);
};
