import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getVaccinesService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/vaccine${query}`, token);
};

export const searchVaccinesService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/vaccine/search${query}`, token);
};

export const updateVaccineService = async (
  vaccineId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/vaccine/${vaccineId}`,
    data,
    token
  );
};

export const addVaccineService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/vaccine`, data, token);
};

export const deleteVaccineService = async (
  vaccineId: string,
  token: string
) => {
  return await HttpRequest.delete(`${SERVER_URL}/vaccine/${vaccineId}`, token);
};
