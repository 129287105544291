import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
} from "@mui/material";
import styles from "../../../components/index.module.scss";
import FormHeader from "../../../components/FormHeader";
import { useTranslation } from "../../../i18n";
import { useSelector } from "react-redux";
import { Birth } from "../../../interfaces/Pig/birth";
import { Form, Input, Space } from "antd";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";

export interface AssignEarTagsProps {
  open: boolean;
  close: Function;
  birth: Birth;
  styles: string;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

type LayoutType = Parameters<typeof Form>[0]["layout"];

const AssignEarTags = (props: AssignEarTagsProps) => {
  const isSubmitting = useSelector((state: any) => state.app.isSubmitting);
  const { t } = useTranslation();
  const [formLayout, setFormLayout] = useState<LayoutType>("vertical");
  const [form] = Form.useForm();

  console.log("rrrrr ", props.birth);

  const submit = (values: any) => {
    console.log("Submiting", values);
  };

  return (
    <Dialog
      classes={{
        paper:
          props?.styles === "vertical"
            ? styles.genericFormVertical
            : styles.genericForm,
      }}
      open={props.open}
      onClose={() => props.close()}
    >
      <FormHeader title="ADD EAR TAGS / GUCUTSA" close={props.close} />
      <DialogContent>
        <Form
          {...layout}
          layout={formLayout}
          form={form}
          name="control-hooks"
          onFinish={submit}
          className="my_form"
        >
          <Box className={styles.genericForm_column}>
            {Array.from(
              { length: props?.birth?.boarsLeft ?? 1 },
              (_, index) => (
                <Box
                  key={index}
                  className={
                    props?.styles === "horizontal"
                      ? styles.forms_input_horizontal
                      : styles.forms_input_vertical
                  }
                >
                  <Form.Item
                    name={`boar${index + 1}`}
                    label={`Imfizi ${index + 1}`}
                  >
                    <Space.Compact>
                      <Input
                        style={{ width: "80%" }}
                        placeholder={`Iherena ry'imfizi ya ${index + 1}.`}
                      />
                      <Input style={{ width: "20%" }} placeholder="Ibiro" />
                    </Space.Compact>
                  </Form.Item>
                </Box>
              )
            )}
            {Array.from({ length: props?.birth?.sowsLeft ?? 1 }, (_, index) => (
              <Box
                key={index}
                className={
                  props?.styles === "horizontal"
                    ? styles.forms_input_horizontal
                    : styles.forms_input_vertical
                }
              >
                <Form.Item name={`sow${index + 1}`}>
                  <Space.Compact>
                    <Input
                      style={{ width: "80%" }}
                      placeholder={`Iherena ry'inyagazi ya ${index + 1}.`}
                    />
                    <Input style={{ width: "20%" }} placeholder="Ibiro" />
                  </Space.Compact>
                </Form.Item>
              </Box>
            ))}
            <Box className={styles.center}>
              <LoadingButton
                loading={isSubmitting}
                type="submit"
                className={styles.form_confirmButton}
                variant="contained"
              >
                {t("save")}
              </LoadingButton>
            </Box>
          </Box>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default AssignEarTags;
