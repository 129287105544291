import { Badge, Box, Divider, Typography } from "@mui/material";
import styles from "../../index.module.scss";
import { useEffect } from "react";
import "./notifications_style.css";
import { useDispatch, useSelector } from "react-redux";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { getNotificationsAction } from "../../../store/app/notifications/notificationActions";
import Storage from "../../../utils/storage";
import { AppState } from "../../../store/app";
import NotificationItem from "./NotificationItem";
import { Notification } from "../../../interfaces/Application/notification";

const Notifications = () => {
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const appState: AppState = useSelector((state: any) => state.app);

  useEffect(() => {
    dispatch(getNotificationsAction("", token));
  }, []);

  return (
    <Box className={styles.notifications}>
      <Box className="notification_header">
        <Typography className="notification_heading">Notifications</Typography>
        <Badge badgeContent={4} color="primary">
          <NotificationsIcon color="action" />
        </Badge>
      </Box>
      <Divider />
      {appState?.notifications?.map((notification: any, index: number) => (
        <NotificationItem key={index} notification={notification} />
      ))}
    </Box>
  );
};

export default Notifications;
