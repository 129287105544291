import { User } from "../Application/user";
import i18n from "../../i18n";

export interface ClientColumn {
  id: "name" | "contact" | "tinNumber" | "action";
  label: string;
  minWidth?: number;
  align?: "center";
}

export const clientColumns: ClientColumn[] = [
  { id: "name", label: i18n.t("name"), align: "center" },
  { id: "contact", label: i18n.t("contact"), align: "center" },
  { id: "tinNumber", label: i18n.t("tinNumber"), align: "center" },
  { id: "action", label: i18n.t("action"), align: "center" },
];

export interface ClientRow {
  name: string;
  contact: string;
  tinNumber: any;
  action: any;
}

export interface Client {
  _id: string;
  name: string;
  address: string;
  type: string; //farmer, butcher, restaurant...
  contact: string;
  tinNumber: number;
  rating: number;
  createdBy?: User;
  createdAt: string;
  updatedAt: string;
}
