const translationsKin = {
  welcome: "Ikaze",
  forms: "Amafishi",
  dashboard: "Imbonerahamwe",
  staff: "Abakozi",
  search: "Shakisha",
  exportExcel: "Excel",
  pigs: "Amatungo",
  maturePig: "Itungo Rikuze",
  pig: "Itungo",
  pigOrBirth: "Itungo/Nyina w'imbyaro",
  pigAge: "Ubukure bw'itungo (mu mezi)",
  months: "Amezi",
  requiredRounds: "Inshuro ruterwa",
  dosagePerRound: "Dose iterwa",
  vaccinationDesc: "Amabwiriza y'urukingo",
  addVaccine: "Kwandika urukingo rushya",
  addVaccination: "Gukingira amatungo",
  roundCost: "Agaciro",
  piglet: "Icyana / Ibyana",
  addPig: "Andika Itungo",
  updatePig: "Hindura Umwirondoro w'intungo",
  logout: "Gusohoka",
  report: "Raporo",
  addNew: "Ongeramo",
  amount: "Amafaranga",
  paidAmount: "Amafaranga yishyuwe",
  firstName: "Izina Ry'irikrisitu",
  lastName: "Izina Ry'umuryango",
  name: "Izina",
  nationalID: "Indangamuntu",
  email: "Imeli",
  position: "Icyo Akora",
  gender: "Igitsina",
  dob: "Itariki y'amavuko",
  births: "Imbyaro",
  birth: "Urubyaro",
  birthWeight: "Ibiro zivukanye",
  addBirth: "Kwandika Imbyaro",
  updateBirth: "Guhindura amakuru y'urubyaro",
  reproduction: "Imyororokere",
  deaths: "Imfu",
  vaccination: "Gukingira",
  diseases: "Indwara",
  sickness: "Uburwayi",
  diseaseName: "Izina ry'indwara",
  diseasePrevention: "Uko yakwirindwa",
  addDisease: "Andika Indwara Nshya",
  updateDisease: "Hindura amakuru ku ndwara",
  symptoms: "Ibimenyetso",
  feeding: "Kugabura",
  weighting: "Gupima Ibiro",
  weight: "Ibiro",
  business: "Ubucuruzi",
  action: "Ibindi",
  delete: "Siba",
  view: "Reba",
  update: "Hindura",
  cancel: "Hagarika",
  confirmDelete: "Emeza Gusiba",
  confirmDeleteDesc: "Urahamya ko ushaka gusiba",
  deleteSuccess: "basibwe neza!",
  male: "Gabo",
  female: "Gore",
  addStaff: "Kwandika Umukozi",
  addReproduction: "Kwandika Imyororokere",
  updateStaff: "Hindura Umwirondoro w'umukozi",
  updateReproduction: "Hindura Amakuru y'imyororokere",
  save: "Emeza",
  beginingDate: "Itariki yatangiriye akazi",
  filter: "Hitamo",
  boar: "Isekurume",
  numberOfBoars: "Umubare w'isekurume",
  sow: "Inyagazi",
  numberOfSows: "Umubare w'inyagazi",
  boarsLeft: "Umubare w'isekurume zisigaye",
  sowsLeft: "Umubare w'inyagazi zisigaye",
  barrow: "Inkone",
  date: "Itariki",
  reproductionType: "Ubwoko bw'Imyororokere",
  crossBreeding: "Intanga",
  mating: "Kubangurirwa",
  father: "Se",
  mother: "Nyina",
  boarRace: "Ubwoko bw'intanga",
  race: "Ubwoko",
  sperm: "Intanga",
  createdBy: "Yinjijwe Na",
  dateOfBirth: "Itariki y'amavuko",
  dateOfMaturing: "Itariki yo gucuka",
  createdAt: "Igihe Yinjijwe",
  updatedAt: "Ubwo Iheruka Guhindurwa",
  status: "Icyiciro",
  category: "Icyiciro",
  severity: "Ubukana",
  treatment: "Umuti",
  work: "Akazi",
  documentName: "Izina ry'ifishi",
  documentType: "Ubwoko bw'ifishi",
  document: "Ifishi",
  add: "Andika",
  days: "Iminsi",
  client: "Umukiriya",
  estimatedCost: "Agaciro k'itungo",
  paid: "Amafaranga yishyuwe",
  cost: "Igiciro",
  addTreatment: "Ubuzima - Ikibazo cy'itungo",
  communicationDate: "Itariki yo gufatwa",
  requisition: "Requisition",
  quantity: "Umubare",
  addSale: "Gucuruza itungo",
  kgUnitCost: "Igiciro cy'ikiro kimwe",
  dateOfDeath: "Itariki y'urupfu",
  totalPrice: "Total",
  reason: "Impamvu",
  remaining: "Izisigaye",
  boarsSowsRemaining: "Isek | Iny Zisigaye",
  afterNoon: "Umugoroba",
  morning: "Igitondo",
  L: "Litiro",
  Kg: "Kilogram",
  m: "Metero",
  expense: "Depanse",
  paymentMethod: "Uburyo bwo kwishyura",
  sale: "Kugurisha Itungo",
  insuranceCompany: "Assurance",
  insuranceStartDate: "Itariki Assurance itangiriyeho",
  insuranceExpiryDate: "Itariki Assurance izarangirira",
  unitCost: "Unit Cost",
  totalValue: "Total Value",
  room: "Ikiraro",
};

export default translationsKin;
