import { expensesActions } from "..";
import {
  exportExcel,
  requestFailed,
  setResponseAction,
} from "../../../utils/functions";
import { appActions } from "../../app";
import { getBirthsService } from "../../pig/birth/birthService";
import { pigActions } from "../../pigSlice";
import { getPigsService } from "../../pigSlice/pigService";
import { getVaccinesService } from "../vaccine/vaccineService";
import {
  getVaccinationsService,
  searchVaccinationsService,
} from "./vaccinationService";

// GET ALL
export const getVaccinationsAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getVaccinationsService(query, token);

      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(expensesActions.setVaccinations(res.data));
        dispatch(appActions.setCount({ count: res.count }));
        dispatch(expensesActions.setFetchedVaccination(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// EXPORT EXCEL
export const exportVaccinationAction = (
  query: string,
  token: string,
  excelName: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsExportingExcel(true));
      const res = await getVaccinationsService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        exportExcel(res.data, excelName);
      }
      dispatch(appActions.setIsExportingExcel(false));
    } catch (err) {
      console.log(err);
    }
  };
};

//SEARCH
export const searchVaccinationAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await searchVaccinationsService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(expensesActions.setVaccinations(res.data));
        dispatch(appActions.setCount({ count: res.count }));
        dispatch(expensesActions.setFetchedVaccination(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const fetchVaccinationReqAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setFetchingFormItems(true));
      const vaccines = await getVaccinesService(query, token);
      const pigs = await getPigsService(query, token);
      const births = await getBirthsService(query, token);
      if (requestFailed(vaccines)) {
        dispatch(setResponseAction(vaccines, "fail", vaccines.message));
      } else {
        dispatch(expensesActions.setDiseases(vaccines.data));
        dispatch(pigActions.setPigs(pigs.data));
        dispatch(pigActions.setBirths(births.data));
      }
      dispatch(appActions.setFetchingFormItems(false));
    } catch (err) {
      console.log(err);
    }
  };
};
