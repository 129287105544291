import { User } from "../Application/user";
import { Pig } from "../Pig/pig";
import i18n from "../../i18n";
import { Item } from "./item";

export interface FeedingColumn {
  id: "date" | "amount" | "action";
  label: string;
  minWidth?: number;
  align?: "center";
}

export const feedingColumns: FeedingColumn[] = [
  { id: "date", label: i18n.t("date"), align: "center" },
  { id: "amount", label: i18n.t("amount"), align: "center" },
  { id: "action", label: i18n.t("action"), align: "center" },
];

export interface FeedingRow {
  date: string;
  amount: number;
  action: any;
}

export interface FeedingPeriod {
  name: string;
  id: "morning" | "afternoon";
}

export const feedingPeriods: FeedingPeriod[] = [
  { name: i18n.t("morning"), id: "morning" },
  { name: i18n.t("afterNoon"), id: "afternoon" },
];

export interface Feeding {
  _id: string;
  pig: Pig;
  date: string;
  time: string;
  meal: string;
  quantity: number;
  amount: number;
  createdBy?: User;
  createdAt: string;
  updatedAt: string;
}

export interface FeedingItem {
  item: Item,
  price: number,
  twoThree: number,
  four: number,
  five: number,
  sixEight: number,
  eight: number,
  total: number
}
