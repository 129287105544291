import { Box } from "@mui/material";
import ViewDrawer, { DetailItem } from "../../../components/ViewDrawer";
import { useTranslation } from "../../../i18n";
import { Death } from "../../../interfaces/Pig/deaths";
import styles from "../../index.module.scss";

export interface DeathViewProps {
  open: boolean;
  onClose: Function;
  death: Death;
}

const DeathView = (props: DeathViewProps) => {
  const { t } = useTranslation();
  const Death: Death = props.death;
  let count = 1;

  return (
    <ViewDrawer open={props.open} onClose={props.onClose}>
      <Box className={styles.item_details}>
        {/* <DetailItem
          index={count}
          key={count++}
          label={t("boar")}
          value={Death?.Death?.boar?.earTag}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("sow")}
          value={Death?.Death?.sow?.earTag}
        />
          <DetailItem
            index={count}
            key={count++}
            label={t("date")}
            value={Death?.date.substring(0, 10)}
          />
          <DetailItem
          index={count}
          key={count++}
          label={t("numberOfBoars")}
          value={Death?.boars}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("numberOfSows")}
          value={Death?.sows}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("DeathWeight")}
          value={Death?.weightEstimate}
        /> */}
        <DetailItem
          index={count}
          key={count++}
          label={t("createdBy")}
          value={Death?.createdBy}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("createdAt")}
          value={Death?.createdAt.substring(0, 10)}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("updatedAt")}
          value={Death?.updatedAt.substring(0, 10)}
        />
      </Box>
    </ViewDrawer>
  );
};

export default DeathView;
