import { Line } from "react-chartjs-2";
// import Chart from 'chart.js/auto';
//Chart as ChartJS,
import "chart.js/auto";
// import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS } from "chart.js";
import { registerables } from "chart.js";
import { Chart } from "react-chartjs-2";
// import {Chart as ChartJS } from 'chart.js/auto'
ChartJS.register(...registerables);

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
};

const PieChart = (props: any) => {
  let { chartData } = props;
  return (
    <>
      {chartData?.labels?.length > 0 && <Chart type={"pie"} options={options} data={chartData} />}
    </>
  );
};

export default PieChart;
