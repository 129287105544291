import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenericForm from "../../../components/GenericForm";
import { useTranslation } from "../../../i18n";
import { appActions } from "../../../store/app";
import Storage from "../../../utils/storage";
import { addAction, updateAction } from "../../../store/app/appActions";
import { expensesActions } from "../../../store/expenses";
import {
  Expense,
  expenseCategories,
  ExpenseCategory,
  PaymentMethod,
  paymentMethods,
} from "../../../interfaces/Expenses/expense";
import {
  addExpenseService,
  updateExpenseService,
} from "../../../store/expenses/expense/expenseService";

export interface AddExpenseProps {
  expense?: Expense;
}

const AddExpense = (props: AddExpenseProps) => {
  const appState = useSelector((state: any) => state.app);
  const token = Storage.getToken();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({
    description: "" as string,
    category: {} as ExpenseCategory | any,
    categoryText: "" as string | any,
    amount: 0 as number,
    paid: 0 as number,
    paymentMethod: {} as PaymentMethod | any,
    paymentMethodText: "" as string | any,
    date: new Date().toISOString().substring(0, 10) as string,
  });

  React.useEffect(() => {
    if (appState.openForm.action === "update" && props.expense !== undefined) {
      setFormValues({
        description: props.expense.description,
        category: {
          name: props.expense.category,
          id: props.expense.category,
        },
        categoryText: "",
        amount: props.expense.amount,
        paid: props.expense.paid,
        paymentMethod: {
          name: props.expense.paymentMethod,
          id: props.expense.paymentMethod,
        },
        paymentMethodText: "",
        date: props.expense.date.toString().substring(0, 10),
      });
    }
  }, []);

  const onCategoryChanged = (category: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({
        ...formValues,
        category,
        categoryText: "",
      });
    } else {
      setFormValues({
        ...formValues,
        categoryText: category,
        category: null,
      });
    }
  };

  const onPaymentMethodChanged = (paymentMethod: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({ ...formValues, paymentMethod, paymentMethodText: "" });
    } else {
      setFormValues({
        ...formValues,
        paymentMethodText: paymentMethod,
        paymentMethod: null,
      });
    }
  };

  const submit = () => {
    const data = {
      description: formValues.description,
      category: formValues.category.id,
      amount: formValues.amount,
      paid: formValues.paid,
      paymentMethod: formValues.paymentMethod.id,
      date: formValues.date,
    };

    console.log("data ", data);

    if (appState.openForm.action === "add") {
      dispatch(
        addAction(
          addExpenseService,
          data,
          token,
          () => {},
          () => dispatch(expensesActions.setFetchedExpenses(false))
        )
      );
    } else if (
      appState.openForm.action === "update" &&
      props.expense !== undefined
    ) {
      dispatch(
        updateAction(
          updateExpenseService,
          props.expense._id,
          data,
          token,
          () => {},
          () => dispatch(expensesActions.setFetchedExpenses(false))
        )
      );
    }
  };

  const newStaffElement = [
    {
      label: t("reason"),
      placeholder: "Andika Depense iyo ariyo",
      inputComponent: "input",
      required: true,
      value: formValues?.description,
      valueLabel: "description",
      onChange: (description: any) =>
        setFormValues({ ...formValues, description }),
    },
    {
      label: t("category"),
      identifier: "category",
      inputComponent: "select",
      required: true,
      values: expenseCategories,
      value: formValues.category?.name || formValues.categoryText,
      onChange: (category: any, found: boolean) =>
        onCategoryChanged(category, found),
    },
    {
      label: t("amount"),
      placeholder: "Andika amafaranga",
      inputComponent: "input",
      type: "number",
      required: true,
      value: formValues.amount,
      onChange: (amount: number) =>
        setFormValues({ ...formValues, amount, paid: amount }),
    },
    {
      label: t("paidAmount"),
      placeholder: "Andika ayishyuwe",
      inputComponent: "input",
      type: "number",
      value: formValues.paid,
      onChange: (paid: number) => setFormValues({ ...formValues, paid }),
    },
    {
      label: t("paymentMethod"),
      identifier: "payment",
      inputComponent: "select",
      values: paymentMethods,
      value: formValues.paymentMethod?.name || formValues.paymentMethodText,
      onChange: (paymentMethod: any, found: boolean) =>
        onPaymentMethodChanged(paymentMethod, found),
    },
    {
      label: t("date"),
      inputComponent: "date",
      placeholder: "Enter Your Date of Birth",
      required: true,
      value: formValues?.date || "",
      onChange: (date: any) => setFormValues({ ...formValues, date }),
    },
  ];

  return (
    <GenericForm
      open={appState.openForm.open}
      close={() => dispatch(appActions.closeForm())}
      title={
        appState.openForm.action === "add"
          ? `${t("add")} ${t("expense")} `
          : t("updateExpense")
      }
      styles="horizontal"
      formElements={newStaffElement}
      onSubmit={submit}
    />
  );
};

export default AddExpense;
