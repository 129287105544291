import { User } from "../Application/user";
import { Pig } from "../Pig/pig";
import i18n from "../../i18n";

export interface InsuranceColumn {
  id:
    | "insuranceCompany"
    | "pigs"
    | "startDate"
    | "expiryDate"
    | "unitCost"
    | "totalValue"
    | "action";
  label: string;
  minWidth?: number;
  align?: "center";
}

export const insuranceColumns: InsuranceColumn[] = [
  {
    id: "insuranceCompany",
    label: i18n.t("insuranceCompany"),
    align: "center",
  },
  { id: "pigs", label: i18n.t("pigs"), align: "center" },
  { id: "startDate", label: i18n.t("startDate"), align: "center" },
  { id: "expiryDate", label: i18n.t("expiryDate"), align: "center" },
  { id: "unitCost", label: i18n.t("unitCost"), align: "center" },
  { id: "totalValue", label: i18n.t("totalValue"), align: "center" },
  { id: "action", label: i18n.t("action"), align: "center" },
];

export interface InsuranceRow {
  insuranceCompany: string;
  pigs: number;
  startDate: string;
  expiryDate: string;
  unitCost: number;
  totalValue: number;
  action: any;
}

export interface InsuranceCompany {
  name: string;
  id:
    | "soras"
    | "sanlam"
    | "uap"
    | "radiant"
    | "sonarwa"
    | "prime"
    | "old mutual";
}

export const insuranceCompanies: InsuranceCompany[] = [
  { name: "SORAS", id: "soras" },
  { name: "SANLAM", id: "sanlam" },
  { name: "UAP", id: "uap" },
  { name: "RADIANT", id: "radiant" },
  { name: "SONARWA", id: "sonarwa" },
  { name: "PRIME", id: "prime" },
  { name: "OLD MUTUAL", id: "old mutual" },
];

export interface Insurance {
  _id: string;
  insuranceCompany: string;
  pigs: Pig[];
  startDate: string;
  expiryDate: string;
  unitCost: number;
  totalValue: number;
  createdBy?: User;
  createdAt: string;
  updatedAt: string;
}
