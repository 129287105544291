import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenericForm from "../../../components/GenericForm";
import { useTranslation } from "../../../i18n";
import { appActions } from "../../../store/app";
import Storage from "../../../utils/storage";
import {
  Pig,
  PigGender,
  PigRace,
  pigGenders,
  pigRaces,
} from "../../../interfaces/Pig/pig";
import { liveStockActions, LiveStockState } from "../../../store/pig";
import { addAction, updateAction } from "../../../store/app/appActions";
import { Birth } from "../../../interfaces/Pig/birth";
import { fetchBirthsAction } from "../../../store/pigSlice/pigActions";
import { PigState, pigActions } from "../../../store/pigSlice";
import {
  addPigService,
  updatePigService,
} from "../../../store/pigSlice/pigService";

export interface AddReproductionProps {
  pig?: Pig;
}

const AddPig = (props: AddReproductionProps) => {
  const appState = useSelector((state: any) => state.app);
  const token = Storage.getToken();
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null as unknown);
  const liveStockState: LiveStockState = useSelector(
    (state: any) => state.liveStock
  );
  const pigState: PigState = useSelector((state: any) => state.pig);
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({
    earTag: "" as string,
    gender: {} as PigGender | any,
    genderText: "" as string | any,
    birth: {} as any,
    birthText: "" as string,
    photo: "" as string,
    room: "" as string,
    weight: 0 as number,
    cost: 0 as number,
    race: {} as PigRace | any,
    raceText: "" as string,
    date: new Date().toISOString().substring(0, 10) as string,
    dateOfBirth: new Date().toISOString().substring(0, 10) as string,
    sow: {} as Pig | any,
    sowText: "" as string,
    boar: {} as Pig | any,
    boarText: "" as string,
  });

  React.useEffect(() => {
    //get pigs
    if (!pigState.fetchedForm) {
      dispatch(fetchBirthsAction("?limit=200000", token));
    }

    if (appState.openForm.action === "update" && props.pig !== undefined) {
      let mother = pigState.sows.filter(
        (sow: Pig) => sow._id === props?.pig?.mother
      );
      let father = pigState.boars.filter(
        (boar: Pig) => boar._id === props?.pig?.father
      );
      setFormValues({
        earTag: props.pig?.earTag,
        gender: {
          name: t(`${props.pig.gender}`),
          id: props.pig?.gender,
        },
        genderText: "",
        birth: props.pig?.birth
          ? {
              // _id: birth._id,
              // race: birth.race,
              // date: birth.date,
              // cost: birth.costEstimate / (birth.boarsLeft + birth.sowsLeft),
              // name: `${birth?.reproduction.sow.earTag} - ${birth?.date.substring(
              //   0,
              //   10
              // )}`,
            }
          : {},
        birthText: "",
        photo: "" as string,
        room: props?.pig?.room,
        weight: props.pig?.weight,
        cost: props.pig?.costEstimate,
        race: {
          name: props.pig?.race,
        },
        raceText: "",
        date: props.pig?.date.substring(0, 10),
        dateOfBirth: props.pig?.dateOfBirth.substring(0, 10),
        sow: mother[0] || ({} as Pig | any),
        sowText: "" as string,
        boar: father[0] || ({} as Pig | any),
        boarText: "" as string,
      });
    }
  }, []);

  const onBirthChanged = (birth: any, isObject: boolean) => {
    if (isObject) {
      console.log("bbb ", birth);
      setFormValues({
        ...formValues,
        birth,
        dateOfBirth: birth.date.substring(0, 10),
        race: {
          name: birth.race,
        },
        boar: birth.father,
        sow: birth.mother,
        cost: birth.cost,
        birthText: "",
      });
    } else {
      setFormValues({
        ...formValues,
        birthText: birth,
        birth: null,
      });
    }
  };

  const onGenderChanged = (gender: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({ ...formValues, gender, genderText: "" });
    } else {
      setFormValues({ ...formValues, genderText: gender, gender: null });
    }
  };

  const onBoarChanged = (boar: any, isObject: boolean) => {
    console.log("boarr ", boar);
    if (isObject) {
      setFormValues({
        ...formValues,
        boar,
        boarText: "",
      });
    } else {
      setFormValues({
        ...formValues,
        boarText: boar,
        boar: null,
      });
    }
  };

  const onSowChanged = (sow: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({
        ...formValues,
        sow,
        sowText: "",
      });
    } else {
      setFormValues({
        ...formValues,
        sowText: sow,
        sow: null,
      });
    }
  };

  const onRaceChanged = (race: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({
        ...formValues,
        race,
        raceText: "",
      });
    } else {
      setFormValues({
        ...formValues,
        raceText: race,
        race: null,
      });
    }
  };

  const handleFileInputChange = (e: any) => {
    // @ts-ignore: Object is possibly 'null'.
    const file = (e.target as HTMLInputElement).files[0];
    setSelectedFile(file);
  };

  const birthValues: any = pigState?.births?.map((birth: Birth) => {
    return {
      _id: birth._id,
      race: birth.race,
      date: birth.date,
      father: birth?.reproduction?.boar,
      mother: birth?.reproduction?.sow,
      cost: birth.costEstimate / (birth.boarsLeft + birth.sowsLeft),
      name: `${birth?.reproduction?.sow?.earTag} - ${birth?.date?.substring(
        0,
        10
      )}`,
    };
  });

  const submit = () => {
    let formData = new FormData();
    formData.append("earTag", formValues.earTag);
    formData.append("gender", formValues.gender.id);
    if (formValues.birth._id) {
      formData.append("birth", formValues.birth._id);
    }
    if (formValues.boar._id) {
      formData.append("father", formValues.boar._id);
    }
    if (formValues.sow._id) {
      formData.append("mother", formValues.sow._id);
    }
    formData.append("costEstimate", "" + formValues.cost);
    formData.append("race", formValues.race.name);
    formData.append("weight", "" + formValues.weight);
    formData.append("room", formValues.room);
    formData.append("dateOfBirth", "" + formValues.dateOfBirth);
    formData.append("file", selectedFile as File);

    if (appState.openForm.action === "add") {
      dispatch(
        addAction(
          addPigService,
          formData,
          token,
          () => {},
          () => dispatch(pigActions.setFetchedPigs(false))
        )
      );
    } else if (
      appState.openForm.action === "update" &&
      props.pig !== undefined
    ) {
      formData.delete("father");
      // Convert FormData to an array
      const formDataArray = Array.from(formData);

      // Log the values of FormData
      formDataArray.forEach(([name, value]) => {
        console.log(`${name}: ${value}`);
      });
      dispatch(
        updateAction(
          updatePigService,
          props.pig._id,
          formData,
          token,
          () => {},
          () => dispatch(pigActions.setFetchedPigs(false))
        )
      );
    }
  };

  const newStaffElement = [
    {
      label: t("earTag"),
      placeholder: "Andika Iherena",
      inputComponent: "input",
      required: true,
      value: formValues?.earTag,
      valueLabel: "earTag",
      onChange: (earTag: any) => setFormValues({ ...formValues, earTag }),
    },
    {
      label: t("gender"),
      identifier: "gender",
      inputComponent: "select",
      required: true,
      values: pigGenders,
      value: formValues.gender?.name || formValues.genderText,
      onChange: (gender: any, found: boolean) => onGenderChanged(gender, found),
    },
    {
      label: t("birth"),
      identifier: "birth",
      inputComponent: "select",
      required: true,
      loading: pigState.fetchingForm,
      values: birthValues,
      value: formValues.birth?.name || formValues.birthText,
      valueLabel: "sow",
      onChange: (reproduction: any, found: boolean) =>
        onBirthChanged(reproduction, found),
    },
    {
      label: t("weight"),
      placeholder: "Ibiro",
      inputComponent: "input",
      type: "number",
      required: true,
      value: formValues.weight,
      onChange: (weight: number) => setFormValues({ ...formValues, weight }),
    },
    {
      label: t("dateOfBirth"),
      inputComponent: "date",
      placeholder: "Itariki Yamavuko",
      required: true,
      value: formValues?.dateOfBirth,
      onChange: (dateOfBirth: any) =>
        setFormValues({ ...formValues, dateOfBirth }),
    },
    {
      label: t("dateOfMaturing"),
      inputComponent: "date",
      placeholder: "Enter Your Date of Birth",
      required: true,
      value: formValues?.date || "",
      onChange: (date: any) => setFormValues({ ...formValues, date }),
    },
    {
      label: t("cost"),
      placeholder: "Ibiro",
      inputComponent: "input",
      type: "number",
      required: true,
      value: formValues.cost,
      onChange: (cost: number) => setFormValues({ ...formValues, cost }),
    },
    {
      label: t("race"),
      identifier: "race",
      inputComponent: "select",
      required: true,
      values: pigRaces,
      value: formValues.race?.name || formValues.raceText,
      onChange: (race: any, found: boolean) => onRaceChanged(race, found),
    },
    {
      label: t("mother"),
      identifier: "sow",
      inputComponent: "select",
      values: pigState.sows,
      loading: pigState.fetchingForm,
      value: formValues.sow?.earTag || formValues.sowText,
      valueLabel: "earTag",
      onChange: (sow: any, found: boolean) => onSowChanged(sow, found),
    },
    {
      label: t("father"),
      identifier: "boar",
      inputComponent: "select",
      values: pigState.boars,
      loading: pigState.fetchingForm,
      value: formValues.boar?.earTag || formValues.boarText,
      valueLabel: "earTag",
      onChange: (boar: any, found: boolean) => onBoarChanged(boar, found),
    },
    {
      label: t("room"),
      placeholder: "Ikiraro i.e. A10",
      inputComponent: "input",
      type: "text",
      required: true,
      value: formValues.room,
      onChange: (room: string) => setFormValues({ ...formValues, room }),
    },
    {
      label: t("photo"),
      inputComponent: "file",
      required: true,
      value: formValues.weight,
      onChange: handleFileInputChange,
    },
  ];

  return (
    <GenericForm
      open={appState.openForm.open}
      close={() => dispatch(appActions.closeForm())}
      title={appState.openForm.action === "add" ? t("addPig") : t("updatePig")}
      styles="horizontal"
      formElements={newStaffElement}
      onSubmit={submit}
    />
  );
};

export default AddPig;
