import { expensesActions } from "..";
import {
  exportExcel,
  requestFailed,
  setResponseAction,
} from "../../../utils/functions";
import { appActions } from "../../app";
import {
  getDiseasesService,
  getSymptomsService,
  searchDiseasesService,
} from "./diseaseService";

// GET ALL
export const getDiseasesAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getDiseasesService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(expensesActions.setDiseases(res.data));
        dispatch(appActions.setCount({ count: res.count }));
        dispatch(expensesActions.setFetchedDiseases(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// EXPORT EXCEL
export const exportDiseasesAction = (
  query: string,
  token: string,
  excelName: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsExportingExcel(true));
      const res = await getDiseasesService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        var data = res.data.map((disease: any) => {
          return {
            ...disease,
            createdAt: disease.createdAt.substring(0, 10),
            updatedAt: disease.updatedAt.substring(0, 10),
          };
        });
        exportExcel(data, excelName);
      }
      dispatch(appActions.setIsExportingExcel(false));
    } catch (err) {
      console.log(err);
    }
  };
};

//SEARCH
export const searchDiseasesAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await searchDiseasesService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(expensesActions.setDiseases(res.data));
        dispatch(appActions.setCount({ count: res.count }));
        dispatch(expensesActions.setFetchedDiseases(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const fetchSymptomssAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setFetchingFormItems(true));
      const res = await getSymptomsService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(expensesActions.setSymptoms(res.data));
        dispatch(expensesActions.setFetchedSymptoms(true));
      }
      dispatch(appActions.setFetchingFormItems(false));
    } catch (err) {
      console.log(err);
    }
  };
};
