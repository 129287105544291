import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'authentication',
  initialState: { isFetching: false, isAuth: false, token: '', user: {}, status: '' },
  reducers: {
    login(state, action) {
      state.token = action.payload.token;
      state.user = action.payload.data?.user;
      state.status = action.payload.status;
      state.isAuth = true;
    },
    logout(state) {
      // store.remove('x-auth-token');
      state.token = '';
      state.status = '';
      state.user = {};
      state.isAuth = false;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
