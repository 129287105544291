import HttpRequest from "../../utils/HttpRequest";
import { SERVER_URL } from "../../utils/constants";

export const getPigsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/pig${query}`, token);
};

export const getPigsStatsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/dashboard/pigs`, token);
};

export const searchPigsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/pig/search${query}`, token);
};

export const updatePigService = async (
  pigId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(`${SERVER_URL}/pig/${pigId}`, data, token);
};

export const addPigService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/pig`, data, token);
};

export const deletePigService = async (pigId: string, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/pig/${pigId}`, token);
};
