import { Box } from "@mui/material";
import ViewDrawer, { DetailItem } from "../../../components/ViewDrawer";
import { useTranslation } from "../../../i18n";
import { Insurance } from "../../../interfaces/Expenses/insurance";
import { Weight, WeightItem } from "../../../interfaces/Pig/weight";
import styles from "../../index.module.scss";
import ScaleIcon from "@mui/icons-material/Scale";
import { Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LiveStockState } from "../../../store/pig";
import { useEffect } from "react";
import Storage from "../../../utils/storage";
import { getPigWeightsAction } from "../../../store/pig/weight/weightActions";
import { AppState } from "../../../store/app";
import LoadingContainer from "../../../components/LoadingContainer";
import { renderWeight } from "../../../utils/functions";
import moment from "moment";

export interface WeightingViewProps {
  open: boolean;
  onClose: Function;
  weight: Weight;
}

const WeightingView = (props: WeightingViewProps) => {
  const { t } = useTranslation();
  const weight: Weight = props.weight;
  let count = 1;
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const appState: AppState = useSelector((state: any) => state.app);
  const liveStockState: LiveStockState = useSelector(
    (state: any) => state.liveStock
  );

  useEffect(() => {
    if (!liveStockState.fetchedWeightLists) {
      dispatch(getPigWeightsAction(``, token));
    }
  }, []);

  return (
    <ViewDrawer open={props.open} onClose={props.onClose}>
      <Typography className="font-bold text-xl ms-9 mb-4 text-primaryBlue">
        Weighting Details
      </Typography>
      <Box className="p-4 bg-white rounded-md w-11/12 mx-auto">
        <DetailItem
          index={count}
          key={count++}
          label={t("title")}
          value={weight.title}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("date")}
          value={weight?.date.substring(0, 10)}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("method")}
          value={weight?.method}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("createdBy")}
          value={weight?.createdBy}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("_id")}
          value={weight?._id}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("updatedAt")}
          value={weight?.updatedAt.substring(0, 10)}
        />
      </Box>
      <Typography className="font-bold text-xl ms-9 mt-10 mb-4 text-primaryBlue">
        Individual Pig Weights
      </Typography>
      <div className="w-5/6 mx-auto bg-white rounded-md p-8">
        {appState.fetchingFormItems && <LoadingContainer />}
        {!appState.fetchingFormItems && (
          <div>
            {liveStockState?.weightItems?.map((pigWeight: WeightItem) => (
              <div
                key={pigWeight._id}
                className="flex items-center pb-2 my-2 border-b border-gray-300"
              >
                <div className="py-1 px-2 rounded-xl bg-primaryLightBlue me-3">
                  <ScaleIcon className="text-white" />
                </div>
                <div className="w-full">
                  <div className="font-semibold flex justify-between">
                    <Typography>{pigWeight.pig.earTag}</Typography>
                    <Typography>{renderWeight(pigWeight.weight)}</Typography>
                  </div>
                  <div className="text-gray-400 text-sm">
                    {t(`${pigWeight.pig.gender}`)} - {pigWeight.pig.race} -{" "}
                    {moment().diff(pigWeight.pig.dateOfBirth, "month")} Months
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </ViewDrawer>
  );
};

export default WeightingView;
