import { Typography, Box, Button, Grid } from "@mui/material";
import Layout from "../../../components/Layout";
import styles from "../../index.module.scss";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import TableComponent from "../../../components/TableComponent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState, appActions } from "../../../store/app";
import AddIcon from "@mui/icons-material/Add";
import Storage from "../../../utils/storage";
import LoadingContainer from "../../../components/LoadingContainer";
import { useTranslation } from "../../../i18n";
import PopupMenu from "../../../components/PopupMenu";
import ConfirmModal from "../../../components/ConfirmModal";
import { deleteAction } from "../../../store/app/appActions";
import SearchComponent from "../../../components/SearchComponent";
import ChipComponent, { ChipData } from "../../../components/ChipComponent";
import { LoadingButton } from "@mui/lab";
import FilterItems from "./FilterItems";
import AddItem from "./AddItem";
import { expensesActions, ExpensesState } from "../../../store/expenses";
import {
  exportItemsAction,
  getItemsAction,
  searchItemsAction,
} from "../../../store/expenses/items/itemActions";
import { Item, itemColumns, ItemRow } from "../../../interfaces/Expenses/item";
import { numberWithCommas } from "../../../utils/functions";
import { deleteItemService } from "../../../store/expenses/items/itemService";
import { LabelValuePair2 } from "../../../components/LabelValuePair";

const Items = (props: any) => {
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(50);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [query, setQuery] = useState("");
  const [isInit, setIsInit] = useState(true);
  const dispatch = useDispatch();
  const appState: AppState = useSelector((state: any) => state.app);
  const expensesState: ExpensesState = useSelector(
    (state: any) => state.expenses
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [chipData, setChipData] = useState([]);
  const [item, setItem] = useState<any>();
  const open = Boolean(anchorEl);
  const token = Storage.getToken();
  const { t } = useTranslation();

  //&farm=${user?.farm?.id}

  useEffect(() => {
    dispatch(
      getItemsAction(
        `?page=${page}&sort=-createdAt&limit=${rowsPerPage}`,
        token
      )
    );
  }, []);

  useEffect(() => {
    if (!expensesState.fetchedItems) {
      dispatch(
        getItemsAction(
          `?page=${page}&sort=-createdAt&limit=${rowsPerPage}${query}`,
          token
        )
      );
    }
  }, [expensesState.fetchedItems]);

  useEffect(() => {
    if (!appState.isFetching) {
      setCount(appState.count);
    }
  }, [appState.isFetching, appState.count]);

  useEffect(() => {
    if (chipData.length > 0) {
      let queryString = "";
      chipData.forEach((data: any) => {
        queryString += `&${data.id}=${data.filter}`;
      });
      setQuery(queryString);
      dispatch(expensesActions.setFetchedItems(false));
    } else if (chipData.length === 0 && query !== "") {
      setQuery("");
      dispatch(expensesActions.setFetchedItems(false));
    }
  }, [chipData]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (searchText) {
        dispatch(searchItemsAction(`?text=${searchText}`, token));
        setIsInit(false);
      } else {
        if (!appState.isFetching && !isInit) {
          dispatch(expensesActions.setFetchedItems(false));
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [searchText]);

  const createData = (item: Item): ItemRow => {
    return {
      name: item?.name,
      price: `${numberWithCommas(item?.price)} RWF`,
      unit: item?.unit ? t(`${item?.unit}`) : "N/A",
      stock: item?.stock,
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setItem(item);
            setAnchorEl(event.currentTarget);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(expensesActions.setFetchedItems(false));
  };

  const filterHandler = (filterData: any) => {
    setChipData(filterData);
  };

  const handleChipDelete = (chipToDelete: ChipData) => {
    setChipData((chips) =>
      chips.filter((chip: ChipData) => chip.key !== chipToDelete.key)
    );
  };

  const exportExcelHandler = () => {
    dispatch(exportItemsAction("?limit=1000", token, t("Items")));
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(expensesActions.setFetchedItems(false));
  };

  const deleteItemHandler = () => {
    dispatch(
      deleteAction(
        deleteItemService,
        item?._id,
        token,
        t("Items"),
        () => {},
        () => dispatch(expensesActions.setFetchedItems(false))
      )
    );
  };

  const rows = expensesState.items?.map((item: Item) => createData(item));

  return (
    <>
      <Box className={styles.topContainer}>
        <Box>{t("welcome")}</Box>
        <Box className={styles.dFlex}>
          <SearchComponent setSearchText={setSearchText} />
          <LoadingButton
            variant="contained"
            startIcon={<SaveAltIcon />}
            loading={appState.isExportingExcel}
            onClick={exportExcelHandler}
            className={styles.exportExcelButton}
          >
            {t("exportExcel")}
          </LoadingButton>
        </Box>
      </Box>
      {appState.isFetching && <LoadingContainer />}
      {!appState.isFetching && (
        <>
          {chipData.length > 0 && (
            <ChipComponent
              chipData={chipData}
              handleDelete={handleChipDelete}
            />
          )}

          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Box className={styles.tableTopActions}>
                <Box>
                  <Typography>{t("Items")}</Typography>
                </Box>
                <Box>
                  <Button
                    onClick={() => dispatch(appActions.setOpenFilter(true))}
                    startIcon={<AddIcon />}
                  >
                    {t("filter")}
                  </Button>
                  <Button
                    onClick={() =>
                      dispatch(
                        appActions.setOpenForm({ open: true, action: "add" })
                      )
                    }
                    startIcon={<AddIcon />}
                  >
                    {t("addNew")}
                  </Button>
                </Box>
              </Box>
              <TableComponent
                rows={rows}
                columns={itemColumns}
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography className="font-bold mb-2">Item Details</Typography>
              <div className="bg-white">
                <LabelValuePair2 label="Name" value="Amata" />
                <LabelValuePair2 label="id" value="123e3resd3321" />
              </div>
            </Grid>
          </Grid>

          {open && (
            <PopupMenu
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              onView={() => {}}
              onUpdate={() =>
                dispatch(
                  appActions.setOpenForm({ open: true, action: "update" })
                )
              }
              onDelete={() => dispatch(appActions.setIsDeleting(true))}
            />
          )}
          {appState.isDeleting && (
            <ConfirmModal
              onConfirm={() => deleteItemHandler()}
              content={`${t("confirmDeleteDesc")} ${item?.name} ?`}
            />
          )}
          {appState.openForm.open && <AddItem item={item} />}
          {appState.openFilter && (
            <FilterItems
              open={appState.openFilter}
              close={() => dispatch(appActions.setOpenFilter(false))}
              filterHandler={filterHandler}
            />
          )}
        </>
      )}
    </>
  );
};

export default Items;
