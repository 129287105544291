import { Avatar, Box } from "@mui/material";
import ViewDrawer, { DetailItem } from "../../../components/ViewDrawer";
import { StaffI } from "../../../interfaces/Application/staff";
import styles from "../../index.module.scss";

export interface StaffViewProps {
  open: boolean;
  onClose: Function;
  staff: StaffI;
}

const StaffView = (props: StaffViewProps) => {
  const staff: StaffI = props.staff;
  let count = 1;

  return (
    <ViewDrawer open={props.open} onClose={props.onClose}>
      <Box className={styles.item_details}>
        <Avatar
          style={{
            height: "5rem",
            width: "5rem",
          }}
        >
          <img
            className={styles.profilePicture}
            src={staff.photo.url}
            alt="user"
          />
        </Avatar>
        {Object.entries(staff).map(([key, value]) => {
          if (key === "photo") {
            return (
              <DetailItem
                index={count}
                key={count++}
                label={key}
                value="Photo"
              />
            );
          } else {
            return (
              <DetailItem
                index={count}
                key={count++}
                label={key}
                value={value}
              />
            );
          }
        })}
      </Box>
    </ViewDrawer>
  );
};

export default StaffView;
