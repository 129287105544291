const translationsEn = {
  welcome: "Bienvenue!!",
  forms: "Forms",
  dashboard: "Dashboard",
  staff: "Staff",
  search: "Search",
  exportExcel: "Export File",
  pigs: "Pigs",
  pig: "Pig",
  pigOrBirth: "Pig/Birth",
  pigAge: "Pig Age in Months",
  months: "Months",
  sale: "Sale",
  requiredRounds: "Required Rounds",
  dosagePerRound: "Dosage Per Round",
  vaccinationDesc: "Vaccination Description",
  roundCost: "Round Cost",
  addVaccine: "Add new vaccine",
  addVaccination: "Add Vaccination",
  maturePig: "Pig",
  estimatedCost: "Estimated Cost",
  addPig: "Add Pig",
  updatePig: "Update Pig",
  logout: "Logout",
  report: "Report",
  client: "Client",
  paid: "Amount Paid",
  addSale: "Add Sale",
  kgUnitCost: "Kg unit cost",
  totalPrice: "Total",
  addNew: "Add New",
  firstName: "First Name",
  lastName: "Last Name",
  name: "Name",
  nationalID: "National ID",
  email: "Email",
  position: "Position",
  gender: "Gender",
  dob: "Date Of Birth",
  reproduction: "Reproduction",
  births: "Births",
  birth: "Birth",
  birthWeight: "Birth Weight",
  addBirth: "Record New Birth",
  updateBirth: "Update Birth Details",
  deaths: "Deaths",
  vaccination: "Vaccination",
  diseases: "Diseases",
  diseaseName: "Disease Name",
  diseasePrevention: "Disease Prevention",
  addDisease: "Add New Disease",
  updateDisease: "Update Disease",
  symptoms: "Symptoms",
  severity: "Severity",
  category: "Category",
  feeding: "Feeding",
  weighting: "Weighting",
  business: "Business",
  action: "Action",
  delete: "Delete",
  view: "View",
  update: "Update",
  cancel: "Cancel",
  confirmDelete: "Confirm Delete",
  confirmDeleteDesc: "Are you sure you want to delete",
  deleteSuccess: "deleted Successfully!",
  male: "Male",
  female: "Female",
  addStaff: "Add New Staff",
  updateStaff: "Update Staff",
  save: "Save",
  beginingDate: "Begining Date",
  filter: "Filter",
  boar: "Boar",
  numberOfBoars: "Number Of Boars",
  sow: "Sow",
  barrow: "Barrow",
  numberOfSows: "Number of Sows",
  boarsLeft: "Number of boars Left",
  sowsLeft: "Number of sows Left",
  reproductionType: "Reproduction Type",
  crossBreeding: "Cross Breeding",
  mating: "Mating",
  boarRace: "Boar Race",
  father: "Father",
  mother: "Mother",
  race: "Race",
  amount: "Amount",
  paidAmount: "Paid Amount",
  createdBy: "Created By",
  createdAt: "Created At",
  sperm: "Sperm",
  updatedAt: "Updated At",
  dateOfBirth: "Date of Birth",
  dateOfMaturing: "Date of Maturing",
  status: "Status",
  earTag: "Ear Tag",
  weight: "Weight",
  photo: "Photo",
  treatment: "Treatment",
  work: "Work",
  documentName: "Name of Document",
  documentType: "Type of Document",
  document: "Document",
  add: "Add",
  days: "Days",
  cost: "Cost",
  addTreatment: "Add Pig Treatment",
  sickness: "Sickness",
  communicationDate: "Communication Date",
  requisition: "Requisition",
  dateOfDeath: "Date of Death",
  reason: "Reason",
  remaining: "Remaining",
  boarsSowsRemaining: "Boars/Sows Remaining",
  afterNoon: "After Noon",
  morning: "Morning",
  L: "Liter",
  Kg: "Kilogram",
  m: "Meter",
  expense: "Expense",
  paymentMethod: "Payment Method",
  insuranceCompany: "Insurance Company",
  insuranceStartDate: "Insurance Start Date",
  insuranceExpiryDate: "Insurance Expirty Date",
  unitCost: "Unit Cost",
  totalValue: "Total Value",
  room: "Room",
};

export default translationsEn;
