import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenericForm from "../../../components/GenericForm";
import { useTranslation } from "../../../i18n";
import { appActions } from "../../../store/app";
import Storage from "../../../utils/storage";
import {
  DocumentType,
  documentTypes,
} from "../../../interfaces/Application/document";
import { addAction } from "../../../store/app/appActions";
import { addDocumentService } from "../../../store/app/documents/documentsService";
import { expensesActions } from "../../../store/expenses";

const AddDocument = () => {
  const appState = useSelector((state: any) => state.app);
  const token = Storage.getToken();
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null as unknown);
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState({
    documentName: "" as string,
    documentType: {} as DocumentType | any,
    documentTypeText: "" as string | any,
  });

  const handleFileInputChange = (e: any) => {
    // @ts-ignore: Object is possibly 'null'.
    const file = (e.target as HTMLInputElement).files[0];
    setSelectedFile(file);
  };

  const onTypeChanged = (documentType: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({ ...formValues, documentType, documentTypeText: "" });
    } else {
      setFormValues({
        ...formValues,
        documentTypeText: documentType,
        documentType: null,
      });
    }
  };

  const newDocumentElements = [
    {
      label: t("documentName"),
      placeholder: "Andika hano",
      inputComponent: "input",
      required: true,
      value: formValues?.documentName,
      onChange: (documentName: any) =>
        setFormValues({ ...formValues, documentName }),
    },
    {
      label: t("documentType"),
      identifier: "gender",
      inputComponent: "select",
      required: true,
      values: documentTypes,
      value: formValues.documentType?.name || formValues.documentTypeText,
      onChange: (documentType: any, found: boolean) =>
        onTypeChanged(documentType, found),
    },
    {
      label: t("document"),
      placeholder: "document",
      inputComponent: "file",
      required: true,
      onChange: handleFileInputChange,
    },
  ];

  const submit = () => {
    let formData = new FormData();
    formData.append("name", formValues.documentName);
    formData.append("type", formValues.documentType.id);
    formData.append("file", selectedFile as File);

    console.log("Name ", formValues.documentName);
    console.log("type ", formValues.documentType.id);
    console.log("file ", selectedFile);

    dispatch(
      addAction(
        addDocumentService,
        formData,
        token,
        () => {},
        () => dispatch(expensesActions.setFetchedDocuments(false))
      )
    );
  };

  return (
    <GenericForm
      open={appState.openForm.open}
      close={() => dispatch(appActions.closeForm())}
      title={`${t("add")} ${t("document")}`}
      styles="vertical"
      formElements={newDocumentElements}
      onSubmit={submit}
    />
  );
};

export default AddDocument;
