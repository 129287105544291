import { appActions } from "..";
import {
  exportExcel,
  requestFailed,
  setResponseAction,
} from "../../../utils/functions";
import {
  getNotificationsService,
  searchNotificationsService,
} from "./notificationsService";

// GET ALL
export const getNotificationsAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getNotificationsService(query, token);

      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(appActions.setNotifications(res.data));
        dispatch(appActions.setCount({ count: res.count }));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// EXPORT EXCEL
export const exportNotificationsAction = (
  query: string,
  token: string,
  excelName: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsExportingExcel(true));
      const res = await getNotificationsService(query, token);
      console.log("resssss ", res);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        exportExcel(res.data, excelName);
      }
      dispatch(appActions.setIsExportingExcel(false));
    } catch (err) {
      console.log(err);
    }
  };
};

//SEARCH
export const searchNotificationAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await searchNotificationsService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(appActions.setNotifications(res.data));
        dispatch(appActions.setCount({ count: res.count }));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
