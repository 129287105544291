import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getWeightsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/weight${query}`, token);
};

export const getPigWeightsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/weightitem${query}`, token);
};

export const searchWeightsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/weight/search${query}`, token);
};

export const updateWeightService = async (
  weightId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/weight/${weightId}`,
    data,
    token
  );
};

export const addWeightService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/weight`, data, token);
};

export const deleteWeightService = async (weightId: string, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/weight/${weightId}`, token);
};
