import { User } from "../Application/user";
import { Birth } from "./birth";
import i18n from "../../i18n";

export interface PigColumn {
  id: "earTag" | "gender" | "months" | "weight" | "room" | "cost" | "action";
  label: string;
  minWidth?: number;
  align?: "center";
}

export const pigColumns: PigColumn[] = [
  { id: "earTag", label: i18n.t("earTag"), align: "center" },
  { id: "gender", label: i18n.t("gender"), align: "center" },
  { id: "months", label: i18n.t("months"), align: "center" },
  { id: "weight", label: i18n.t("weight"), align: "center" },
  { id: "room", label: i18n.t("room"), align: "center" },
  { id: "cost", label: i18n.t("cost"), align: "center" },
  { id: "action", label: i18n.t("action"), align: "center" },
];

export interface PigRow {
  earTag: string;
  gender: any;
  months: number;
  weight: string;
  room: string;
  cost: string;
  action: any;
}

export interface Pig {
  _id: string;
  earTag: string;
  race: string;
  gender: string;
  state: string;
  birth: Birth;
  weight: number;
  room: string;
  date: string;
  dateOfBirth: string;
  costEstimate: number;
  photo: any;
  father?: any;
  mother?: any;
  createdBy?: User;
  createdAt: string;
  updatedAt: string;
}

export interface PigGender {
  name: string;
  id: "sow" | "boar" | "barrow";
}

export const pigGenders: PigGender[] = [
  { name: i18n.t("sow"), id: "sow" },
  { name: i18n.t("boar"), id: "boar" },
  { name: i18n.t("barrow"), id: "barrow" },
];

export interface PigRace {
  name: string;
}

export const pigRaces: PigRace[] = [
  { name: "Duroc" },
  { name: "Large White" },
  { name: "Pietrain" },
  { name: "Landrace" },
  { name: "Hybride" },
  { name: "Combough" },
];

export const translatePigGender = (gender: string) => {
  switch (gender) {
    case "sow":
      return i18n.t("sow");
    case "boar":
      return i18n.t("boar");
    default:
      return i18n.t("barrow");
  }
};
