import { expensesActions } from "..";
import {
  exportExcel,
  requestFailed,
  setResponseAction,
} from "../../../utils/functions";
import { appActions } from "../../app";
import {
  getInsurancesService,
  searchInsuranceService,
} from "./insuranceService";

// GET ALL
export const getInsurancesAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getInsurancesService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(expensesActions.setInsurances(res.data));
        dispatch(appActions.setCount({ count: res.count }));
        dispatch(expensesActions.setFetchedInsurance(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// EXPORT EXCEL
export const exportInsurancesAction = (
  query: string,
  token: string,
  excelName: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsExportingExcel(true));
      const res = await getInsurancesService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        var data = res.data.map((item: any) => {
          return {
            ...item,
            date: item.date.substring(0, 10),
            createdAt: item.createdAt.substring(0, 10),
            updatedAt: item.updatedAt.substring(0, 10),
          };
        });
        exportExcel(data, excelName);
      }
      dispatch(appActions.setIsExportingExcel(false));
    } catch (err) {
      console.log(err);
    }
  };
};

//SEARCH
export const searchInsurancesAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await searchInsuranceService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(expensesActions.setInsurances(res.data));
        dispatch(appActions.setCount({ count: res.count }));
        dispatch(expensesActions.setFetchedInsurance(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
