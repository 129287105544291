import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getReproductionsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/reproduction${query}`, token);
};

export const searchReproductionService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/reproduction/search${query}`, token);
};

export const updateReproductionService = async (
  reproductionId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(`${SERVER_URL}/reproduction/${reproductionId}`, data, token);
};

export const addReproductionService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/reproduction`, data, token);
};

export const deleteReproductionService = async (reproductionId: string, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/reproduction/${reproductionId}`, token);
};
