import { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import store from "store";
import { useSelector } from "react-redux";
import PrintIcon from "@mui/icons-material/Print";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import styles from "./index.module.scss";
import logo from "../assets/images/logoo.png";

import { numberWithCommas } from "../utils/functions";
import { ExpensesState } from "../store/expenses";
import { FeedingItem } from "../interfaces/Expenses/feeding";
import { AppState } from "../store/app";

const ReceiptComponent = (props: any) => {
  const [date, setDate] = useState(
    new Date().toLocaleString().substring(0, 17)
  );
  const expensesState: ExpensesState = useSelector(
    (state: any) => state.expenses
  );
  const appState: AppState = useSelector((state: any) => state.app);

  return (
    <>
      <Box className={styles.purchasesReceipt}>
        <h4 className={styles.receipt_title}>{props?.title}</h4>
        <Paper elevation={2} className={styles.purchasesReceipt_header}>
          <Box>
            <img className={styles.receipt_logo} src={logo} alt="logo" />
          </Box>
          <Box>
            <Typography
              className={`${styles.centerText} ${styles.receipt_text}`}
            >
              {store.get("user")?.farm?.name}
            </Typography>
            <Typography
              className={`${styles.centerText} ${styles.receipt_text}`}
            >
              TEL: {store.get("user")?.farm?.contact}
            </Typography>
            <Typography
              className={`${styles.centerText} ${styles.receipt_text}`}
            >
              TIN: {store.get("user")?.farm?.tinNumber}
            </Typography>
          </Box>
        </Paper>
        <Paper elevation={2} className={styles.receipt_table}>
          {/* {newPurchase?.items[0]?.supplier?._id && (
            <>
              <Typography
                className={`${styles.centerText} ${styles.receipt_text}`}
              >
                Supplier: {newPurchase?.items[0]?.supplier?.name}
              </Typography>
            </>
          )} */}
          <Paper sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  className={`${styles.centerText} ${styles.bold} ${styles.receipt_text}`}
                >
                  Item
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  className={`${styles.centerText} ${styles.bold} ${styles.receipt_text}`}
                >
                  Qty
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  className={`${styles.centerText} ${styles.bold} ${styles.receipt_text}`}
                >
                  U.Price
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  className={`${styles.centerText} ${styles.bold} ${styles.receipt_text}`}
                >
                  Total
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          {expensesState?.feedingItems?.items?.map(
            (item: FeedingItem, index: number) => (
              <ReceiptItem
                key={index}
                index={index}
                item={item?.item?.name}
                quantity={item?.total}
                unit={item?.item?.unit}
                price={item?.price}
              />
            )
          )}
          <Typography className={styles.receiptTotal}>
            Total: {numberWithCommas(expensesState?.feedingItems?.total)} Rwf
          </Typography>
          {/* </Box> */}
        </Paper>
        <Typography className={`${styles.centerText} ${styles.receipt_text}`}>
          Done at {date}
        </Typography>
      </Box>
      <Box className={styles.receiptActions}>
        <Button
          className={styles.receipt_print_btn}
          startIcon={<PrintIcon />}
          variant="contained"
        >
          <Typography>Print</Typography>
        </Button>
        {!appState.isSubmitting && (
          <Button
            className={styles.receipt_save_btn}
            startIcon={<CheckCircleOutlineIcon />}
            onClick={() => {
              props?.onSave();
            }}
            variant="contained"
          >
            <Typography>Save</Typography>
          </Button>
        )}
        {appState.isSubmitting && (
          <Button className={styles.receipt_save_btn} variant="contained">
            <CircularProgress
              size={30}
              color="inherit"
              className={styles.circularProgress}
            />
          </Button>
        )}
      </Box>
    </>
  );
};

export const ReceiptItem = (props: any) => {
  return (
    <Box className={styles.receiptItem}>
      <Grid container>
        <Grid item xs={6}>
          <Typography className={`${styles.receipt_items_text}`}>{`${
            props.index + 1
          }. ${props.item}`}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={`${styles.centerText} ${styles.receipt_text}`}>
            {props.quantity}
            {props.unit}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={`${styles.centerText} ${styles.receipt_text}`}>
            {props.price}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={`${styles.centerText} ${styles.receipt_text}`}>
            {props.quantity * props.price}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReceiptComponent;
