import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "../../../i18n";
import Storage from "../../../utils/storage";
import { addAction, updateAction } from "../../../store/app/appActions";
import styles from "../../index.module.scss";
import {
  Feeding,
  FeedingItem,
  FeedingPeriod,
  feedingPeriods,
} from "../../../interfaces/Expenses/feeding";
import {
  addFeedingService,
  updateFeedingService,
} from "../../../store/expenses/feeding/feedingService";
import { expensesActions, ExpensesState } from "../../../store/expenses";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  Grid,
} from "@mui/material";
import ReceiptComponent from "../../../components/ReceiptComponent";
import FormInputs from "../../../components/inputs/FormInputs";
import FormHeader from "../../../components/FormHeader";
import { appActions } from "../../../store/app";
import { fetchItemsAction } from "../../../store/expenses/feeding/feedingActions";

export interface AddFeedingProps {
  feeding?: Feeding;
}

const AddFeeding = (props: AddFeedingProps) => {
  const appState = useSelector((state: any) => state.app);
  const token = Storage.getToken();
  const [unit, setUnit] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const expensesState: ExpensesState = useSelector(
    (state: any) => state.expenses
  );
  const [formValues, setFormValues] = useState({
    date: new Date().toISOString().substring(0, 10) as string,
    time: {} as FeedingPeriod | any,
    timeText: "" as any,
    item: {} as any,
    itemText: "" as string | any,
    price: 0 as number,
    twoThreeMonths: 0 as number,
    fourMonths: 0 as number,
    fiveMonths: 0 as number,
    sixEightMonths: 0 as number,
    eightMonthsAbove: 0 as number,
    total: 0 as number,
  });

  console.log("UUUU ", expensesState.feedingItems);

  React.useEffect(() => {
    //get pigs
    // if (!liveStockState.fetchedPigs) {
    dispatch(fetchItemsAction("", token));
    // }
    // if (appState.openForm.action === "update" && props.Feeding !== undefined) {
    //   setFormValues({
    //     reproduction: props.Feeding.reproduction,
    //     reproductionText: "",
    //     date: props.Feeding.date,
    //     boars: props.Feeding.boars,
    //     sows: props.Feeding.sows,
    //     weightEstimate: props.Feeding.weightEstimate,
    //   });
    // }
  }, []);

  const onItemChanged = (item: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({ ...formValues, item, price: item.price, itemText: "" });
      setUnit(`${t(`${item?.unit}`)}`);
    } else {
      setFormValues({ ...formValues, itemText: item, item: null });
    }
  };

  const onTimeChanged = (time: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({ ...formValues, time, timeText: "" });
    } else {
      setFormValues({ ...formValues, timeText: time, time: null });
    }
  };

  const submit = () => {
    const data = {
      date: formValues.date,
      time: formValues.time.id,
      total: expensesState.feedingItems.total,
      feedingItems: expensesState.feedingItems.items,
    };

    console.log("Data ", data);

    if (appState.openForm.action === "add") {
      dispatch(
        addAction(
          addFeedingService,
          data,
          token,
          () => {},
          () => dispatch(expensesActions.setFetchedFeedings(false))
        )
      );
    } else if (
      appState.openForm.action === "update" &&
      props.feeding !== undefined
    ) {
      dispatch(
        updateAction(
          updateFeedingService,
          props.feeding._id,
          data,
          token,
          () => {},
          () => dispatch(expensesActions.setFetchedFeedings(false))
        )
      );
    }
  };

  const addFeedingItem = () => {
    const feedingItem: FeedingItem = {
      item: formValues.item,
      price: +formValues.price,
      twoThree: +formValues.twoThreeMonths,
      four: +formValues.fourMonths,
      five: +formValues.fiveMonths,
      sixEight: +formValues.sixEightMonths,
      eight: +formValues.eightMonthsAbove,
      total:
        +formValues.twoThreeMonths +
        +formValues.fourMonths +
        +formValues.fiveMonths +
        +formValues.sixEightMonths +
        +formValues.eightMonthsAbove,
    };

    dispatch(expensesActions.addFeedingItem(feedingItem));
    clearFields();
  };

  const clearFields = () => {
    setFormValues({
      ...formValues,
      item: {},
      itemText: "",
      price: 0,
      twoThreeMonths: 0,
      fourMonths: 0,
      fiveMonths: 0,
      sixEightMonths: 0,
      eightMonthsAbove: 0,
      total: 0,
    });
  };

  const newStaffElement = [
    {
      label: t("date"),
      inputComponent: "date",
      placeholder: "Enter Your Date of Feeding",
      required: true,
      value: formValues?.date || "",
      onChange: (date: any) => setFormValues({ ...formValues, date }),
    },
    {
      label: t("time"),
      identifier: "time",
      inputComponent: "select",
      required: true,
      values: feedingPeriods,
      value: formValues.time?.name || formValues.timeText,
      onChange: (time: any, found: boolean) => onTimeChanged(time, found),
    },
    {
      label: t("item"),
      identifier: "item",
      inputComponent: "select",
      required: true,
      values: expensesState.items,
      value: formValues.item?.name || formValues.itemText,
      onChange: (item: any, found: boolean) => onItemChanged(item, found),
    },
    {
      label: unit ? `${t("cost")} / ${unit}` : t("cost"),
      placeholder: t("add"),
      inputComponent: "input",
      type: "number",
      required: true,
      value: formValues.price,
      onChange: (price: number) => setFormValues({ ...formValues, price }),
    },
    {
      label: t("2-3 Months"),
      placeholder: t("add"),
      inputComponent: "input",
      type: "number",
      required: true,
      value: formValues.twoThreeMonths,
      onChange: (twoThreeMonths: number) =>
        setFormValues({ ...formValues, twoThreeMonths }),
    },
    {
      label: t("4 Months"),
      placeholder: t("add"),
      inputComponent: "input",
      type: "number",
      required: true,
      value: formValues.fourMonths,
      onChange: (fourMonths: number) =>
        setFormValues({ ...formValues, fourMonths }),
    },
    {
      label: t("5 Months"),
      placeholder: t("amount"),
      inputComponent: "input",
      type: "number",
      required: true,
      value: formValues.fiveMonths,
      onChange: (fiveMonths: number) =>
        setFormValues({ ...formValues, fiveMonths }),
    },
    {
      label: t("6-8 Months"),
      placeholder: t("add"),
      inputComponent: "input",
      type: "number",
      required: true,
      value: formValues.sixEightMonths,
      onChange: (sixEightMonths: number) =>
        setFormValues({ ...formValues, sixEightMonths }),
    },
    {
      label: t("8 Months"),
      placeholder: t("amount"),
      inputComponent: "input",
      type: "number",
      required: true,
      value: formValues.eightMonthsAbove,
      onChange: (eightMonthsAbove: number) =>
        setFormValues({ ...formValues, eightMonthsAbove }),
    },
    {
      label: t("total"),
      placeholder: t("add total"),
      inputComponent: "input",
      type: "number",
      disabled: true,
      value:
        +formValues.twoThreeMonths +
        +formValues.fourMonths +
        +formValues.fiveMonths +
        +formValues.sixEightMonths +
        +formValues.eightMonthsAbove,
      onChange: (total: number) => setFormValues({ ...formValues, total }),
    },
  ];

  return (
    <Container classes={{ root: styles.newPurchaseContainer }}>
      <Dialog
        classes={{ paper: styles.paper }}
        open={appState.openForm.open}
        onClose={() => {
          dispatch(appActions.closeForm());
          // resetFields(true);
          // dispatch(shopActions.resetNewPurchase());
        }}
      >
        <FormHeader
          title={
            appState.openForm.action === "add" ? t("feeding") : "Update Sale"
          }
          close={() => {
            dispatch(appActions.closeForm());
            // props.close();
            // resetFields(true);
            // dispatch(shopActions.resetNewPurchase());
            // dispatch(shopActions.setSelectedItems(shopState?.items));
          }}
        />
        <DialogContent>
          <Grid container>
            <Grid item xs={8}>
              <Box className={styles.genericForm_column}>
                {newStaffElement.map((element: any, index: number) => {
                  return (
                    <FormInputs
                      key={index}
                      element={element}
                      style="horizontal"
                    />
                  );
                })}
              </Box>
              <Box className={styles.center}>
                <Button
                  onClick={() => addFeedingItem()}
                  className={styles.confirmButton}
                  variant="contained"
                >
                  Add
                </Button>
              </Box>
            </Grid>
            <Grid item xs={4}>
              Receipt
              <ReceiptComponent title="Ifunguro" onSave={() => submit()} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default AddFeeding;
