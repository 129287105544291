import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getInsurancesService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/insurance${query}`, token);
};

export const searchInsuranceService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/insurance/search${query}`, token);
};

export const updateInsuranceService = async (
  insuranceId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/insurance/${insuranceId}`,
    data,
    token
  );
};

export const addInsuranceService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/insurance`, data, token);
};

export const deleteInsuranceService = async (
  insuranceId: string,
  token: string
) => {
  return await HttpRequest.delete(
    `${SERVER_URL}/insurance/${insuranceId}`,
    token
  );
};
