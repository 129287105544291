import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenericForm from "../../../components/GenericForm";
import { useTranslation } from "../../../i18n";
import { appActions } from "../../../store/app";
import Storage from "../../../utils/storage";
import { addAction, updateAction } from "../../../store/app/appActions";
import {
  Insurance,
  insuranceCompanies,
} from "../../../interfaces/Expenses/insurance";
import { LiveStockState } from "../../../store/pig";
import {
  addInsuranceService,
  updateInsuranceService,
} from "../../../store/expenses/insurance/insuranceService";
import { expensesActions } from "../../../store/expenses";
import { PigState } from "../../../store/pigSlice";

export interface AddinsuranceProps {
  insurance?: Insurance;
}

const AddInsurance = (props: AddinsuranceProps) => {
  const appState = useSelector((state: any) => state.app);
  const pigState: PigState = useSelector(
    (state: any) => state.pig
  );
  const token = Storage.getToken();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({
    insuranceCompany: {} as any,
    insuranceCompanyText: "" as string | any,
    pig: {} as any,
    pigText: "" as string | any,
    startDate: new Date().toISOString().substring(0, 10) as string,
    expiryDate: new Date().toISOString().substring(0, 10) as string,
    unitCost: 0 as number,
    totalValue: 0 as number,
  });

  // React.useEffect(() => {

  //   if (
  //     appState.openForm.action === "update" &&
  //     props.insurance !== undefined
  //   ) {
  //     setFormValues({
  //       description: props.insurance.description,
  //       category: {
  //         name:
  //           props.insurance.category,
  //         id:
  //           props.insurance.category,
  //       },
  //       categoryText: "",
  //       amount: props.insurance.amount,
  //       paid: props.insurance.paid,
  //       paymentMethod: {
  //         name:
  //           props.insurance.paymentMethod,
  //         id:
  //           props.insurance.paymentMethod,
  //       },
  //       paymentMethodText: "",
  //       date: props.insurance.date.toString().substring(0, 10),
  //     });
  //   }
  // }, []);

  const onPigChanged = (pig: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({ ...formValues, pig, pigText: "" });
    } else {
      setFormValues({ ...formValues, pigText: pig, pig: null });
    }
  };

  const onInsuranceCompanyChanged = (
    insuranceCompany: any,
    isObject: boolean
  ) => {
    if (isObject) {
      setFormValues({
        ...formValues,
        insuranceCompany,
        insuranceCompanyText: "",
      });
    } else {
      setFormValues({
        ...formValues,
        insuranceCompanyText: insuranceCompany,
        insuranceCompany: null,
      });
    }
  };

  const submit = () => {
    const data = {
      insuranceCompany: formValues.insuranceCompany.name,
      pig: formValues.pig._id,
      earTag: formValues.pig.earTag,
      startDate: formValues.startDate,
      expiryDate: formValues.expiryDate,
      unitCost: +formValues.unitCost,
      totalValue: +formValues.totalValue,
    };

    console.log("d ", data);

    if (appState.openForm.action === "add") {
      dispatch(
        addAction(
          addInsuranceService,
          data,
          token,
          () => {},
          () => dispatch(expensesActions.setFetchedInsurance(false))
        )
      );
    } else if (
      appState.openForm.action === "update" &&
      props.insurance !== undefined
    ) {
      dispatch(
        updateAction(
          updateInsuranceService,
          props.insurance._id,
          data,
          token,
          () => {},
          () => dispatch(expensesActions.setFetchedInsurance(false))
        )
      );
    }
  };

  const newStaffElement = [
    {
      label: t("insuranceCompany"),
      identifier: "payment",
      inputComponent: "select",
      required: true,
      values: insuranceCompanies,
      value:
        formValues.insuranceCompany?.name || formValues.insuranceCompanyText,
      onChange: (insuranceCompany: any, found: boolean) =>
        onInsuranceCompanyChanged(insuranceCompany, found),
    },
    {
      label: t("pig"),
      identifier: "pig",
      inputComponent: "select",
      required: true,
      values: pigState.pigs,
      value: formValues.pig?.earTag || formValues.pigText,
      valueLabel: "earTag",
      onChange: (pig: any, found: boolean) => onPigChanged(pig, found),
    },
    {
      label: t("insuranceStartDate"),
      inputComponent: "date",
      required: true,
      value: formValues?.startDate || "",
      onChange: (startDate: any) => setFormValues({ ...formValues, startDate }),
    },
    {
      label: t("insuranceExpiryDate"),
      inputComponent: "date",
      required: true,
      value: formValues?.expiryDate || "",
      onChange: (expiryDate: any) =>
        setFormValues({ ...formValues, expiryDate }),
    },
    {
      label: t("unitCost"),
      placeholder: "Andika hano",
      inputComponent: "input",
      required: true,
      value: formValues?.unitCost,
      valueLabel: "unitCost",
      onChange: (unitCost: any) => setFormValues({ ...formValues, unitCost }),
    },
    {
      label: t("totalValue"),
      placeholder: "Andika hano",
      inputComponent: "input",
      required: true,
      value: formValues?.totalValue,
      valueLabel: "totalValue",
      onChange: (totalValue: any) =>
        setFormValues({ ...formValues, totalValue }),
    },
  ];

  return (
    <GenericForm
      open={appState.openForm.open}
      close={() => dispatch(appActions.closeForm())}
      title={
        appState.openForm.action === "add"
          ? t("addinsurance")
          : t("updateinsurance")
      }
      styles="horizontal"
      formElements={newStaffElement}
      onSubmit={submit}
    />
  );
};

export default AddInsurance;
