import { createSlice } from "@reduxjs/toolkit";
import { Client } from "../../interfaces/Business/client";
import { Sale } from "../../interfaces/Business/sales";
import { Supplier } from "../../interfaces/Business/supplier";

export interface BusinessState {
  fetchedSales: boolean;
  fetchedSuppliers: boolean;
  fetchedClients: boolean;
  sales: Sale[];
  suppliers: Supplier[];
  clients: Client[];
}

const businessSlice = createSlice({
  name: "business",
  initialState: {
    fetchedSales: false,
    fetchedSuppliers: false,
    fetchedClients: false,
    sales: [] as Sale[],
    suppliers: [] as Supplier[],
    clients: [] as Client[],
  } as BusinessState,
  reducers: {
    setFetchedSales(state, action) {
      state.fetchedSales = action.payload;
    },
    setFetchedSuppliers(state, action) {
      state.fetchedSuppliers = action.payload;
    },
    setFetchedClients(state, action) {
      state.fetchedClients = action.payload;
    },
    setSales(state, action) {
      state.sales = action.payload;
    },
    setSuppliers(state, action) {
      state.suppliers = action.payload;
    },
    setClients(state, action) {
      state.clients = action.payload;
    },
  },
});

export const businessActions = businessSlice.actions;

export default businessSlice.reducer;
