import { appActions } from "..";
import { requestFailed, setResponseAction } from "../../../utils/functions";
import { getDashboardService, getMonthlyExpensesService, getReportService } from "./dashboardServices";

export const getMonthlyExpensesAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    dispatch(appActions.setFetchingMonthlyExpenses(true));
    const res = await getMonthlyExpensesService(query, token);
    if (requestFailed(res)) {
      dispatch(setResponseAction(res, "fail", res?.message));
    } else {
      dispatch(appActions.setMonthlyExpenses(res.data));
      dispatch(appActions.setFetchedMonthlyExpenses(true));
      dispatch(appActions.setFetchingMonthlyExpenses(false));
    }
  };
};

export const getDashboardAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setFetchingDashboard(true));
      const res = await getDashboardService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(appActions.setDashboard(res.data));
      }
      dispatch(appActions.setFetchingDashboard(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getReport = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setFetchingDashboard(true));
      await getReportService(query, token);
      // console.log("Statsssssss ", res.response);
      // if (requestFailed(res)) {
      //   dispatch(setResponseAction(res, "fail", res.message));
      // } else {
      //   dispatch(appActions.setPigStats(res.data));
      // }
      // dispatch(appActions.setFetchingDashboard(false));
    } catch (err) {
      console.log(err);
    }
  };
};