import { User } from "./user";
import i18n from "../../i18n";

export interface DocumentType {
  name: string;
  id: "work";
}

export const documentTypes: DocumentType[] = [
  { id: "work", name: i18n.t("work") },
];

export interface Document {
  _id: string;
  name: string;
  type: string;
  url: string;
  cloudinary_id: string;
  createdAt: string;
  updatedAt: string;
  createdBy?: User;
}
