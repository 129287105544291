import { Box } from "@mui/material";
import ViewDrawer, { DetailItem } from "../../../components/ViewDrawer";
import { useTranslation } from "../../../i18n";
import { Requisition } from "../../../interfaces/Expenses/requisition";
import styles from "../../index.module.scss";

export interface RequisitionViewProps {
  open: boolean;
  onClose: Function;
  requisition: Requisition;
}

const RequisitionView = (props: RequisitionViewProps) => {
  const { t } = useTranslation();
  const requisition: Requisition = props.requisition;
  let count = 1;

  return (
    <ViewDrawer open={props.open} onClose={props.onClose}>
      <Box className={styles.item_details}>
        <DetailItem
          index={count}
          key={count++}
          label={t("category")}
          value={requisition?.category}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("description")}
          value={requisition?.description}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("quantity")}
          value={requisition?.quantity}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("status")}
          value={requisition?.status}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("date")}
          value={requisition?.date.substring(0, 10)}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("createdBy")}
          value={requisition?.createdBy}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("createdAt")}
          value={requisition?.createdAt.substring(0, 10)}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("updatedAt")}
          value={requisition?.updatedAt.substring(0, 10)}
        />
      </Box>
    </ViewDrawer>
  );
};

export default RequisitionView;
