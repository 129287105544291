import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationsEn from "./english";
import translationsKin from "./kinyarwanda";
import translationsFr from "./kinyarwanda";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationsEn },
    fr: { translation: translationsFr },
    kin: { translation: translationsKin },
  },
  lng: "kin",
  fallbackLng: "en",
  interpolation: { escapeValue: false },
});

export { useTranslation } from 'react-i18next';

export default i18n;