import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenericForm from "../../../components/GenericForm";
import { useTranslation } from "../../../i18n";
import { appActions } from "../../../store/app";
import Storage from "../../../utils/storage";
import {
  Reproduction,
  ReproductionType,
  reproductionTypes,
} from "../../../interfaces/Pig/reproduction";
import { Pig, PigRace, pigRaces } from "../../../interfaces/Pig/pig";
import { getSowAndBoarsAction } from "../../../store/pig/reproduction/reproductionActions";
import { liveStockActions } from "../../../store/pig";
import { addAction, updateAction } from "../../../store/app/appActions";
import {
  addReproductionService,
  updateReproductionService,
} from "../../../store/pig/reproduction/reproductionService";
import { PigState } from "../../../store/pigSlice";
import { fetchBirthsAction } from "../../../store/pigSlice/pigActions";

export interface AddReproductionProps {
  reproduction?: Reproduction;
}

const AddReproduction = (props: AddReproductionProps) => {
  const appState = useSelector((state: any) => state.app);
  const token = Storage.getToken();
  const dispatch = useDispatch();
  const pigState: PigState = useSelector(
    (state: any) => state.pig
  );
  const { t } = useTranslation();

  console.log("Number of boars ", pigState.boars);

  const [formValues, setFormValues] = useState({
    sow: {} as Pig | any,
    sowText: "" as string,
    boar: {} as Pig | any,
    boarText: "" as string,
    sperm: "" as string,
    reproductionType: reproductionTypes[0] as ReproductionType | any,
    reproductionTypeText: "" as string,
    date: new Date().toISOString().substring(0, 10) as string,
    boarRace: {} as PigRace | any,
    boarRaceText: "" as string,
  });

  React.useEffect(() => {
    if (!pigState.fetchedForm) {
      dispatch(fetchBirthsAction("?limit=200000", token));
    }

    if (
      appState.openForm.action === "update" &&
      props.reproduction !== undefined
    ) {
      setFormValues({
        sow: props.reproduction.sow,
        sowText: "",
        boar: props.reproduction.boar,
        boarText: "",
        sperm: "",
        reproductionType: {
          name:
            props.reproduction.reproductionType === "mating"
              ? t("mating")
              : t("crossBreeding"),
          id:
            props.reproduction.reproductionType === "mating"
              ? "mating"
              : "cross-breeding",
        },
        reproductionTypeText: "",
        date: props.reproduction.date.toString().substring(0, 10),
        boarRace: {
          name: props.reproduction.boarRace,
        },
        boarRaceText: "",
      });
    }
  }, []);

  const onSowChanged = (sow: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({
        ...formValues,
        sow,
        sowText: "",
      });
    } else {
      setFormValues({
        ...formValues,
        sowText: sow,
        sow: null,
      });
    }
  };

  const onBoarChanged = (boar: any, isObject: boolean) => {
    console.log("boarr ", boar);
    if (isObject) {
      setFormValues({
        ...formValues,
        boar,
        boarRace: {
          name: boar.race,
        },
        boarText: "",
      });
    } else {
      setFormValues({
        ...formValues,
        boarText: boar,
        boar: null,
      });
    }
  };

  const onReproductionChanged = (reproductionType: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({
        ...formValues,
        reproductionType,
        reproductionTypeText: "",
      });
    } else {
      setFormValues({
        ...formValues,
        reproductionTypeText: reproductionType,
        reproductionType: null,
      });
    }
  };

  const onBoarRaceChanged = (boarRace: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({
        ...formValues,
        boarRace,
        boarRaceText: "",
      });
    } else {
      setFormValues({
        ...formValues,
        boarRaceText: boarRace,
        boarRace: null,
      });
    }
  };

  const submit = () => {
    let data: any = {
      sow: formValues.sow._id,
      sowEarTag: formValues.sow.earTag,
      reproductionType: formValues.reproductionType.id,
      date: formValues.date,
      boarRace: formValues.boarRace.name,
    };

    if (formValues.reproductionType.id === "mating") {
      data.boar = formValues.boar._id;
      data.boarEarTag = formValues.boar.earTag;
    } else if (formValues.reproductionType.id === "crossBreeding") {
      data.sperm = formValues.sperm;
    }

    if (appState.openForm.action === "add") {
      dispatch(
        addAction(
          addReproductionService,
          data,
          token,
          () => {},
          () => dispatch(liveStockActions.setFetchedReproduction(false))
        )
      );
    } else if (
      appState.openForm.action === "update" &&
      props.reproduction !== undefined
    ) {
      dispatch(
        updateAction(
          updateReproductionService,
          props.reproduction._id,
          data,
          token,
          () => {},
          () => dispatch(liveStockActions.setFetchedReproduction(false))
        )
      );
    }
  };

  const matingElements = [
    {
      label: t("reproductionType"),
      identifier: "reproductionType",
      inputComponent: "select",
      required: true,
      values: reproductionTypes,
      value:
        formValues.reproductionType?.name || formValues.reproductionTypeText,
      onChange: (reproductionType: any, found: boolean) =>
        onReproductionChanged(reproductionType, found),
    },
    {
      label: t("sow"),
      identifier: "sow",
      inputComponent: "select",
      required: true,
      loading: pigState.fetchingForm,
      values: pigState.sows,
      value: formValues.sow?.earTag || formValues.sowText,
      valueLabel: "earTag",
      onChange: (sow: any, found: boolean) => onSowChanged(sow, found),
    },
    {
      label: t("boar"),
      identifier: "boar",
      inputComponent: "select",
      required: true,
      loading: pigState.fetchingForm,
      values: pigState.boars,
      value: formValues.boar?.earTag || formValues.boarText,
      valueLabel: "earTag",
      onChange: (boar: any, found: boolean) => onBoarChanged(boar, found),
    },
    {
      label: t("date"),
      inputComponent: "date",
      placeholder: "Enter Your Date of Birth",
      required: true,
      value: formValues?.date || "",
      onChange: (date: any) => setFormValues({ ...formValues, date }),
    },
    {
      label: t("boarRace"),
      identifier: "race",
      inputComponent: "select",
      required: true,
      values: pigRaces,
      value: formValues.boarRace?.name || formValues.boarRaceText,
      onChange: (boarRace: any, found: boolean) =>
        onBoarRaceChanged(boarRace, found),
    },
  ];

  const crossBreedingElements = [
    {
      label: t("reproductionType"),
      identifier: "reproductionType",
      inputComponent: "select",
      required: true,
      values: reproductionTypes,
      value:
        formValues.reproductionType?.name || formValues.reproductionTypeText,
      onChange: (reproductionType: any, found: boolean) =>
        onReproductionChanged(reproductionType, found),
    },
    {
      label: t("sow"),
      identifier: "sow",
      inputComponent: "select",
      required: true,
      values: pigState.sows,
      value: formValues.sow?.earTag || formValues.sowText,
      valueLabel: "earTag",
      onChange: (sow: any, found: boolean) => onSowChanged(sow, found),
    },
    {
      label: t("sperm"),
      placeHolder: "Andika Iherena ry'isekurume nyir'intanga",
      inputComponent: "input",
      value: formValues.sperm,
      onChange: (sperm: string) => setFormValues({ ...formValues, sperm }),
    },
    {
      label: t("date"),
      inputComponent: "date",
      placeholder: "Enter Your Date of Birth",
      required: true,
      value: formValues?.date || "",
      onChange: (date: any) => setFormValues({ ...formValues, date }),
    },
    {
      label: t("boarRace"),
      identifier: "race",
      inputComponent: "select",
      required: true,
      values: pigRaces,
      value: formValues.boarRace?.name || formValues.boarRaceText,
      onChange: (boarRace: any, found: boolean) =>
        onBoarRaceChanged(boarRace, found),
    },
  ];

  return (
    <GenericForm
      open={appState.openForm.open}
      close={() => dispatch(appActions.closeForm())}
      title={
        appState.openForm.action === "add"
          ? t("addReproduction")
          : t("updateReproduction")
      }
      styles="vertical"
      formElements={
        formValues?.reproductionType?.id === "mating"
          ? matingElements
          : crossBreedingElements
      }
      onSubmit={submit}
    />
  );
};

export default AddReproduction;
