import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { useTranslation } from "../i18n";
import { Menu, MenuItem, Typography } from "@mui/material";
import { ReactNode } from "react";

export interface PopupMenuProps {
  anchorEl: null | HTMLElement;
  onClose: Function;
  onView: Function;
  onUpdate: Function;
  onDelete: Function;
  children: ReactNode;
}

const PopupMenu = (props: any) => {
  const { t } = useTranslation();
  return (
    <Menu
      id="basic-menu"
      anchorEl={props.anchorEl}
      open={Boolean(props.anchorEl)}
      onClose={() => props.onClose()}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem
        onClick={() => {
          props.onView();
          props.onClose();
        }}
      >
        <RemoveRedEyeIcon />
        <Typography>{t("view")}</Typography>
      </MenuItem>
      <MenuItem
        onClick={() => {
          props.onUpdate();
          props.onClose();
        }}
      >
        <AutorenewIcon />
        <Typography>{t("update")}</Typography>
      </MenuItem>
      <MenuItem
        onClick={() => {
          props.onDelete();
          props.onClose();
        }}
      >
        <DeleteIcon />
        <Typography>{t("delete")}</Typography>
      </MenuItem>
      {props.children}
    </Menu>
  );
};

export default PopupMenu;
