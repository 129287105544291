import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenericForm from "../../../components/GenericForm";
import { useTranslation } from "../../../i18n";
import { appActions } from "../../../store/app";
import Storage from "../../../utils/storage";
import { Reproduction } from "../../../interfaces/Pig/reproduction";
import { liveStockActions, LiveStockState } from "../../../store/pig";
import { addAction, updateAction } from "../../../store/app/appActions";
import { Birth } from "../../../interfaces/Pig/birth";
import { fetchReproductionsAction } from "../../../store/pig/birth/birthActions";
import {
  addBirthService,
  updateBirthService,
} from "../../../store/pig/birth/birthService";

export interface AddBirthProps {
  birth?: Birth;
}

const AddBirth = (props: AddBirthProps) => {
  const appState = useSelector((state: any) => state.app);
  const token = Storage.getToken();
  const dispatch = useDispatch();
  const liveStockState: LiveStockState = useSelector(
    (state: any) => state.liveStock
  );
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({
    reproduction: {} as any,
    reproductionText: "" as string,
    date: new Date().toISOString().substring(0, 10) as string,
    boars: 0 as number | any,
    boarsLeft: 0 as number | any,
    sows: 0 as number | any,
    sowsLeft: 0 as number | any,
    weightEstimate: 0 as number | any,
  });

  React.useEffect(() => {
    //get pigs
    if (!liveStockState.fetchedReproduction) {
      dispatch(fetchReproductionsAction("?limit=1000&born=false", token));
    }

    if (appState.openForm.action === "update" && props.birth !== undefined) {
      setFormValues({
        reproduction: props.birth.reproduction,
        reproductionText: "",
        date: props.birth.date,
        boars: props.birth.boars,
        boarsLeft: props.birth?.boarsLeft,
        sows: props.birth.sows,
        sowsLeft: props.birth?.sowsLeft,
        weightEstimate: props.birth.weightEstimate,
      });
    }
  }, []);

  const onReproductionChanged = (reproduction: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({
        ...formValues,
        reproduction,
        reproductionText: "",
      });
    } else {
      setFormValues({
        ...formValues,
        reproductionText: reproduction,
        reproduction: null,
      });
    }
  };

  const submit = () => {
    const data = {
      reproduction: formValues.reproduction._id,
      date: formValues.date,
      boars: +formValues.boars,
      boarsLeft: +formValues.boarsLeft,
      sows: +formValues.sows,
      sowsLeft: +formValues.sowsLeft,
      race: formValues.reproduction.race,
      weightEstimate: +formValues.weightEstimate,
    };

    if (appState.openForm.action === "add") {
      dispatch(
        addAction(
          addBirthService,
          data,
          token,
          () => {},
          () => dispatch(liveStockActions.setFetchedBirth(false))
        )
      );
    } else if (
      appState.openForm.action === "update" &&
      props.birth !== undefined
    ) {
      console.log("sent dataaaaaaaaaaaaaa ", data);
      dispatch(
        updateAction(
          updateBirthService,
          props.birth._id,
          data,
          token,
          () => {},
          () => dispatch(liveStockActions.setFetchedBirth(false))
        )
      );
    }
  };

  const reproductionValues = liveStockState?.reproduction?.map(
    (reproduction: Reproduction) => {
      return {
        _id: reproduction._id,
        race: reproduction.boarRace,
        name: `${reproduction.sow.earTag} - ${reproduction.date.substring(
          0,
          10
        )}`,
      };
    }
  );

  const newStaffElement = [
    {
      label: t("reproduction"),
      identifier: "reproduction",
      inputComponent: "select",
      required: true,
      values: reproductionValues,
      value: formValues.reproduction?.name || formValues.reproductionText,
      valueLabel: "sow",
      onChange: (reproduction: any, found: boolean) =>
        onReproductionChanged(reproduction, found),
    },
    {
      label: t("date"),
      inputComponent: "date",
      placeholder: "Enter Your Date of Birth",
      required: true,
      value: formValues?.date || "",
      onChange: (date: any) => setFormValues({ ...formValues, date }),
    },
    {
      label: t("numberOfBoars"),
      placeholder: t("numberOfBoars"),
      inputComponent: "input",
      type: "number",
      required: true,
      value: formValues.boars,
      onChange: (boars: number) =>
        setFormValues({ ...formValues, boars, boarsLeft: boars }),
    },
    {
      label: t("boarsLeft"),
      placeholder: t("boarsLeft"),
      inputComponent: "input",
      type: "number",
      required: true,
      value: formValues.boarsLeft,
      onChange: (boarsLeft: number) =>
        setFormValues({ ...formValues, boarsLeft }),
    },
    {
      label: t("numberOfSows"),
      placeholder: t("numberOfSows"),
      inputComponent: "input",
      type: "number",
      required: true,
      value: formValues.sows,
      onChange: (sows: number) =>
        setFormValues({ ...formValues, sows, sowsLeft: sows }),
    },
    {
      label: t("sowsLeft"),
      placeholder: t("sowsLeft"),
      inputComponent: "input",
      type: "number",
      required: true,
      value: formValues.sowsLeft,
      onChange: (sowsLeft: number) =>
        setFormValues({ ...formValues, sowsLeft }),
    },
    {
      label: t("birthWeight"),
      placeholder: t("birthWeight"),
      inputComponent: "input",
      type: "number",
      required: true,
      value: formValues.weightEstimate,
      onChange: (weightEstimate: number) =>
        setFormValues({ ...formValues, weightEstimate }),
    },
  ];

  return (
    <GenericForm
      open={appState.openForm.open}
      close={() => dispatch(appActions.closeForm())}
      title={
        appState.openForm.action === "add"
          ? t("addBirth")
          : t("updateReproduction")
      }
      styles="horizontal"
      formElements={newStaffElement}
      onSubmit={submit}
    />
  );
};

export default AddBirth;
