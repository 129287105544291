import { Box } from "@mui/material";
import ViewDrawer, { DetailItem } from "../../../components/ViewDrawer";
import { useTranslation } from "../../../i18n";
import { Insurance } from "../../../interfaces/Expenses/insurance";
import styles from "../../index.module.scss";

export interface InsuranceViewProps {
  open: boolean;
  onClose: Function;
  insurance: Insurance;
}

const InsuranceView = (props: InsuranceViewProps) => {
  const { t } = useTranslation();
  const insurance: Insurance = props.insurance;
  let count = 1;

  return (
    <ViewDrawer open={props.open} onClose={props.onClose}>
      <Box className={styles.item_details}>
        <DetailItem
          index={count}
          key={count++}
          label={t("insuranceCompany")}
          value={insurance.insuranceCompany.toUpperCase()}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("pigs")}
          value={insurance?.pigs.length}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("startDate")}
          value={insurance.startDate}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("expiryDate")}
          value={insurance?.expiryDate}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("unitCost")}
          value={insurance.unitCost}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("totalValue")}
          value={insurance.totalValue}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("createdBy")}
          value={insurance?.createdBy}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("createdAt")}
          value={insurance?.createdAt.substring(0, 10)}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("updatedAt")}
          value={insurance?.updatedAt.substring(0, 10)}
        />
      </Box>
    </ViewDrawer>
  );
};

export default InsuranceView;
