import { User } from "../Application/user";
import i18n from "../../i18n";
import { Disease } from "./disease";
export interface VaccineColumn {
  id:
    | "name"
    | "controlledDisease"
    | "requiredRounds"
    | "pigAge"
    | "dosagePerRound"
    | "roundCost"
    | "action";
  label: string;
  minWidth?: number;
  align?: "center";
}

export const vaccineColumns: VaccineColumn[] = [
  {
    id: "name",
    label: i18n.t("name"),
    align: "center",
  },
  {
    id: "controlledDisease",
    label: i18n.t("controlledDisease"),
    align: "center",
  },
  { id: "requiredRounds", label: i18n.t("requiredRounds"), align: "center" },
  { id: "pigAge", label: i18n.t("pigAge"), align: "center" },
  { id: "dosagePerRound", label: i18n.t("dosagePerRound"), align: "center" },
  { id: "roundCost", label: i18n.t("roundCost"), align: "center" },
  { id: "action", label: i18n.t("action"), align: "center" },
];

export interface VaccineRow {
  name: string;
  controlledDisease: string;
  requiredRounds: number;
  pigAge: number;
  dosagePerRound: number;
  roundCost: number;
  action: any;
}
export interface Vaccine {
  _id: string;
  name: string;
  controlledDisease: Disease;
  description: string;
  requiredRounds: number;
  pigAge: number;
  dosagePerRound: number;
  roundCost: number;
  administration: string;
  createdBy?: User;
  createdAt: string;
  updatedAt: string;
}
