import store from "store";
import { authActions } from ".";
import { loginService } from "./authServices";

export const loginAction = (data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(authActions.setIsFetching(true));
      dispatch(authActions.login({ status: null }));
      const res = await loginService(data);
      if (res?.status === 200) {
        store.set("x-auth-token", res?.data?.token);
        store.set("user", res?.data?.data?.user);
      }
      dispatch(authActions.setIsFetching(false));
      dispatch(authActions.login({ ...res?.data, status: res?.status }));
    } catch (err) {
      console.log(err);
    }
  };
};

export const logoutAction = (navigate: Function) => {
  return async (dispatch: any) => {
    try {
      store.remove("x-auth-token");
      store.remove("user");
      dispatch(authActions.logout());
      navigate();
    } catch (err) {
      console.log(err);
    }
  };
};
