import { User } from "../Application/user";
import { Birth } from "../Pig/birth";
import { Pig } from "../Pig/pig";
import i18n from "../../i18n";
import { Disease } from "./disease";

export interface TreatmentColumn {
  id: "date" | "category" | "pig" | "sickness" | "cost" | "action";
  label: string;
  minWidth?: number;
  align?: "center";
}

export const treatmentColumns: TreatmentColumn[] = [
  { id: "date", label: i18n.t("date"), align: "center" },
  { id: "category", label: i18n.t("category"), align: "center" },
  { id: "pig", label: i18n.t("pig"), align: "center" },
  { id: "sickness", label: i18n.t("sickness"), align: "center" },
  { id: "cost", label: i18n.t("cost"), align: "center" },
  { id: "action", label: i18n.t("action"), align: "center" },
];

export interface TreatmentRow {
  date: string;
  category: string;
  pig: string;
  sickness: string;
  cost: string;
  action: any;
}

export interface Treatment {
  _id: string;
  category: string;
  pig: Pig;
  birth: Birth;
  communicationDate: string;
  sickness: Disease;
  treatment: string;
  cost: number;
  observation: string;
  createdBy?: User;
  createdAt: string;
  updatedAt: string;
}

export interface pigCategory {
  name: string;
  id: "piglet" | "pig";
}

export const pigCategories: pigCategory[] = [
  {
    name: i18n.t("piglet"),
    id: "piglet",
  },
  {
    name: i18n.t("pig"),
    id: "pig",
  },
];
