import { Menu, MenuItem } from "@mui/material";
import styles from "../../index.module.scss";
import { Link } from "react-router-dom";
import { SERVER_URL } from "../../../utils/constants";
import Layout from "../../../components/Layout";
import { AppState, appActions } from "../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import Visibility from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import Storage from "../../../utils/storage";
import { useEffect, useState } from "react";
import { getReportsAction } from "../../../store/app/report/reportActions";
import TableComponent from "../../../components/TableComponent";
import {
  Report,
  ReportRow,
  reportsColumns,
} from "../../../interfaces/Application/report";
import LoadingContainer from "../../../components/LoadingContainer";

const Reports = () => {
  const appState: AppState = useSelector((state: any) => state.app);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(25);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [report, setReport] = useState<Report | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const token = Storage.getToken();

  useEffect(() => {
    if (!appState.fetchedReports) {
      dispatch(getReportsAction(``, token));
    }
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    dispatch(appActions.setFetchedReports(false));
  };

  const handleRowsPerPageChange = (event: any) => {
    setCount(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createData = (report: Report): ReportRow => {
    return {
      id: report._id,
      date: report?.period?.endDate?.substring(0, 10),
      name: report.name,
      fileType: "PDF",
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setReport(report);
            setAnchorEl(event.currentTarget);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const rows = appState.reports?.map((report: Report) => createData(report));

  return (
    <Layout>
      <div className="mt-8"></div>
      {/* <Link to={`${SERVER_URL}/project/report/pdf`}>
        <Button variant="contained">Monthly Report</Button>
      </Link> */}

      {appState.isFetching && <LoadingContainer />}
      {!appState.isFetching && (
        <div>
          <TableComponent
            rows={rows}
            columns={reportsColumns}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={handleChangePage}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
          {Boolean(anchorEl) && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem>
                <Link to={`${SERVER_URL}/report/${report?._id}`}>
                  <Visibility
                    onClick={() => setAnchorEl(null)}
                    style={{ marginRight: "0.2rem" }}
                  />{" "}
                  View
                </Link>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  dispatch(appActions.setIsDeleting(true));
                }}
              >
                <DeleteIcon />
                Delete
              </MenuItem>
            </Menu>
          )}
        </div>
      )}
    </Layout>
  );
};

export default Reports;
