import { User } from "../Application/user";
import { Pig } from "./pig";
import i18n from "../../i18n";

export interface WeightsColumn {
  id: "date" | "title" | "method" | "pigs" | "totalWeight" | "action";
  label: string;
  minWidth?: number;
  align?: "center";
}

export const weightsColumns: WeightsColumn[] = [
  { id: "date", label: i18n.t("date"), align: "center" },
  { id: "title", label: i18n.t("title"), align: "center" },
  { id: "method", label: i18n.t("method"), align: "center" },
  { id: "pigs", label: i18n.t("pigs"), align: "center" },
  { id: "totalWeight", label: i18n.t("totalWeight"), align: "center" },
  { id: "action", label: i18n.t("action"), align: "center" },
];

export interface WeightsRow {
  date: string;
  title: string;
  method: string;
  pigs: number;
  totalWeight: string;
  action: any;
}

export interface Weight {
  _id: string;
  title: string;
  date: string;
  method: string;
  pigs: number;
  totalWeight: number;
  createdBy?: User;
  createdAt: string;
  updatedAt: string;
}

export interface WeightItem {
  _id: string;
  pig: Pig;
  weight: number;
  weighting: Weight;
  createdBy?: User;
  createdAt: string;
  updatedAt: string;
}

export interface PigWeight {
  pig: Pig;
  weight: number;
}

