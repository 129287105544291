import { liveStockActions } from "..";
import {
  exportExcel,
  requestFailed,
  setResponseAction,
} from "../../../utils/functions";
import { appActions } from "../../app";
import { pigActions } from "../../pigSlice";
import { getPigsService } from "../../pigSlice/pigService";
import {
  getReproductionsService,
  searchReproductionService,
} from "./reproductionService";

// GET ALL
export const getReproductionsAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getReproductionsService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(liveStockActions.setReproduction(res.data));
        dispatch(appActions.setCount({ count: res.count }));
        dispatch(liveStockActions.setFetchedReproduction(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// EXPORT EXCEL
export const exportReproductionAction = (
  query: string,
  token: string,
  excelName: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsExportingExcel(true));
      const res = await getReproductionsService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        var data = res.data.map((reproduction: any) => {
          return {
            ...reproduction,
            sow: reproduction.sow.earTag,
            boar: reproduction.boar.earTag,
            date: reproduction.date.substring(0, 10),
            createdAt: reproduction.createdAt.substring(0, 10),
            updatedAt: reproduction.updatedAt.substring(0, 10),
          };
        });
        exportExcel(data, excelName);
      }
      dispatch(appActions.setIsExportingExcel(false));
    } catch (err) {
      console.log(err);
    }
  };
};

//SEARCH
export const searchReproductionAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await searchReproductionService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(liveStockActions.setReproduction(res.data));
        dispatch(appActions.setCount({ count: res.count }));
        dispatch(liveStockActions.setFetchedReproduction(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// GET ALL
export const getSowAndBoarsAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setFetchingFormItems(true));
      const res = await getPigsService(query, token);
      const boars = res.data.filter((result: any) => result.gender === "boar");
      const sows = res.data.filter((result: any) => result.gender === "sow");
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(pigActions.setBoars(boars));
        dispatch(pigActions.setSows(sows));
        dispatch(pigActions.setFetchedPigs(true));
      }
      dispatch(appActions.setFetchingFormItems(false));
    } catch (err) {
      console.log(err);
    }
  };
};
