import { Box } from "@mui/material";
import ViewDrawer, { DetailItem } from "../../../components/ViewDrawer";
import { useTranslation } from "../../../i18n";
import { Reproduction } from "../../../interfaces/Pig/reproduction";
import styles from "../../index.module.scss";

export interface ReproductionViewProps {
  open: boolean;
  onClose: Function;
  reproduction: Reproduction;
}

const ReproductionView = (props: ReproductionViewProps) => {
  const { t } = useTranslation();
  const reproduction: Reproduction = props.reproduction;
  let count = 1;

  return (
    <ViewDrawer open={props.open} onClose={props.onClose}>
      <Box className={styles.item_details}>
        <DetailItem
          index={count}
          key={count++}
          label={t("boar")}
          value={reproduction?.boar?.earTag}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("sow")}
          value={reproduction?.sow?.earTag}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("reproductionType")}
          value={reproduction?.reproductionType}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("boarRace")}
          value={reproduction?.boarRace}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("date")}
          value={reproduction?.date.substring(0, 10)}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("createdBy")}
          value={reproduction?.createdBy}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("createdAt")}
          value={reproduction?.createdAt.substring(0, 10)}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("updatedAt")}
          value={reproduction?.updatedAt.substring(0, 10)}
        />
      </Box>
    </ViewDrawer>
  );
};

export default ReproductionView;
