import { User } from "./user";
import i18n from "../../i18n";

export interface StaffColumn {
  id: "firstName" | "lastName" | "nationalID" | "email" | "position" | "action";
  label: string;
  minWidth?: number;
  align?: "center";
}

export const staffColumns: StaffColumn[] = [
  { id: "firstName", label: i18n.t("firstName"), align: "center" },
  { id: "lastName", label: i18n.t("lastName"), align: "center" },
  { id: "nationalID", label: i18n.t("nationalID"), align: "center" },
  { id: "email", label: i18n.t("email"), align: "center" },
  { id: "position", label: i18n.t("position"), align: "center" },
  { id: "action", label: i18n.t("action"), align: "center" },
];

export interface StaffRow {
  _id: String;
  firstName: String;
  lastName: String;
  nationalID: Number;
  email: String;
  position: String;
  action: any;
}

export interface Position {
  name:
    | "Umushumba"
    | "Umuzamu"
    | "IT"
    | "Umwubatsi"
    | "Umuhinzi"
    | "Agronome"
    | "Veterinaire"
    | "Manager";
}

export const positions: Position[] = [
  { name: "Umushumba" },
  { name: "Umuzamu" },
  { name: "IT" },
  { name: "Umwubatsi" },
  { name: "Umuhinzi" },
  { name: "Agronome" },
  { name: "Veterinaire" },
  { name: "Manager" },
];

export interface Gender {
  name: string;
  id: "male" | "female" | "other";
}

export const genders: Gender[] = [
  { name: i18n.t("male"), id: "male" },
  { name: i18n.t("female"), id: "female" },
];

export interface StaffI {
  _id: string;
  firstName: string;
  lastName: string;
  gender: string;
  dob: string;
  nationalID: number;
  officialAddress: string[];
  contact: string;
  email: string;
  levelOfEducation: string;
  fieldOfStudy: string;
  relevantCertification: string[];
  position: string;
  department: string;
  hiringDate: string;
  leavingDate: string;
  photo: any;
  createdBy?: User;
}
