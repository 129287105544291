import { List, ListItem, ListItemText } from "@mui/material";
import { Link, Outlet, useLocation } from "react-router-dom";
import styles from "../index.module.scss";
import Layout from "../../components/Layout";

const BusinessMain = () => {
  const pigRoutes = ["Sales", "Supplier", "Client"];
  const router = useLocation();
  const pathName = router.pathname;

  return (
    <Layout>
      <List className="page_nav">
        {pigRoutes.map((route) => (
          <ListItem
            key={route}
            component={Link}
            to={`/business/${route.toLowerCase()}`}
            className="flex-1"
            selected={`/business/${route.toLowerCase()}` === pathName}
            classes={{ root: styles.root, selected: styles.listItemSelected }}
          >
            <ListItemText primary={route} className={styles.listItemText} />
          </ListItem>
        ))}
      </List>
      <Outlet />
    </Layout>
  );
};

export default BusinessMain;
