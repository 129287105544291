import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../index.module.scss";
import { useTranslation } from "../../../i18n";
import { appActions } from "../../../store/app";
import Storage from "../../../utils/storage";
import { liveStockActions, LiveStockState } from "../../../store/pig";
import { PigWeight, Weight } from "../../../interfaces/Pig/weight";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  Grid,
} from "@mui/material";
import FormHeader from "../../../components/FormHeader";
import FormInputs from "../../../components/inputs/FormInputs";
import WeightsCard from "./WeightsCard";
import { Pig } from "../../../interfaces/Pig/pig";
import { fetchPigs } from "../../../store/pig/weight/weightActions";
import { addAction, updateAction } from "../../../store/app/appActions";
import {
  addWeightService,
  updateWeightService,
} from "../../../store/pig/weight/weightService";
import { PigState } from "../../../store/pigSlice";

export interface AddWeightProps {
  weight?: Weight;
}

const AddWeight = (props: AddWeightProps) => {
  const appState = useSelector((state: any) => state.app);
  const liveStockState: LiveStockState = useSelector(
    (state: any) => state.liveStock
  );
  const pigState: PigState = useSelector(
    (state: any) => state.pig
  );
  const token = Storage.getToken();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({
    date: new Date().toISOString().substring(0, 10) as string,
    title: "" as string,
    method: "" as string,
    pig: {} as Pig | any,
    pigText: "" as string,
    weight: 0 as number,
  });

  const onPigChanged = (pig: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({ ...formValues, pig, pigText: "" });
    } else {
      setFormValues({ ...formValues, pigText: pig, pig: null });
    }
  };

  React.useEffect(() => {
    if (!pigState.fetchedPigs) {
      dispatch(fetchPigs("?state=live&limit=300000", token));
    } else {
      dispatch(liveStockActions.setPigsLeft(pigState.pigs));
    }
  }, []);

  const submit = () => {
    const data = {
      date: formValues.date,
      title: formValues.title,
      method: formValues.method,
      totalWeight: liveStockState.pigWeightsTotal,
      pigWeights: liveStockState.pigWeights,
    };
    if (appState.openForm.action === "add") {
      dispatch(
        addAction(
          addWeightService,
          data,
          token,
          () => {},
          () => {
            dispatch(liveStockActions.setFetchedWeights(false));
            dispatch(liveStockActions.clearPigWeights());
          }
        )
      );
    } else if (
      appState.openForm.action === "update" &&
      props.weight !== undefined
    ) {
      dispatch(
        updateAction(
          updateWeightService,
          props.weight._id,
          data,
          token,
          () => {},
          () => dispatch(liveStockActions.setFetchedWeights(false))
        )
      );
    }
  };

  const addPigWeight = () => {
    let pigWeight: PigWeight = {
      pig: formValues.pig,
      weight: +formValues.weight,
    };
    dispatch(liveStockActions.addPigWeights(pigWeight));
    let pigsLeft = liveStockState.pigsLeft.filter(
      (pig) => pig._id !== formValues?.pig?._id
    );
    dispatch(liveStockActions.setPigsLeft(pigsLeft));

    setFormValues({
      ...formValues,
      pig: {},
      pigText: "",
      weight: 0,
    });
  };

  const newStaffElement = [
    {
      label: t("date"),
      inputComponent: "date",
      required: true,
      value: formValues?.date || "",
      onChange: (date: any) => setFormValues({ ...formValues, date }),
    },
    {
      label: t("title"),
      placeholder: "Andika hano",
      inputComponent: "input",
      required: true,
      value: formValues?.title,
      valueLabel: "title",
      onChange: (title: any) => setFormValues({ ...formValues, title }),
    },
    {
      label: t("method"),
      placeholder: "Andika hano",
      inputComponent: "input",
      required: true,
      value: formValues?.method,
      valueLabel: "method",
      onChange: (method: any) => setFormValues({ ...formValues, method }),
    },
    {
      label: t("pig"),
      identifier: "pig",
      inputComponent: "select",
      required: true,
      values: liveStockState.pigsLeft,
      value: formValues.pig?.earTag || formValues.pigText,
      valueLabel: "earTag",
      onChange: (pig: any, found: boolean) => onPigChanged(pig, found),
    },
    {
      label: t("weight"),
      placeholder: "Andika hano",
      inputComponent: "input",
      required: true,
      value: formValues?.weight,
      valueLabel: "weight",
      onChange: (weight: any) => setFormValues({ ...formValues, weight }),
    },
  ];

  return (
    <Container classes={{ root: styles.newPurchaseContainer }}>
      <Dialog
        classes={{ paper: styles.paper }}
        open={appState.openForm.open}
        onClose={() => {
          dispatch(appActions.closeForm());
          dispatch(liveStockActions.clearPigWeights());
        }}
      >
        <FormHeader
          title={
            appState.openForm.action === "add" ? t("weighting") : "Update Sale"
          }
          close={() => {
            dispatch(appActions.closeForm());
            dispatch(liveStockActions.clearPigWeights());
          }}
        />
        <DialogContent>
          <Grid container>
            <Grid item xs={8}>
              <Box className={styles.genericForm_column}>
                {newStaffElement.map((element: any, index: number) => {
                  return (
                    <FormInputs
                      key={index}
                      element={element}
                      style="horizontal"
                    />
                  );
                })}
              </Box>
              <Box className={styles.center}>
                <Button
                  onClick={() => addPigWeight()}
                  className={styles.confirmButton}
                  variant="contained"
                >
                  Add
                </Button>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <WeightsCard title="IBIRO" onSave={() => submit()} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default AddWeight;
