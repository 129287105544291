import { Box, Grid, Typography } from "@mui/material";
import { primaryBackground } from "../assets/colors/colors";
import styles from "./index.module.scss";
import NavMenu from "./NavMenu";
import logo from "../assets/images/logoo.png";

const Layout = (props: any) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={2.5} className={styles.sideNav}>
        <Box className={styles.logo_container}>
          <Box className={`${styles.center} ${styles.imageContainer}`}>
            <img className={styles.image} alt="logo" src={logo} />
          </Box>
          <Typography style={{ fontSize: "1.5rem", color: primaryBackground }}>
            IRISA FARM
          </Typography>
        </Box>
        <NavMenu />
      </Grid>
      <Grid
        item
        xs={9.5}
        className={styles.main}
        style={{ paddingRight: "16px" }}
      >
        {props.children}
      </Grid>
    </Grid>
  );
};

export default Layout;
