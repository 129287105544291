import {
  exportExcel,
  requestFailed,
  setResponseAction,
} from "../../../utils/functions";
import { appActions } from "../../app";
import { getStaffsService, searchStaffService } from "./staffService";

//CRUD Actions for Shop Items

// CREATE ONE
// export const addItem = (data: LooseObject, token: string) => {
//   return async (dispatch: any) => {
//     try {
//       const res = await addItemService(data, token);
//       if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
//         dispatch(
//           appActions.setHttpResponse({
//             statusCode: res?.response?.status,
//             status: res?.response?.data?.status,
//             message: res?.response?.data?.message,
//           })
//         );
//       } else {
//         dispatch(appActions.setIsFetching(false));
//         dispatch(appActions.setUpdateNeeded(true));
//       }
//     } catch (err) {
//       console.log(err);
//       dispatch(appActions.setIsFetching(false));
//     }
//   };
// };

// GET ALL
export const getStaffAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getStaffsService(query, token);
      console.log("rrrrrrrrrrrrr ", res.message);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(appActions.setStaff(res.data));
        dispatch(appActions.setCount({ count: res.count }));
        dispatch(appActions.setFetchedStaff(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// EXPORT EXCEL
export const exportStaffAction = (
  query: string,
  token: string,
  excelName: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsExportingExcel(true));
      const res = await getStaffsService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        exportExcel(res.data, excelName);
      }
      dispatch(appActions.setIsExportingExcel(false));
    } catch (err) {
      console.log(err);
    }
  };
};

//SEARCH
export const searchStaffAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await searchStaffService(query, token);
      console.log("resssss ", res);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(appActions.setStaff(res.data));
        dispatch(appActions.setCount({ count: res.count }));
        dispatch(appActions.setFetchedStaff(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
