import { Typography, Box, Stack, Avatar } from "@mui/material";
import styles from "../../index.module.scss";

export interface DashboardCardProps {
  title: string;
  value: string;
  postValue?: string;
  icon: any;
  accentColor: string;
  secondaryText?: string;
  children?: any
}

const DashboardCard = (props: DashboardCardProps) => {
  return (
    <Box className={styles.dashboardCard}>
      <Box className={styles.dashboardCard_top}>
        <Stack direction="row">
          <Avatar
            style={{
              height: "2.5rem",
              width: "2.5rem",
              backgroundColor: `${props.accentColor}`,
              margin: "0 0.5rem",
            }}
          >
            {props.icon}
          </Avatar>
          <Typography className={styles.dashboardCard_label}>
            {props.title}
          </Typography>
        </Stack>
        <Typography>{props?.secondaryText}</Typography>
      </Box>
      <Box className={styles.dashboardCard_metric}>
        <Typography className={styles.dashboardCard_metric_value}>
          {props.value}
        </Typography>
      </Box>
      {props.children}
    </Box>
  );
};

export default DashboardCard;
