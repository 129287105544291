import { Line } from "react-chartjs-2";
// import Chart from 'chart.js/auto';
//Chart as ChartJS,
import "chart.js/auto";
// import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS } from "chart.js";
import { registerables } from "chart.js";
import { Chart } from "react-chartjs-2";
// import {Chart as ChartJS } from 'chart.js/auto'
ChartJS.register(...registerables);

const LineChart = (props: any) => {
  let { chartData } = props;
  console.log("chartData ", chartData?.labels?.length);
  return (
    <>
      {chartData?.labels?.length > 0 && <Chart type={"line"} data={chartData} />}
    </>
  );
};

export default LineChart;
