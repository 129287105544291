import { Routes, Route } from "react-router-dom";
import Dashboard from "../pages/General/dashboard/Dashboard";
import Login from "../pages/login";
import Births from "../pages/pigs/births/Births";
import Pigs from "../pages/pigs/pigs/Pigs";
import Reproductions from "../pages/pigs/reproduction/Reproductions";
import Staff from "../pages/General/staff/Staff";
import Documents from "../pages/General/documents/Documents";
import Reports from "../pages/General/reports/Reports";
import Weightings from "../pages/pigs/weighting/Weighting";
import Deaths from "../pages/pigs/deaths/Deaths";
import Sales from "../pages/business/sales/Sales";
import Suppliers from "../pages/business/suppliers/Suppliers";
import Clients from "../pages/business/clients/Clients";
import DiseasesMain from "../pages/expenses/disease/DiseasesMain";
import Expenses from "../pages/expenses/expenses/Expenses";
import Requisitions from "../pages/expenses/requisition/Requisitions";
import Feedings from "../pages/expenses/feeding/Feedings";
import Insurances from "../pages/expenses/insurance/Insurances";
import VaccinationMain from "../pages/expenses/vaccination/VaccinationMain";
import PigsMain from "../pages/pigs/PigsMain";
import ExpensesMain from "../pages/expenses/ExpensesMain";
import BusinessMain from "../pages/business/BusinessMain";
import Items from "../pages/expenses/items/Items";

const MainRouter = () => {
  return (
    <Routes>
      {/* General Routes */}
      <Route path="/" element={<Dashboard />} />
      <Route path="/home" element={<Dashboard />} />
      <Route path="/staff" element={<Staff />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forms" element={<Documents />} />
      <Route path="/report" element={<Reports />} />

      {/* Amatungo Routes */}
      <Route path="/pigs" element={<PigsMain />}>
        <Route path="/pigs/pigs" element={<Pigs />} />
        <Route path="/pigs/reproduction" element={<Reproductions />} />
        <Route path="/pigs/births" element={<Births />} />
        <Route path="/pigs/weighting" element={<Weightings />} />
        <Route path="/pigs/deaths" element={<Deaths />} />
      </Route>

      {/* Expenses Routes */}
      <Route path="/expenses" element={<ExpensesMain />}>
        <Route path="/expenses/expenses" element={<Expenses />} />
        <Route path="/expenses/diseases" element={<DiseasesMain />} />
        <Route path="/expenses/requisition" element={<Requisitions />} />
        <Route path="/expenses/insurance" element={<Insurances />} />
        <Route path="/expenses/vaccination" element={<VaccinationMain />} />
        <Route path="/expenses/feeding" element={<Feedings />} />
        <Route path="/expenses/items" element={<Items />} />
      </Route>

      {/* Business Routes */}
      <Route path="/business" element={<BusinessMain />}>
        <Route path="/business/sales" element={<Sales />} />
        <Route path="/business/supplier" element={<Suppliers />} />
        <Route path="/business/client" element={<Clients />} />
      </Route>
    </Routes>
  );
};

export default MainRouter;
