import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenericForm from "../../../components/GenericForm";
import {
  Gender,
  genders,
  Position,
  positions,
  StaffI,
} from "../../../interfaces/Application/staff";
import { useTranslation } from "../../../i18n";
import { appActions } from "../../../store/app";
import { addAction, updateAction } from "../../../store/app/appActions";
import {
  addStaffService,
  updateStaffService,
} from "../../../store/app/staff/staffService";
import Storage from "../../../utils/storage";

export interface AddStaffProps {
  staff?: StaffI;
}

const AddStaff = (props: AddStaffProps) => {
  const appState = useSelector((state: any) => state.app);
  const token = Storage.getToken();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({
    firstName: "" as string,
    lastName: "" as string,
    dob: "" as string,
    nationalID: 0 as number,
    contact: "" as string,
    email: "" as string,
    department: "" as string,
    position: {} as Position | any,
    positionText: "" as string | any,
    gender: {} as Gender | any,
    genderText: "" as string | any,
    levelOfEducation: "" as string,
    hiringDate: "" as string,
    leavingDate: "" as string,
  });

  React.useEffect(() => {
    if (appState.openForm.action === "update" && props.staff !== undefined) {
      setFormValues({
        firstName: props.staff.firstName,
        lastName: props.staff.lastName,
        dob: props.staff.dob.toString().substring(0, 10),
        nationalID: props.staff.nationalID,
        contact: props.staff.contact,
        email: props.staff.email,
        department: props.staff.department,
        position: {
          name: props.staff.position,
        },
        positionText: "",
        gender: {
          name: props.staff.gender === "male" ? t("male") : t("female"),
          id: props.staff.gender === "male" ? "male" : "female",
        },
        genderText: "",
        levelOfEducation: props.staff.levelOfEducation,
        hiringDate: props.staff.hiringDate.toString().substring(0, 10),
        leavingDate: "",
      });
    }
  }, []);

  const onPositionChanged = (
    position: string | Position,
    isObject: boolean
  ) => {
    if (isObject) {
      setFormValues({ ...formValues, position, positionText: "" });
    } else {
      setFormValues({ ...formValues, positionText: position, position: null });
    }
  };

  const onGenderChanged = (gender: string | Gender, isObject: boolean) => {
    if (isObject) {
      setFormValues({ ...formValues, gender, genderText: "" });
    } else {
      setFormValues({ ...formValues, genderText: gender, gender: null });
    }
  };

  const submit = () => {
    const data = {
      contact: formValues.contact,
      dob: formValues.dob,
      email: formValues.email,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      gender: formValues.gender.id,
      hiringDate: formValues.hiringDate,
      nationalID: formValues.nationalID,
      position: formValues.position.name,
    };

    if (appState.openForm.action === "add") {
      dispatch(
        addAction(
          addStaffService,
          data,
          token,
          () => {},
          () => dispatch(appActions.setFetchedStaff(false))
        )
      );
    } else if (
      appState.openForm.action === "update" &&
      props.staff !== undefined
    ) {
      dispatch(
        updateAction(
          updateStaffService,
          props.staff._id,
          data,
          token,
          () => {},
          () => dispatch(appActions.setFetchedStaff(false))
        )
      );
    }
  };

  const newStaffElement = [
    {
      label: t("firstName"),
      inputComponent: "input",
      placeholder: "Enter Your First Name",
      required: true,
      value: formValues?.firstName || "",
      onChange: (firstName: any) => setFormValues({ ...formValues, firstName }),
    },
    {
      label: t("lastName"),
      inputComponent: "input",
      placeholder: "Enter Your Last Name",
      required: true,
      value: formValues?.lastName || "",
      onChange: (lastName: any) => setFormValues({ ...formValues, lastName }),
    },
    {
      label: "Telephone",
      inputComponent: "input",
      placeholder: "Enter Your Phone Number",
      required: true,
      value: formValues?.contact || "",
      onChange: (contact: any) => setFormValues({ ...formValues, contact }),
    },
    {
      label: t("email"),
      inputComponent: "input",
      placeholder: "Enter Your Email",
      required: true,
      value: formValues?.email || "",
      onChange: (email: any) => setFormValues({ ...formValues, email }),
    },
    {
      label: t("gender"),
      identifier: "gender",
      inputComponent: "select",
      required: true,
      values: genders,
      value: formValues.gender?.name || formValues.genderText,
      onChange: (gender: any, found: boolean) => onGenderChanged(gender, found),
    },
    {
      label: t("dob"),
      inputComponent: "date",
      placeholder: "Enter Your Date of Birth",
      required: true,
      value: formValues?.dob || "",
      onChange: (dob: any) => setFormValues({ ...formValues, dob }),
    },
    // {
    //   label: "Photo",
    //   inputComponent: "input",
    //   placeholder: "Enter Your Photo",
    //   required: true,
    //   values: positions,
    //   value: formValues.position?.name || formValues.positionText,
    //   onChange: (position: any, found: boolean) =>
    //     onPositionChanged(position, found),
    // },
    {
      label: t("position"),
      identifier: "position",
      inputComponent: "select",
      placeholder: "Enter Your Email",
      required: true,
      values: positions,
      value: formValues.position?.name || formValues.positionText,
      onChange: (position: any, found: boolean) =>
        onPositionChanged(position, found),
    },
    {
      label: t("beginingDate"),
      inputComponent: "date",
      placeholder: "Date",
      required: true,
      value: formValues?.hiringDate || "",
      onChange: (hiringDate: any) =>
        setFormValues({ ...formValues, hiringDate }),
    },
    {
      label: t("nationalID"),
      inputComponent: "input",
      placeholder: "Enter Your National ID",
      required: true,
      value: formValues?.nationalID || "",
      onChange: (nationalID: any) =>
        setFormValues({ ...formValues, nationalID }),
    },
  ];

  return (
    <GenericForm
      open={appState.openForm.open}
      close={() => dispatch(appActions.closeForm())}
      title={
        appState.openForm.action === "add" ? t("addStaff") : t("updateStaff")
      }
      styles={"horizontal"}
      formElements={newStaffElement}
      onSubmit={submit}
    />
  );
};

export default AddStaff;
