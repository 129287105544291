import HttpRequest from "../../../utils/HttpRequest";
import { SERVER_URL } from "../../../utils/constants";

export const getNotificationsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/notification${query}`, token);
};

export const searchNotificationsService = async (
  query: string,
  token: string
) => {
  return await HttpRequest.get(
    `${SERVER_URL}/notification/search?q=${query}`,
    token
  );
};

export const markNotificationAsReadService = async (
  notificationId: string,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/notification/${notificationId}`,
    {},
    token
  );
};

export const updateNotificationService = async (
  notificationId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/notification/${notificationId}`,
    data,
    token
  );
};

export const addNotificationService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/notification`, data, token);
};

export const deleteNotificationService = async (
  notificationId: string,
  token: string
) => {
  return await HttpRequest.delete(
    `${SERVER_URL}/notification/${notificationId}`,
    token
  );
};