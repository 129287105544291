import { useState } from "react";
import { useTranslation } from "../../../../i18n";
import {
  Position,
  positions,
} from "../../../../interfaces/Application/staff";
import GenericForm from "../../../../components/GenericForm";

export interface FilterVaccinaitonProps {
  open: boolean;
  close: Function;
  filterHandler: Function;
}

export default function FilterDisease(props: FilterVaccinaitonProps) {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState({
    dob: "" as string,
    nationalID: 0 as number,
    contact: "" as string,
    email: "" as string,
    position: {} as Position | any,
    positionText: "" as string | any,
  });

  const onPositionChanged = (
    position: string | Position,
    isObject: boolean
  ) => {
    if (isObject) {
      setFormValues({ ...formValues, position, positionText: "" });
    } else {
      setFormValues({ ...formValues, positionText: position, position: null });
    }
  };

  const filterStaffElements = [
    {
      label: "Telephone",
      inputComponent: "input",
      placeholder: "Enter Your Phone Number",
      required: true,
      value: formValues?.contact || "",
      onChange: (contact: any) => setFormValues({ ...formValues, contact }),
    },
    {
      label: t("email"),
      inputComponent: "input",
      placeholder: "Enter Your Email",
      required: true,
      value: formValues?.email || "",
      onChange: (email: any) => setFormValues({ ...formValues, email }),
    },
    {
      label: t("dob"),
      inputComponent: "date",
      placeholder: "Enter Your Date of Birth",
      required: true,
      value: formValues?.dob || "",
      onChange: (dob: any) => setFormValues({ ...formValues, dob }),
    },
    {
      label: t("position"),
      inputComponent: "select",
      placeholder: "Enter Your Email",
      required: true,
      values: positions,
      value: formValues.position?.name || formValues.positionText,
      onChange: (position: any, found: boolean) =>
        onPositionChanged(position, found),
    },
    {
      label: t("nationalID"),
      inputComponent: "input",
      placeholder: "Enter Your National ID",
      required: true,
      value: formValues?.nationalID || "",
      onChange: (nationalID: any) =>
        setFormValues({ ...formValues, nationalID }),
    },
  ];

  const filter = () => {
    let chipData = [];
    let key = 0;
    if (formValues?.dob) {
      chipData.push({
        key,
        filter: formValues.dob,
        id: "dob",
        label: "Date Of Birth",
        value: formValues.dob,
      });
      key++;
    }
    if (formValues?.email) {
      chipData.push({
        key,
        filter: formValues.email,
        id: "email",
        label: "Email",
        value: formValues.email,
      });
      key++;
    }
    if (formValues?.nationalID) {
      chipData.push({
        key,
        filter: formValues.nationalID,
        id: "nationalID",
        label: "National ID",
        value: formValues.nationalID,
      });
      key++;
    }
    if (formValues?.contact) {
      chipData.push({
        key,
        filter: formValues.contact,
        id: "contact",
        label: "Telephone",
        value: formValues.contact,
      });
      key++;
    }
    if (formValues?.position?.name) {
      chipData.push({
        key,
        filter: formValues?.position?.name,
        id: "position",
        label: "Position",
        value: formValues.nationalID,
      });
      key++;
    }

    if (chipData.length > 0) {
      props.filterHandler(chipData);
      props.close();
    } else {
      props.close();
    }
  };

  return (
    <GenericForm
      open={props.open}
      close={props.close}
      title="Filter Staff"
      formElements={filterStaffElements}
      onSubmit={filter}
      confirmText="Filter"
    />
  );
}
