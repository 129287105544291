import HttpRequest from "../../../utils/HttpRequest";
import { SERVER_URL } from "../../../utils/constants";

export const getDashboardService = async (query: string, token: string) => {
  return await HttpRequest.get(
    `${SERVER_URL}/dashboard${query}`,
    token
  );
};

export const getReportService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/dashboard/report`, token);
};

export const getMonthlyExpensesService = async (query: string, token: string) => {
  return await HttpRequest.get(
    `${SERVER_URL}/dashboard/expenses${query}`,
    token
  );
};
