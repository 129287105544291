import { Typography, Box, Button, MenuItem } from "@mui/material";
import styles from "../../index.module.scss";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import TableComponent from "../../../components/TableComponent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState, appActions } from "../../../store/app";
import AddIcon from "@mui/icons-material/Add";
import Storage from "../../../utils/storage";
import LoadingContainer from "../../../components/LoadingContainer";
import { useTranslation } from "../../../i18n";
import PopupMenu from "../../../components/PopupMenu";
import ConfirmModal from "../../../components/ConfirmModal";
import { deleteAction } from "../../../store/app/appActions";
import SearchComponent from "../../../components/SearchComponent";
import ChipComponent, { ChipData } from "../../../components/ChipComponent";
import { LoadingButton } from "@mui/lab";
import { liveStockActions, LiveStockState } from "../../../store/pig";
import {
  exportPigAction,
  getPigsAction,
  searchPigAction,
} from "../../../store/pigSlice/pigActions";
import {
  Pig,
  pigColumns,
  PigRow, 
  translatePigGender,
} from "../../../interfaces/Pig/pig";
import PigView from "./PigView";
import FilterPigs from "./FilterPig";
import AddPig from "./AddPig";
import PigsStats from "./PigStats";
import { numberWithCommas } from "../../../utils/functions";
import moment from "moment";
import { PigState, pigActions } from "../../../store/pigSlice";
import { deletePigService } from "../../../store/pigSlice/pigService";

const Pigs = (props: any) => {
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(50);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [query, setQuery] = useState("");
  const [isInit, setIsInit] = useState(true);
  const dispatch = useDispatch();
  const appState: AppState = useSelector((state: any) => state.app);
  const liveStockState: LiveStockState = useSelector(
    (state: any) => state.liveStock
  );
  const pigState : PigState = useSelector(
    (state: any) => state.pig
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [chipData, setChipData] = useState([]);
  const [pig, setPig] = useState<any>();
  const [viewPig, setViewPig] = useState<boolean>();
  const open = Boolean(anchorEl);
  const token = Storage.getToken();
  const { t } = useTranslation();

  // useEffect(() => {
  //   dispatch(
  //     getPigsAction(
  //       `?page=${page}&state=live&sort=-createdAt&limit=${rowsPerPage}`,
  //       token
  //     )
  //   );
  // }, []);

  useEffect(() => {
    if (!pigState.fetchedPigs) {
      dispatch(
        getPigsAction(
          `?page=${page}&sort=-createdAt&state=live&createdAt[gte]=${pigState.fetchedPigsAt}&updatedAt[gte]=${pigState.fetchedPigsAt}&limit=${rowsPerPage}${query}`,
          token
        )
      );
    }
  }, [pigState.fetchedPigs]);

  useEffect(() => {
    if (!appState.isFetching) {
      setCount(pigState.count);
    }
  }, [appState.isFetching, pigState.count]);

  useEffect(() => {
    if (chipData.length > 0) {
      let queryString = "";
      chipData.forEach((data: any) => {
        queryString += `&${data.id}=${data.filter}`;
      });
      setQuery(queryString);
      dispatch(pigActions.setFetchedPigs(false));
    } else if (chipData.length === 0 && query !== "") {
      setQuery("");
      dispatch(pigActions.setFetchedPigs(false));
    }
  }, [chipData]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (searchText) {
        dispatch(searchPigAction(`?text=${searchText}`, token));
        setIsInit(false);
      } else {
        if (!appState.isFetching && !isInit) {
          dispatch(pigActions.setFetchedPigs(false));
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [searchText]);

  const createData = (pig: Pig): PigRow => {
    return {
      earTag: pig?.earTag,
      gender: translatePigGender(pig?.gender),
      months:
        Math.round(
          (-moment(pig.dateOfBirth.substring(0, 10)).diff(
            moment.now(),
            "days"
          ) /
            30) *
            10
        ) / 10,
      weight: `${pig.weight} Kg`,
      room: pig.room,
      cost: `${numberWithCommas(pig?.costEstimate)} RWF`,
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setPig(pig);
            setAnchorEl(event.currentTarget);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(pigActions.setFetchedPigs(false));
  };

  const filterHandler = (filterData: any) => {
    setChipData(filterData);
  };

  const handleChipDelete = (chipToDelete: ChipData) => {
    setChipData((chips) =>
      chips.filter((chip: ChipData) => chip.key !== chipToDelete.key)
    );
  };

  const exportExcelHandler = () => {
    dispatch(exportPigAction("?limit=1000&state=live", token, t("pigs")));
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(pigActions.setFetchedPigs(false));
  };

  const deletePigHandler = () => {
    dispatch(
      deleteAction(
        deletePigService,
        pig?._id,
        token,
        t("pigs"),
        () => {},
        () => dispatch(liveStockActions.setFetchedWeights(false))
      )
    );
  };

  const rows = pigState.pigs?.map((pig: Pig) => createData(pig));

  return (
    <>
      <Box className={styles.topContainer}>
        <Typography>{t("pigs")}</Typography>
        <Box className={styles.dFlex}>
          <SearchComponent setSearchText={setSearchText} />
          <LoadingButton
            variant="contained"
            startIcon={<SaveAltIcon />}
            loading={appState.isExportingExcel}
            onClick={exportExcelHandler}
            className={styles.exportExcelButton}
          >
            {t("exportExcel")}
          </LoadingButton>
        </Box>
      </Box>
      <PigsStats />
      {appState.isFetching && <LoadingContainer />}
      {!appState.isFetching && (
        <>
          {chipData.length > 0 && (
            <ChipComponent
              chipData={chipData}
              handleDelete={handleChipDelete}
            />
          )}
          <Box className={styles.tableTopActions}>
            <Box>
              <Typography>{t("pigs")}</Typography>
            </Box>
            <Box>
              <Button
                onClick={() => dispatch(appActions.setOpenFilter(true))}
                startIcon={<AddIcon />}
              >
                {t("filter")}
              </Button>
              <Button
                onClick={() =>
                  dispatch(
                    appActions.setOpenForm({ open: true, action: "add" })
                  )
                }
                startIcon={<AddIcon />}
              >
                {t("addNew")}
              </Button>
            </Box>
          </Box>
          <TableComponent
            rows={rows}
            columns={pigColumns}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
          {open && (
            <PopupMenu
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              onView={() => setViewPig(true)}
              onUpdate={() =>
                dispatch(
                  appActions.setOpenForm({ open: true, action: "update" })
                )
              }
              onDelete={() => dispatch(appActions.setIsDeleting(true))}
            />
          )}
          {appState.isDeleting && (
            <ConfirmModal
              onConfirm={() => deletePigHandler()}
              content={`${t("confirmDeleteDesc")} ${pig?.earTag} ?`}
            />
          )}
          {appState.openForm.open && <AddPig pig={pig} />}
          {appState.openFilter && (
            <FilterPigs
              open={appState.openFilter}
              close={() => dispatch(appActions.setOpenFilter(false))}
              filterHandler={filterHandler}
            />
          )}
          {viewPig && (
            <PigView
              open={viewPig}
              onClose={() => setViewPig(false)}
              pig={pig}
            />
          )}
        </>
      )}
    </>
  );
};

export default Pigs;
