import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "../../../../i18n";
import { AppState, appActions } from "../../../../store/app";
import Storage from "../../../../utils/storage";
import {
  Disease,
  DiseaseCategory,
} from "../../../../interfaces/Expenses/disease";
import { expensesActions, ExpensesState } from "../../../../store/expenses";
import GenericForm from "../../../../components/GenericForm";
import {
  pigCategories,
  pigCategory,
  Treatment,
} from "../../../../interfaces/Expenses/treatment";
import { Pig } from "../../../../interfaces/Pig/pig";
import { Birth } from "../../../../interfaces/Pig/birth";
import { LiveStockState } from "../../../../store/pig";
import { fetchBirthsAndPigs } from "../../../../store/expenses/treatment/treatmentActions";
import { addAction, updateAction } from "../../../../store/app/appActions";
import {
  addTreatmentService,
  updateTreatmentService,
} from "../../../../store/expenses/treatment/treatmentService";
import { PigState } from "../../../../store/pigSlice";

export interface AddStaffProps {
  treatment?: Treatment;
}

const AddTreatment = (props: AddStaffProps) => {
  const appState: AppState = useSelector((state: any) => state.app);
  const expensesState: ExpensesState = useSelector(
    (state: any) => state.expenses
  );
  const pigState: PigState = useSelector(
    (state: any) => state.pig
  );
  const token = Storage.getToken();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({
    category: {} as pigCategory | any,
    categoryText: "" as string | any,
    birth: {} as any,
    birthText: "" as string,
    pig: {} as Pig | any,
    pigText: "" as string,
    communicationDate: new Date().toISOString().substring(0, 10) as string,
    sickness: {} as any,
    sicknessText: "" as string,
    treatment: "" as string,
    cost: 0 as number,
    observation: "" as string,
  });

  React.useEffect(() => {
    dispatch(fetchBirthsAndPigs("", token));

    // if (appState.openForm.action === "update" && props.disease !== undefined) {
    //   setFormValues({
    //     name: props.disease.name,
    //     category: {
    //       name: props.disease.category,
    //     },
    //     categoryText: "",
    //     symptoms: props.disease.symptoms,
    //     severity: {
    //       name: props.disease.severity,
    //     },
    //     severityText: "",
    //     prevention: props.disease.prevention,
    //     treatment: props.disease.treatment,
    //   });
    // }
  }, []);

  const onBirthChanged = (birth: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({
        ...formValues,
        birth,
        birthText: "",
      });
    } else {
      setFormValues({
        ...formValues,
        birthText: birth,
        birth: null,
      });
    }
  };

  const onPigChanged = (pig: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({ ...formValues, pig, pigText: "" });
    } else {
      setFormValues({ ...formValues, pigText: pig, pig: null });
    }
  };

  const onCategoryChanged = (
    category: string | DiseaseCategory,
    isObject: boolean
  ) => {
    if (isObject) {
      setFormValues({ ...formValues, category, categoryText: "" });
    } else {
      setFormValues({ ...formValues, categoryText: category, category: null });
    }
  };

  const onSicknessChanged = (sickness: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({ ...formValues, sickness, sicknessText: "" });
    } else {
      setFormValues({ ...formValues, sicknessText: sickness, sickness: null });
    }
  };

  const submit = () => {
    const data: any = {
      category: formValues.category.id,
      disease: formValues.sickness._id,
      communicationDate: formValues.communicationDate,
      treatment: formValues.treatment,
      observation: formValues.observation,
      cost: formValues.cost,
    };

    if(formValues.category.id === "pig") {
      data.pig = formValues.pig?._id;
    } else if(formValues.category.id === "piglet") {
      data.birth = formValues.birth?._id;
    }

    console.log(data );

    // if (appState.openForm.action === "add") {
    //   dispatch(
    //     addAction(
    //       addTreatmentService,
    //       data,
    //       token,
    //       () => {},
    //       () => dispatch(expensesActions.setFetchedTreatments(false))
    //     )
    //   );
    // } else if (
    //   appState.openForm.action === "update" &&
    //   props.treatment !== undefined
    // ) {
    //   dispatch(
    //     updateAction(
    //       updateTreatmentService,
    //       props.treatment._id,
    //       data,
    //       token,
    //       () => {},
    //       () => dispatch(expensesActions.setFetchedTreatments(false))
    //     )
    //   );
    // }
  };

  const birthValues = pigState?.births?.map((birth: Birth) => {
    return {
      _id: birth._id,
      name: `${birth?.reproduction.sow.earTag} - ${birth?.date.substring(
        0,
        10
      )}`,
    };
  });

  const newTreatmentElement = [
    {
      label: t("category"),
      identifier: "category",
      inputComponent: "select",
      required: true,
      values: pigCategories,
      value: formValues.category?.name || formValues.categoryText,
      onChange: (category: any, found: boolean) =>
        onCategoryChanged(category, found),
    },
    {
      label: t("birth"),
      identifier: "birth",
      inputComponent: "select",
      required: true,
      values: birthValues,
      value: formValues.birth?.name || formValues.birthText,
      valueLabel: "sow",
      onChange: (reproduction: any, found: boolean) =>
        onBirthChanged(reproduction, found),
    },
    {
      label: t("pig"),
      identifier: "pig",
      inputComponent: "select",
      required: true,
      values: pigState.pigs,
      value: formValues.pig?.earTag || formValues.pigText,
      valueLabel: "earTag",
      onChange: (pig: any, found: boolean) => onPigChanged(pig, found),
    },
    {
      label: t("communicationDate"),
      inputComponent: "date",
      placeholder: "Andika hano",
      required: true,
      value: formValues?.communicationDate || "",
      onChange: (communicationDate: any) =>
        setFormValues({ ...formValues, communicationDate }),
    },
    {
      label: t("sickness"),
      identifier: "sickness",
      inputComponent: "select",
      required: true,
      values: expensesState.diseases,
      value: formValues.sickness?.name || formValues.sicknessText,
      onChange: (sickness: any, found: boolean) =>
        onSicknessChanged(sickness, found),
    },
    {
      label: t("treatment"),
      inputComponent: "input",
      placeholder: "Andika ubuvuzi bwatanzwe",
      required: true,
      value: formValues?.treatment,
      onChange: (treatment: any) => setFormValues({ ...formValues, treatment }),
    },
    {
      label: t("cost"),
      inputComponent: "input",
      placeholder: "Andika hano",
      required: true,
      value: formValues?.cost,
      onChange: (cost: any) => setFormValues({ ...formValues, cost }),
    },
    {
      label: t("observation"),
      inputComponent: "input",
      placeholder: "Andika andi makuru",
      required: true,
      value: formValues?.observation,
      onChange: (observation: any) =>
        setFormValues({ ...formValues, observation }),
    },
  ];

  return (
    <GenericForm
      open={appState.openForm.open}
      close={() => dispatch(appActions.closeForm())}
      title={
        appState.openForm.action === "add"
          ? t("addTreatment")
          : t("updateDisease")
      }
      styles="horizontal"
      formElements={newTreatmentElement}
      onSubmit={submit}
    />
  );
};

export default AddTreatment;
