import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getDeathsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/death${query}`, token);
};

export const searchDeathsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/death/search${query}`, token);
};

export const updateDeathService = async (
  deathId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/death/${deathId}`,
    data,
    token
  );
};

export const addDeathService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/death`, data, token);
};

export const deleteDeathService = async (deathId: string, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/death/${deathId}`, token);
};
