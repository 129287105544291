import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/Layout";
import { AppState, appActions } from "../../../store/app";
import styles from "../../index.module.scss";
import { useTranslation } from "../../../i18n";
import AddIcon from "@mui/icons-material/Add";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import AddDocument from "./AddDocument";
import { useEffect } from "react";
import Storage from "../../../utils/storage";
import { getDocumentsAction } from "../../../store/app/documents/documentsActions";
import { ExpensesState } from "../../../store/expenses";
import { Document } from "../../../interfaces/Application/document";

const Documents = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const token = Storage.getToken();
  const appState: AppState = useSelector((state: any) => state.app);
  const expensesState: ExpensesState = useSelector(
    (state: any) => state.expenses
  );

  useEffect(() => {
    dispatch(getDocumentsAction(`?sort=createdAt`, token));
  }, []);

  useEffect(() => {
    if (!expensesState.fetchedDocuments) {
      dispatch(getDocumentsAction(``, token));
    }
  }, [dispatch, expensesState.fetchedDocuments, token]);

  return (
    <Layout>
      <Box className={styles.topContainer}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() =>
            dispatch(appActions.setOpenForm({ open: true, action: "add" }))
          }
          className={styles.exportExcelButton}
        >
          {t("addNew")}
        </Button>
      </Box>

      {expensesState?.documents?.length > 0 &&
        expensesState?.documents?.map((document: Document, index: number) => (
          <Box className={styles.formDocument}>
            <a
              key={index}
              className={styles.link}
              href={document.url}
              target="_blank"
              download
              rel="noreferrer"
            >
              {`${document.name}  `}
              <CloudDownloadIcon />
            </a>
          </Box>
        ))}
      {appState.openForm.open && <AddDocument />}
    </Layout>
  );
};

export default Documents;
