import { createSlice } from "@reduxjs/toolkit";
import { Document } from "../../interfaces/Application/document";
import { Disease, Symptom } from "../../interfaces/Expenses/disease";
import { Expense } from "../../interfaces/Expenses/expense";
import { Feeding, FeedingItem } from "../../interfaces/Expenses/feeding";
import { Insurance } from "../../interfaces/Expenses/insurance";
import { Item } from "../../interfaces/Expenses/item";
import { Requisition } from "../../interfaces/Expenses/requisition";
import { Treatment } from "../../interfaces/Expenses/treatment";
import { Vaccination } from "../../interfaces/Expenses/vaccination";
import { Vaccine } from "../../interfaces/Expenses/vaccine";

export interface ExpensesState {
  fetchedDiseases: boolean;
  fetchedSymptoms: boolean;
  fetchedDocuments: boolean;
  fetchedExpenses: boolean;
  fetchedTreatments: boolean;
  fetchedRequisitions: boolean;
  fetchedFeedings: boolean;
  fetchedItems: boolean;
  fetchedInsurance: boolean;
  fetchedVaccinations: boolean;
  fetchedVaccines: boolean;
  expenses: Expense[];
  diseases: Disease[];
  symptoms: Symptom[];
  documents: Document[];
  treatments: Treatment[];
  requisitions: Requisition[];
  feedings: Feeding[];
  items: Item[];
  insurances: Insurance[];
  feedingItems: {
    total: number;
    items: FeedingItem[];
  },
  vaccinations: Vaccination[];
  vaccines: Vaccine[];
}

const expensesSlice = createSlice({
  name: "liveStock",
  initialState: {
    fetchedDiseases: false,
    fetchedSymptoms: false,
    fetchedDocuments: false,
    fetchedExpenses: false,
    fetchedTreatments: false,
    fetchedRequisitions: false,
    fetchedFeedings: false,
    fetchedItems: false,
    fetchedInsurance: false,
    fetchedVaccinations: false,
    fetchedVaccines: false,
    expenses: [] as Expense[],
    diseases: [] as Disease[],
    symptoms: [] as Symptom[],
    documents: [] as Document[],
    treatments: [] as Treatment[],
    requisitions: [] as Requisition[],
    feedings: [] as Feeding[],
    items: [] as Item[],
    insurances: [] as Insurance[],
    feedingItems: {
      total: 0,
      items: [] as FeedingItem[],
    },
    vaccinations: [] as Vaccination[],
    vaccines: [] as Vaccine[],
  } as ExpensesState,
  reducers: {
    setFetchedDiseases(state, action) {
      state.fetchedDiseases = action.payload;
    },
    setFetchedSymptoms(state, action) {
      state.fetchedSymptoms = action.payload;
    },
    setFetchedDocuments(state, action) {
      state.fetchedDocuments = action.payload;
    },
    setFetchedExpenses(state, action) {
      state.fetchedExpenses = action.payload;
    },
    setFetchedTreatments(state, action) {
      state.fetchedTreatments = action.payload;
    },
    setFetchedRequisitions(state, action) {
      state.fetchedRequisitions = action.payload;
    },
    setFetchedFeedings(state, action) {
      state.fetchedFeedings = action.payload;
    },
    setFetchedItems(state, action) {
      state.fetchedItems = action.payload;
    },
    setFetchedInsurance(state, action) {
      state.fetchedInsurance = action.payload;
    },
    setFetchedVaccination(state, action) {
      state.fetchedVaccinations = action.payload;
    },
    setFetchedVaccine(state, action) {
      state.fetchedVaccines = action.payload;
    },
    setDiseases(state, action) {
      state.diseases = action.payload;
    },
    setSymptoms(state, action) {
      state.symptoms = action.payload;
    },
    setDocuments(state, action) {
      state.documents = action.payload;
    },
    setExpenses(state, action) {
      state.expenses = action.payload;
    },
    setTreatments(state, action) {
      state.treatments = action.payload;
    },
    setRequisitions(state, action) {
      state.requisitions = action.payload;
    },
    setFeedings(state, action) {
      state.feedings = action.payload;
    },
    setItems(state, action) {
      state.items = action.payload;
    },
    setInsurances(state, action) {
      state.insurances = action.payload;
    },
    setVaccinations(state, action) {
      state.vaccinations = action.payload;
    },
    setVaccines(state, action) {
      state.vaccines = action.payload;
    },
    addFeedingItem(state, action) {
      state.feedingItems.total += (action.payload.price * action.payload.total)
      state.feedingItems.items.push(action.payload);
    },
    clearFeedingItems(state) {
      state.feedingItems = {
        total: 0,
        items: []
      };
    },
  },
});

export const expensesActions = expensesSlice.actions;

export default expensesSlice.reducer;
