import { Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import Layout from "../../../components/Layout";
import Diseases from "./diseases/Diseases";
import "./style.css";
import Treatments from "./treatments/Treatments";

const DiseasesMain = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        scrollButtons={false}
        aria-label="sure false"
      >
        <Tab label="Indwara" />
        <Tab label="Indwara z'amatungo" />
      </Tabs>
      {activeTab === 0 && <Diseases />}
      {activeTab === 1 && <Treatments />}
    </>
  );
};
export default DiseasesMain;
