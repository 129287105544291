import { Typography, Box, Button } from "@mui/material";
import Layout from "../../../components/Layout";
import styles from "../../index.module.scss";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import TableComponent from "../../../components/TableComponent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState, appActions } from "../../../store/app";
import AddIcon from "@mui/icons-material/Add";
import Storage from "../../../utils/storage";
import LoadingContainer from "../../../components/LoadingContainer";
import { useTranslation } from "../../../i18n";
import PopupMenu from "../../../components/PopupMenu";
import ConfirmModal from "../../../components/ConfirmModal";
import { deleteAction } from "../../../store/app/appActions";
import SearchComponent from "../../../components/SearchComponent";
import ChipComponent, { ChipData } from "../../../components/ChipComponent";
import { LoadingButton } from "@mui/lab";
import WeightView from "./WeightingView";
import FilterWeights from "./FilterWeighting";
import AddWeight from "./AddWeighting";
import {
  exportWeightsAction,
  getWeightsAction,
} from "../../../store/pig/weight/weightActions";
import { liveStockActions, LiveStockState } from "../../../store/pig";
import {
  Weight,
  weightsColumns,
  WeightsRow,
} from "../../../interfaces/Pig/weight";
import {
  deleteWeightService,
  searchWeightsService,
} from "../../../store/pig/weight/weightService";

const Weightings = (props: any) => {
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(50);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [query, setQuery] = useState("");
  const [isInit, setIsInit] = useState(true);
  const dispatch = useDispatch();
  const appState: AppState = useSelector((state: any) => state.app);
  const liveStockState: LiveStockState = useSelector(
    (state: any) => state.liveStock
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [chipData, setChipData] = useState([]);
  const [weight, setWeight] = useState<any>();
  const [viewWeight, setViewWeight] = useState<boolean>();
  const open = Boolean(anchorEl);
  const token = Storage.getToken();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(
      getWeightsAction(
        `?page=${page}&sort=-createdAt&limit=${rowsPerPage}`,
        token
      )
    );
  }, []);

  useEffect(() => {
    if (!liveStockState.fetchedWeights) {
      dispatch(
        getWeightsAction(
          `?page=${page}&sort=-createdAt&limit=${rowsPerPage}${query}`,
          token
        )
      );
    }
  }, [liveStockState.fetchedWeights]);

  useEffect(() => {
    if (!appState.isFetching) {
      setCount(appState.count);
    }
  }, [appState.isFetching, appState.count]);

  useEffect(() => {
    if (chipData.length > 0) {
      let queryString = "";
      chipData.forEach((data: any) => {
        queryString += `&${data.id}=${data.filter}`;
      });
      setQuery(queryString);
      dispatch(liveStockActions.setFetchedWeights(false));
    } else if (chipData.length === 0 && query !== "") {
      setQuery("");
      dispatch(liveStockActions.setFetchedWeights(false));
    }
  }, [chipData]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (searchText) {
        dispatch(searchWeightsService(`?text=${searchText}`, token));
        setIsInit(false);
      } else {
        if (!appState.isFetching && !isInit) {
          dispatch(liveStockActions.setFetchedWeights(false));
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [searchText]);

  const createData = (weight: Weight): WeightsRow => {
    return {
      date: weight.date.substring(0, 10),
      title: weight.title,
      method: weight.method || "N/A",
      pigs: weight.pigs,
      totalWeight: `${weight.totalWeight} Kg`,
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setWeight(weight);
            setAnchorEl(event.currentTarget);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(liveStockActions.setFetchedWeights(false));
  };

  const filterHandler = (filterData: any) => {
    setChipData(filterData);
  };

  const handleChipDelete = (chipToDelete: ChipData) => {
    setChipData((chips) =>
      chips.filter((chip: ChipData) => chip.key !== chipToDelete.key)
    );
  };

  const exportExcelHandler = () => {
    dispatch(exportWeightsAction("?limit=1000", token, t("weights")));
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(liveStockActions.setFetchedWeights(false));
  };

  const deleteWeightHandler = () => {
    dispatch(
      deleteAction(
        deleteWeightService,
        weight?._id,
        token,
        t("Weights"),
        () => {},
        () => dispatch(liveStockActions.setFetchedWeights(false))
      )
    );
  };

  const rows = liveStockState.weights?.map((weight: Weight) =>
    createData(weight)
  );

  return (
    <>
      <Box className={styles.topContainer}>
        <Box>{t("welcome")}</Box>
        <Box className={styles.dFlex}>
          <SearchComponent setSearchText={setSearchText} />
          <LoadingButton
            variant="contained"
            startIcon={<SaveAltIcon />}
            loading={appState.isExportingExcel}
            onClick={exportExcelHandler}
            className={styles.exportExcelButton}
          >
            {t("exportExcel")}
          </LoadingButton>
        </Box>
      </Box>
      {appState.isFetching && <LoadingContainer />}
      {!appState.isFetching && (
        <>
          {chipData.length > 0 && (
            <ChipComponent
              chipData={chipData}
              handleDelete={handleChipDelete}
            />
          )}
          <Box className={styles.tableTopActions}>
            <Box>
              <Typography>{t("Weights")}</Typography>
            </Box>
            <Box>
              <Button
                onClick={() => dispatch(appActions.setOpenFilter(true))}
                startIcon={<AddIcon />}
              >
                {t("filter")}
              </Button>
              <Button
                onClick={() =>
                  dispatch(
                    appActions.setOpenForm({ open: true, action: "add" })
                  )
                }
                startIcon={<AddIcon />}
              >
                {t("addNew")}
              </Button>
            </Box>
          </Box>
          <TableComponent
            rows={rows}
            columns={weightsColumns}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
          {open && (
            <PopupMenu
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              onView={() => setViewWeight(true)}
              onUpdate={() =>
                dispatch(
                  appActions.setOpenForm({ open: true, action: "update" })
                )
              }
              onDelete={() => dispatch(appActions.setIsDeleting(true))}
            />
          )}
          {appState.isDeleting && (
            <ConfirmModal
              onConfirm={() => deleteWeightHandler()}
              content={`${t("confirmDeleteDesc")} ${weight?.description} ?`}
            />
          )}
          {appState.openForm.open && <AddWeight weight={weight} />}
          {appState.openFilter && (
            <FilterWeights
              open={appState.openFilter}
              close={() => dispatch(appActions.setOpenFilter(false))}
              filterHandler={filterHandler}
            />
          )}
          {viewWeight && (
            <WeightView
              open={viewWeight}
              onClose={() => setViewWeight(false)}
              weight={weight}
            />
          )}
        </>
      )}
    </>
  );
};

export default Weightings;
