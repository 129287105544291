import { liveStockActions } from "..";
import {
  exportExcel,
  requestFailed,
  setResponseAction,
} from "../../../utils/functions";
import { appActions } from "../../app";
import { pigActions } from "../../pigSlice";
import { getPigsService } from "../../pigSlice/pigService";
import { getPigWeightsService, getWeightsService, searchWeightsService } from "./weightService";

// GET ALL
export const getWeightsAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getWeightsService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(liveStockActions.setWeights(res.data));
        dispatch(appActions.setCount({ count: res.count }));
        dispatch(liveStockActions.setFetchedWeights(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// GET ALL
export const getPigWeightsAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setFetchingFormItems(true));
      const res = await getPigWeightsService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(liveStockActions.setWeightItems(res.data));
        dispatch(appActions.setCount({ count: res.count }));
        dispatch(liveStockActions.setFetchedWeights(true));
      }
      dispatch(appActions.setFetchingFormItems(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// EXPORT EXCEL
export const exportWeightsAction = (
  query: string,
  token: string,
  excelName: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsExportingExcel(true));
      const res = await getWeightsService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        var data = res.data.map((weight: any) => {
          return {
            ...weight,
            date: weight.date.substring(0, 10),
            createdAt: weight.createdAt.substring(0, 10),
            updatedAt: weight.updatedAt.substring(0, 10),
          };
        });
        exportExcel(data, excelName);
      }
      dispatch(appActions.setIsExportingExcel(false));
    } catch (err) {
      console.log(err);
    }
  };
};

//SEARCH
export const searchWeightsAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await searchWeightsService(query, token);
      console.log("resssss ", res);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(liveStockActions.setWeights(res.data));
        dispatch(appActions.setCount({ count: res.count }));
        dispatch(liveStockActions.setFetchedWeights(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const fetchPigs = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setFetchingFormItems(true));
      const pigs = await getPigsService(query, token);
      if (requestFailed(pigs)) {
        dispatch(setResponseAction(pigs));
      } else {
        dispatch(pigActions.setPigs(pigs.data));
        dispatch(liveStockActions.setPigsLeft(pigs.data));
      }
      dispatch(appActions.setFetchingFormItems(false));
    } catch (err) {
      console.log(err);
    }
  };
};
