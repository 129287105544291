import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenericForm from "../../../components/GenericForm";
import { useTranslation } from "../../../i18n";
import { appActions } from "../../../store/app";
import Storage from "../../../utils/storage";
import { addAction, updateAction } from "../../../store/app/appActions";
import { Item, units } from "../../../interfaces/Expenses/item";
import {
  addItemService,
  updateItemService,
} from "../../../store/expenses/items/itemService";
import { expensesActions } from "../../../store/expenses";
import InputForm from "../../../components/InputForm";
import { Form } from "antd";

export interface AddItemProps {
  item?: Item;
}

const AddItem = (props: AddItemProps) => {
  const appState = useSelector((state: any) => state.app);
  const token = Storage.getToken();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({
    name: "" as string,
    price: 0 as number,
    unit: {} as any,
    unitText: "" as any,
  });

  React.useEffect(() => {
    if (appState.openForm.action === "update" && props.item !== undefined) {
      setFormValues({
        name: props.item?.name,
        price: props.item?.price,
        unitText: "",
        unit: {
          name: t(props?.item?.unit),
          id: props?.item?.unit,
        },
      });
    }
  }, []);

  const submit = () => {
    const data = {
      name: form.getFieldValue("name"),
      price: +form.getFieldValue("price"),
      unit: form.getFieldValue("unit"),
    };

    console.log("data ", data);

    if (appState.openForm.action === "add") {
      dispatch(
        addAction(
          addItemService,
          data,
          token,
          () => {},
          () => dispatch(expensesActions.setFetchedItems(false))
        )
      );
    } else if (
      appState.openForm.action === "update" &&
      props.item !== undefined
    ) {
      dispatch(
        updateAction(
          updateItemService,
          props.item._id,
          data,
          token,
          () => {},
          () => dispatch(expensesActions.setFetchedItems(false))
        )
      );
    }
  };

  const onUnitChanged = (unit: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({ ...formValues, unit, unitText: "" });
    } else {
      setFormValues({ ...formValues, unitText: unit, unit: null });
    }
  };

  const newStaffElement = [
    {
      label: t("name"),
      name: "name",
      type: "text",
      rules: [{ required: true, message: "Item name is required" }],
    },
    {
      label: t("unit"),
      name: "unit",
      type: "select",
      options: units,
      rules: [{ required: true, message: "Item unit is required" }],
    },
    {
      label: t("price"),
      name: "price",
      type: "number",
      rules: [{ required: true, message: "Item price is required" }],
    },
  ];

  return (
    <InputForm
      open={appState.openForm.open}
      close={() => dispatch(appActions.closeForm())}
      form={form}
      styles="vertical"
      title={
        appState.openForm.action === "add" ? t("addItem") : t("updateItem")
      }
      formElements={newStaffElement}
      onSubmit={submit}
    />
    // <GenericForm
    //   open={appState.openForm.open}
    //   close={() => dispatch(appActions.closeForm())}
    //   title={
    //     appState.openForm.action === "add" ? t("addItem") : t("updateItem")
    //   }
    //   styles="vertical"
    //   formElements={newStaffElement}
    //   onSubmit={submit}
    // />
  );
};

export default AddItem;
