import { liveStockActions } from "../pig";
import {
  exportExcel,
  requestFailed,
  setResponseAction,
} from "../../utils/functions";
import { appActions } from "../app";
import { getBirthsService } from "../pig/birth/birthService";
import {
  getPigsService,
  getPigsStatsService,
  searchPigsService,
} from "./pigService";
import { pigActions } from ".";

// GET ALL
export const getPigsAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getPigsService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(pigActions.setPigs(res.data));
        // dispatch(liveStockActions.setFetchedPigsAt(new Date()));
        dispatch(pigActions.setCount(res.count));
        dispatch(pigActions.setFetchedPigs(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// EXPORT EXCEL
export const exportPigAction = (
  query: string,
  token: string,
  excelName: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsExportingExcel(true));
      const res = await getPigsService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        exportExcel(res.data, excelName);
      }
      dispatch(appActions.setIsExportingExcel(false));
    } catch (err) {
      console.log(err);
    }
  };
};

//SEARCH
export const searchPigAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await searchPigsService(query, token);
      console.log("resssss ", res);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(pigActions.setPigs(res.data));
        dispatch(pigActions.setCount(res.count));
        dispatch(liveStockActions.setFetchedReproduction(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const fetchBirthsAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(pigActions.setFetchingForm(true));
      const res = await getBirthsService(query, token);
      const pigs = await getPigsService(query, token);
      const boars = pigs.data.filter((result: any) => result.gender === "boar");
      const sows = pigs.data.filter((result: any) => result.gender === "sow");
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(pigActions.setBirths(res.data));
        dispatch(pigActions.setBoars(boars));
        dispatch(pigActions.setSows(sows));
        dispatch(pigActions.setFetchedForm(true));
      }
      dispatch(pigActions.setFetchingForm(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getPigsStatsAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setFetchingDashboard(true));
      const res = await getPigsStatsService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(appActions.setPigStats(res.data));
      }
      dispatch(appActions.setFetchingDashboard(false));
    } catch (err) {
      console.log(err);
    }
  };
};
