import { createSlice } from "@reduxjs/toolkit";
import { HttpResponse, OpenForm } from "../../interfaces/Application/store";
import { Expense } from "../../interfaces/Expenses/expense";
import { Report } from "../../interfaces/Application/report";

export interface AppState {
  isOnline: boolean;
  updateNeeded: boolean;
  isFetching: boolean;
  drawerFetching: boolean;
  fetchingFormItems: boolean;
  isExportingExcel: boolean;
  isSubmitting: boolean;
  isDeleting: boolean;
  fetchedStaff: boolean;
  isFetchingDashboard: false;
  pigStats: any;
  selectedProject: any;
  projectId: string;
  count: number;
  navIndex: number;
  projects: any;
  staff: any;
  openFilter: boolean;
  openForm: OpenForm;
  chartData: any;
  httpResponse: HttpResponse;
  monthlyExpenses: Expense[];
  fetchingMonthlyExpenses: boolean;
  fetchedMonthlyExpenses: boolean;
  dashboard: any;
  notifications: Notification[];
  reports: Report[];
  fetchedReports: boolean;
}

const appSlice = createSlice({
  name: "projects",
  initialState: {
    isOnline: true,
    updateNeeded: false,
    isFetching: false,
    drawerFetching: false,
    fetchingFormItems: false,
    isExportingExcel: false,
    isSubmitting: false,
    isDeleting: false,
    isFetchingDashboard: false,
    pigStats: {},
    fetchedStaff: true,
    selectedProject: {},
    projectId: "",
    count: 0,
    navIndex: 0,
    projects: [],
    notifications: [],
    staff: [],
    openFilter: false,
    openForm: {
      open: false,
      action: "",
    } as OpenForm,
    chartData: {},
    httpResponse: {
      statusCode: 0,
      message: "",
      status: "",
    } as HttpResponse,
    monthlyExpenses: [],
    fetchingMonthlyExpenses: false,
    fetchedMonthlyExpenses: false,
    dashboard: {},
    reports: [],
    fetchedReports: false,
  },
  reducers: {
    setNavIndex(state, action) {
      console.log("iii ", action.payload);
      state.navIndex = action.payload;
    },
    setStaff(state, action) {
      state.staff = action.payload;
    },
    setFetchedStaff(state, action) {
      state.fetchedStaff = action.payload;
    },
    changeSelectedProject(state, action) {
      state.selectedProject = action.payload.project;
    },
    setProjectId(state, action) {
      state.projectId = action.payload;
    },
    setUpdateNeeded(state, action) {
      state.updateNeeded = action.payload;
    },
    setIsExportingExcel(state, action) {
      state.isExportingExcel = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setDrawerIsFetching(state, action) {
      state.drawerFetching = action.payload;
    },
    setIsSubmitting(state, action) {
      state.isSubmitting = action.payload;
    },
    setFetchingDashboard(state, action) {
      state.isFetchingDashboard = action.payload;
    },
    setFetchingFormItems(state, action) {
      state.fetchingFormItems = action.payload;
    },
    setIsDeleting(state, action) {
      state.isDeleting = action.payload;
    },
    setOpenFilter(state, action) {
      state.openFilter = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload.count;
    },
    setPigStats(state, action) {
      state.pigStats = action.payload;
    },
    setProject(state, action) {
      state.projects = action.payload.projects;
      state.updateNeeded = false;
      state.isFetching = false;
    },
    resetError(state) {
      state.httpResponse.statusCode = 0;
      state.httpResponse.message = "";
    },
    setHttpResponse(state, action) {
      state.httpResponse.statusCode = action.payload.statusCode;
      state.httpResponse.status = action.payload.status;
      state.httpResponse.message = action.payload.message;
    },
    setOpenForm(state, action) {
      state.openForm = {
        open: action.payload.open,
        action: action.payload.action,
      };
    },
    closeForm(state) {
      state.openForm = { open: false, action: "" };
    },
    setMonthlyExpenses(state, action) {
      state.monthlyExpenses = action.payload;
    },
    setFetchingMonthlyExpenses(state, action) {
      state.fetchingMonthlyExpenses = action.payload;
    },
    setFetchedMonthlyExpenses(state, action) {
      state.fetchedMonthlyExpenses = action.payload;
    },
    setDashboard(state, action) {
      state.dashboard = action.payload;
    },
    setNotifications(state, action) {
      state.notifications = action.payload;
    },
    setIsOnline(state, action) {
      state.isOnline = action.payload;
    },
    setReports(state, action) {
      state.reports = action.payload;
    },
    setFetchedReports(state, action) {
      state.fetchedReports = action.payload;
    },
  },
});

export const appActions = appSlice.actions;

export default appSlice.reducer;
