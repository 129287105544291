import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getDocumentsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/document${query}`, token);
};

export const searchDocumentsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/document/search${query}`, token);
};

export const updateDocumentService = async (
  documentId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/document/${documentId}`,
    data,
    token
  );
};

export const addDocumentService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/document`, data, token);
};

export const deleteDocumentService = async (documentId: string, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/document/${documentId}`, token);
};
