import { Typography, Box, Button } from "@mui/material";
import Layout from "../../../components/Layout";
import styles from "../../index.module.scss";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import TableComponent from "../../../components/TableComponent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState, appActions } from "../../../store/app";
import AddIcon from "@mui/icons-material/Add";
import Storage from "../../../utils/storage";
import LoadingContainer from "../../../components/LoadingContainer";
import { useTranslation } from "../../../i18n";
import PopupMenu from "../../../components/PopupMenu";
import ConfirmModal from "../../../components/ConfirmModal";
import { deleteAction } from "../../../store/app/appActions";
import SearchComponent from "../../../components/SearchComponent";
import ChipComponent, { ChipData } from "../../../components/ChipComponent";
import { LoadingButton } from "@mui/lab";
import {
  exportReproductionAction,
  searchReproductionAction,
} from "../../../store/pig/reproduction/reproductionActions";
import { Requisition, requisitionColumns, RequisitionRow } from "../../../interfaces/Expenses/requisition";
import { expensesActions, ExpensesState } from "../../../store/expenses";
import { getRequisitionsAction } from "../../../store/expenses/requisitions/requisitionActions";
import { deleteRequisitionService } from "../../../store/expenses/requisitions/requisitionService";
import FilterRequisition from "./FilterRequisition";
import AddRequisition from "./AddRequisition";
import RequisitionView from "./RequisitionView";
import { numberWithCommas } from "../../../utils/functions";

const Requisitions = () => {
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(50);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [query, setQuery] = useState("");
  const [isInit, setIsInit] = useState(true);
  const dispatch = useDispatch();
  const appState: AppState = useSelector((state: any) => state.app);
  const expensesState: ExpensesState = useSelector((state: any) => state.expenses);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [chipData, setChipData] = useState([]);
  const [requisition, setRequisition] = useState<any>();
  const [viewRequisition, setViewRequisition] = useState<boolean>();
  const open = Boolean(anchorEl);
  const token = Storage.getToken();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(
      getRequisitionsAction(
        `?page=${page}&sort=-createdAt&limit=${rowsPerPage}&born=false`,
        token
      )
    );
  }, []);

  useEffect(() => {
    if (!expensesState.fetchedRequisitions) {
      dispatch(
        getRequisitionsAction(
          `?page=${page}&sort=-createdAt&limit=${rowsPerPage}&born=false&${query}`,
          token
        )
      );
    }
  }, [dispatch, expensesState.fetchedRequisitions, token]);

  useEffect(() => {
    if (!appState.isFetching) {
      setCount(appState.count);
    }
  }, [appState.isFetching, appState.count]);

  useEffect(() => {
    if (chipData.length > 0) {
      let queryString = "";
      chipData.forEach((data: any) => {
        queryString += `&${data.id}=${data.filter}`;
      });
      setQuery(queryString);
      dispatch(expensesActions.setFetchedRequisitions(false));
    } else if (chipData.length === 0 && query !== "") {
      setQuery("");
      dispatch(expensesActions.setFetchedRequisitions(false));
    }
  }, [chipData, dispatch, query]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (searchText) {
        dispatch(searchReproductionAction(`?text=${searchText}`, token));
        setIsInit(false);
      } else {
        if (!appState.isFetching && !isInit) {
          dispatch(expensesActions.setFetchedRequisitions(false));
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [appState.isFetching, dispatch, isInit, searchText, token]);

  const createData = (requisition: Requisition): RequisitionRow => {
    return {
      date: requisition?.date.substring(0,10),
      category: requisition?.category,
      description: requisition?.description,
      quantityRequested: requisition?.quantity,
      unitCost: `${numberWithCommas(requisition?.unitCost)} RWF`,
      amount: `${numberWithCommas(requisition?.quantity * requisition?.unitCost)} RWF`,
      status: requisition?.status,
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setRequisition(requisition);
            setAnchorEl(event.currentTarget);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(expensesActions.setFetchedRequisitions(false));
  };

  const filterHandler = (filterData: any) => {
    setChipData(filterData);
  };

  const handleChipDelete = (chipToDelete: ChipData) => {
    setChipData((chips) =>
      chips.filter((chip: ChipData) => chip.key !== chipToDelete.key)
    );
  };

  const exportExcelHandler = () => {
    dispatch(exportReproductionAction("?limit=1000", token, t("reproduction")));
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(expensesActions.setFetchedRequisitions(false));
  };

  const deleteRequisitionHandler = () => {
    dispatch(
      deleteAction(
        deleteRequisitionService,
        requisition?._id,
        token,
        t("reproduction"),
        () => {},
        () => dispatch(expensesActions.setFetchedRequisitions(false))
      )
    );
  };

  const rows = expensesState.requisitions?.map((requisition: Requisition) =>
    createData(requisition)
  );

  return (
    <>
      <Box className={styles.topContainer}>
        <Box>{t("welcome")}</Box>
        <Box className={styles.dFlex}>
          <SearchComponent setSearchText={setSearchText} />
          <LoadingButton
            variant="contained"
            startIcon={<SaveAltIcon />}
            loading={appState.isExportingExcel}
            onClick={exportExcelHandler}
            className={styles.exportExcelButton}
          >
            {t("exportExcel")}
          </LoadingButton>
        </Box>
      </Box>
      {appState.isFetching && <LoadingContainer />}
      {!appState.isFetching && (
        <>
          {chipData.length > 0 && (
            <ChipComponent
              chipData={chipData}
              handleDelete={handleChipDelete}
            />
          )}
          <Box className={styles.tableTopActions}>
            <Box>
              <Typography>{t("reproduction")}</Typography>
            </Box>
            <Box>
              <Button
                onClick={() => dispatch(appActions.setOpenFilter(true))}
                startIcon={<AddIcon />}
              >
                {t("filter")}
              </Button>
              <Button
                onClick={() =>
                  dispatch(
                    appActions.setOpenForm({ open: true, action: "add" })
                  )
                }
                startIcon={<AddIcon />}
              >
                {t("addNew")}
              </Button>
            </Box>
          </Box>
          <TableComponent
            rows={rows}
            columns={requisitionColumns}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
          {open && (
            <PopupMenu
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              onView={() => setViewRequisition(true)}
              onUpdate={() =>
                dispatch(
                  appActions.setOpenForm({ open: true, action: "update" })
                )
              }
              onDelete={() => dispatch(appActions.setIsDeleting(true))}
            />
          )}
          {appState.isDeleting && (
            <ConfirmModal
              onConfirm={() => deleteRequisitionHandler()}
              content={`${t("confirmDeleteDesc")} ${
                requisition?.boar?.earTag
              } ${requisition?.sow?.earTag} ?`}
            />
          )}
          {appState.openForm.open && (
            <AddRequisition requisition={requisition} />
          )}
          {appState.openFilter && (
            <FilterRequisition
              open={appState.openFilter}
              close={() => dispatch(appActions.setOpenFilter(false))}
              filterHandler={filterHandler}
            />
          )}
          {viewRequisition && (
            <RequisitionView
              open={viewRequisition}
              onClose={() => setViewRequisition(false)}
              requisition={requisition}
            />
          )}
        </>
      )}
    </>
  );
};

export default Requisitions;
