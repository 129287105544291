import { Typography, Box, Button } from "@mui/material";
import styles from "../../../index.module.scss";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import TableComponent from "../../../../components/TableComponent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState, appActions } from "../../../../store/app";
import AddIcon from "@mui/icons-material/Add";
import Storage from "../../../../utils/storage";
import LoadingContainer from "../../../../components/LoadingContainer";
import { useTranslation } from "../../../../i18n";
import PopupMenu from "../../../../components/PopupMenu";
import ConfirmModal from "../../../../components/ConfirmModal";
import { deleteAction } from "../../../../store/app/appActions";
import SearchComponent from "../../../../components/SearchComponent";
import ChipComponent, { ChipData } from "../../../../components/ChipComponent";
import { LoadingButton } from "@mui/lab";
import { expensesActions, ExpensesState } from "../../../../store/expenses";
import { exportVaccinesAction, getVaccinesAction, searchVaccinesAction } from "../../../../store/expenses/vaccine/vaccinesActions";
import { deleteVaccineService } from "../../../../store/expenses/vaccine/vaccineService";
import { Vaccine, vaccineColumns, VaccineRow } from "../../../../interfaces/Expenses/vaccine";
import FilterVaccine from "./FilterVaccine";
import VaccineView from "./VaccineView";
import AddVaccine from "./AddVaccine";

const Vaccines = () => {
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(50);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [query, setQuery] = useState("");
  const [isInit, setIsInit] = useState(true);
  const dispatch = useDispatch();
  const appState: AppState = useSelector((state: any) => state.app);
  const expensesState: ExpensesState = useSelector((state: any) => state.expenses);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [chipData, setChipData] = useState([]);
  const [vaccine, setVaccine] = useState<any>();
  const [viewVaccine, setViewVaccine] = useState<boolean>();
  const open = Boolean(anchorEl);
  const token = Storage.getToken();
  const { t } = useTranslation();

  console.log("Vaccine reload");

  useEffect(() => {
    dispatch(
      getVaccinesAction(
        `?page=${page}&sort=-createdAt&limit=${rowsPerPage}`,
        token
      )
    );
  }, []);

  useEffect(() => {
    if (!expensesState.fetchedVaccines) {
      dispatch(
        getVaccinesAction(
          `?page=${page}&sort=-createdAt&limit=${rowsPerPage}${query}`,
          token
        )
      );
    }
  }, [dispatch, expensesState.fetchedVaccines, page, query, rowsPerPage, token]);

  useEffect(() => {
    if (!appState.isFetching) {
      setCount(appState.count);
    }
  }, [appState.isFetching, appState.count]);

  useEffect(() => {
    if (chipData.length > 0) {
      let queryString = "";
      chipData.forEach((data: any) => {
        queryString += `&${data.id}=${data.filter}`;
      });
      setQuery(queryString);
      dispatch(expensesActions.setFetchedVaccine(false));
    } else if (chipData.length === 0 && query !== "") {
      setQuery("");
      dispatch(expensesActions.setFetchedVaccine(false));
    }
  }, [chipData, dispatch, query]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (searchText) {
        dispatch(searchVaccinesAction(`?text=${searchText}`, token));
        setIsInit(false);
      } else {
        if (!appState.isFetching && !isInit) {
          dispatch(expensesActions.setFetchedVaccine(false));
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [appState.isFetching, dispatch, isInit, searchText, token]);

  // name: string;
  // controlledDisease: string;
  // requiredRounds: string;
  // pigAge: number;
  // dosagePerRound: string;
  // roundCost: number;

  const createData = (vaccine: Vaccine): VaccineRow => {
    return {
  name: vaccine?.name,
  controlledDisease: vaccine?.controlledDisease?.name,
  requiredRounds: vaccine?.requiredRounds,
  pigAge: vaccine?.pigAge,
  dosagePerRound: vaccine?.dosagePerRound,
  roundCost: vaccine?.roundCost,
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setVaccine(vaccine);
            setAnchorEl(event.currentTarget);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(expensesActions.setFetchedVaccine(false));
  };

  const filterHandler = (filterData: any) => {
    setChipData(filterData);
  };

  const handleChipDelete = (chipToDelete: ChipData) => {
    setChipData((chips) =>
      chips.filter((chip: ChipData) => chip.key !== chipToDelete.key)
    );
  };

  const exportExcelHandler = () => {
    dispatch(exportVaccinesAction("?limit=1000", token, t("Vaccines")));
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(expensesActions.setFetchedVaccine(false));
  };

  const deleteStaffHandler = () => {
    dispatch(
      deleteAction(
        deleteVaccineService,
        vaccine?._id,
        token,
        t("staff"),
        () => {},
        () => dispatch(expensesActions.setFetchedVaccine(false))
      )
    );
  };

  const rows = expensesState.vaccines?.map((vaccine: Vaccine) => createData(vaccine));

  return (
    <>
      <Box className={styles.topContainer}>
        <Box>{t("welcome")}</Box>
        <Box className={styles.dFlex}>
          <SearchComponent setSearchText={setSearchText} />
          <LoadingButton
            variant="contained"
            startIcon={<SaveAltIcon />}
            loading={appState.isExportingExcel}
            onClick={exportExcelHandler}
            className={styles.exportExcelButton}
          >
            {t("exportExcel")}
          </LoadingButton>
        </Box>
      </Box>
      {appState.isFetching && <LoadingContainer />}
      {!appState.isFetching && (
        <>
          {chipData.length > 0 && (
            <ChipComponent
              chipData={chipData}
              handleDelete={handleChipDelete}
            />
          )}
          <Box className={styles.tableTopActions}>
            <Box>
              <Typography>{t("Vaccines")}</Typography>
            </Box>
            <Box>
              <Button
                onClick={() => dispatch(appActions.setOpenFilter(true))}
                startIcon={<AddIcon />}
              >
                {t("filter")}
              </Button>
              <Button
                onClick={() =>
                  dispatch(
                    appActions.setOpenForm({ open: true, action: "add" })
                  )
                }
                startIcon={<AddIcon />}
              >
                {t("addNew")}
              </Button>
            </Box>
          </Box>
          <TableComponent
            rows={rows}
            columns={vaccineColumns}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
          {open && (
            <PopupMenu
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              onView={() => setViewVaccine(true)}
              onUpdate={() =>
                dispatch(
                  appActions.setOpenForm({ open: true, action: "update" })
                )
              }
              onDelete={() => dispatch(appActions.setIsDeleting(true))}
            />
          )}
          {appState.isDeleting && (
            <ConfirmModal
              onConfirm={() => deleteStaffHandler()}
              content={`${t("confirmDeleteDesc")} ${vaccine?.name} ?`}
            />
          )}
          {appState.openForm.open && <AddVaccine vaccine={vaccine} />}
          {appState.openFilter && (
            <FilterVaccine
              open={appState.openFilter}
              close={() => dispatch(appActions.setOpenFilter(false))}
              filterHandler={filterHandler}
            />
          )}
          {viewVaccine && (
            <VaccineView
              open={viewVaccine}
              onClose={() => setViewVaccine(false)}
              vaccine={vaccine}
            />
          )}
        </>
      )}
    </>
  );
};

export default Vaccines;
