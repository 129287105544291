import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getStaffsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/staff${query}`, token);
};

export const searchStaffService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/staff/search${query}`, token);
};

export const updateStaffService = async (
  staffId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/staff/${staffId}`,
    data,
    token
  );
};

export const addStaffService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/staff`, data, token);
};

export const deleteStaffService = async (staffId: string, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/staff/${staffId}`, token);
};
