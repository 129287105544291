import { Box } from "@mui/material";
import ViewDrawer, { DetailItem } from "../../../../components/ViewDrawer";
import { useTranslation } from "../../../../i18n";
import { Vaccine } from "../../../../interfaces/Expenses/vaccine";
import styles from "../../../index.module.scss";

export interface VaccineViewProps {
  open: boolean;
  onClose: Function;
  vaccine: Vaccine;
}

const VaccineView = (props: VaccineViewProps) => {
  const vaccine: Vaccine = props.vaccine;
  const { t } = useTranslation();
  let count = 1;

  // controlledDisease: string;
  // description: string;
  // requiredRounds: number;
  // pigAge: number;
  // dosagePerRound: number;
  // roundCost: number;
  // administration: string;

  return (
    <ViewDrawer open={props.open} onClose={props.onClose}>
      <Box className={styles.item_details}>
          <DetailItem
          index={count++}
          label={t("name")}
          value={vaccine?.name}
        />
        <DetailItem
            index={count++}
            label={t("description")}
            value={vaccine?.description}
          />
        <DetailItem
          index={count++}
          label={t("requiredRounds")}
          value={vaccine?.requiredRounds}
        />
        <DetailItem
          index={count++}
          label={t("roundCost")}
          value={vaccine?.roundCost}
        />
        <DetailItem
          index={count++}
          label={t("administration")}
          value={vaccine?.administration}
        />
        <DetailItem
          index={count++}
          label={t("pigAge")}
          value={vaccine?.pigAge}
        />
        <DetailItem
          index={count++}
          label={t("dosagePerRound")}
          value={vaccine?.dosagePerRound}
        />
        <DetailItem
          index={count++}
          label={t("createdBy")}
          value={vaccine?.createdBy}
        />
        <DetailItem
          index={count++}
          label={t("createdAt")}
          value={vaccine?.createdAt.substring(0, 10)}
        />
        <DetailItem
          index={count++}
          label={t("updatedAt")}
          value={vaccine?.updatedAt.substring(0, 10)}
        />
      </Box>
    </ViewDrawer>
  );
};

export default VaccineView;
