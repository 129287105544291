import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getBirthsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/birth${query}`, token);
};

export const searchBirthsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/birth/search${query}`, token);
};

export const updateBirthService = async (
  birthId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/birth/${birthId}`,
    data,
    token
  );
};

export const addBirthService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/birth`, data, token);
};

export const deleteBirthService = async (birthId: string, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/birth/${birthId}`, token);
};
