import { Box } from "@mui/material";
import ViewDrawer, { DetailItem } from "../../../../components/ViewDrawer";
import { useTranslation } from "../../../../i18n";
import { Treatment } from "../../../../interfaces/Expenses/treatment";
import styles from "../../../index.module.scss";

export interface TreatmentViewProps {
  open: boolean;
  onClose: Function;
  treatment: Treatment;
}

const TreatmentView = (props: TreatmentViewProps) => {
  const treatment: Treatment = props.treatment;
  const { t } = useTranslation();
  let count = 1;

  return (
    <ViewDrawer open={props.open} onClose={props.onClose}>
      <Box className={styles.item_details}>
          <DetailItem
          index={count++}
          label={t("category")}
          value={treatment?.category}
        />
        <DetailItem
            index={count++}
            label={t("treatment")}
            value={treatment?.treatment}
          />
        <DetailItem
          index={count++}
          label={t("cost")}
          value={treatment?.cost}
        />
        <DetailItem
          index={count++}
          label={t("observation")}
          value={treatment?.observation}
        />
        <DetailItem
          index={count++}
          label={t("createdBy")}
          value={treatment?.createdBy}
        />
        <DetailItem
          index={count++}
          label={t("createdAt")}
          value={treatment?.createdAt.substring(0, 10)}
        />
        <DetailItem
          index={count++}
          label={t("updatedAt")}
          value={treatment?.updatedAt.substring(0, 10)}
        />
      </Box>
    </ViewDrawer>
  );
};

export default TreatmentView;
