import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  DialogContentText,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import styles from "./index.module.scss";
import deleteSvg from "../assets/images/delete.svg";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "../store/app";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "../i18n";

interface ConfirmModalProps {
  onConfirm: Function;
  content: string;
}

export default function ConfirmModal(props: ConfirmModalProps) {
  const appState = useSelector((state: any) => state.app);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div>
      <Dialog
        open={appState.isDeleting}
        classes={{ paper: styles.confirmModal }}
        onClose={() => dispatch(appActions.setIsDeleting(false))}
        style={{ padding: "3rem" }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className={styles.vertical}
          >
            <img
              src={deleteSvg}
              alt="delete"
              style={{ height: "10rem", width: "10rem" }}
            />
            <Typography className={styles.confirmModal_title}>
              {t("confirmDelete")}
            </Typography>
            <Box style={{ maxWidth: "50%", marginTop: "1rem" }}>
              <Typography className={styles.confirmModal_text}>
                {props.content}
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box className={styles.form_action}>
            {appState.isSubmitting && (
              <Button
                variant="contained"
                className={styles.confirmModal_Confirm}
              >
                <CircularProgress
                  size={30}
                  color="inherit"
                  className={styles.circularProgress}
                />
              </Button>
            )}
            {!appState.isSubmitting && <Button
              onClick={() => dispatch(appActions.setIsDeleting(false))}
              className={styles.confirmModal_Cancel}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              {t("cancel")}
            </Button>}
            {!appState.isSubmitting && (
              <Button
                onClick={() => props.onConfirm()}
                variant="contained"
                startIcon={<DeleteIcon />}
                className={styles.confirmModal_Confirm}
              >
                {t("delete")}
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
