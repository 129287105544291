import { User } from "../Application/user";
import i18n from "../../i18n";

export interface RequisitionColumn {
  id:
    | "date"
    | "category"
    | "description"
    | "quantityRequested"
    | "amount"
    | "status"
    | "cost"
    | "action";
  label: string;
  minWidth?: number;
  align?: "center";
}

export const requisitionColumns: RequisitionColumn[] = [
  { id: "date", label: i18n.t("date"), align: "center" },
  { id: "category", label: i18n.t("category"), align: "center" },
  { id: "description", label: i18n.t("description"), align: "center" },
  { id: "quantityRequested", label: i18n.t("quantity"), align: "center" },
  { id: "amount", label: i18n.t("amount"), align: "center" },
  { id: "status", label: i18n.t("status"), align: "center" },
  { id: "action", label: i18n.t("action"), align: "center" },
];

export interface RequisitionRow {
  date: string;
  category: string;
  description: string;
  quantityRequested: number;
  unitCost: string;
  amount: string;
  status: string;
  action: any;
}

export interface Requisition {
  _id: string;
  category: string; //Wages, vet services, medecine, feeding...
  description: string;
  quantity: number;
  quantityGranted: number;
  unitCost: number;
  status: string; // Pending, Declined, Approved
  date: string;
  createdBy?: User;
  createdAt: string;
  updatedAt: string;
}

export interface pigCategory {
  name: string;
  id: "piglet" | "pig";
}

export const pigCategories: pigCategory[] = [
  {
    name: i18n.t("piglet"),
    id: "piglet",
  },
  {
    name: i18n.t("pig"),
    id: "pig",
  },
];
