import {
  Typography,
  Box,
  Button,
} from "@mui/material";
import Layout from "../../../components/Layout";
import styles from "../../index.module.scss";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import TableComponent from "../../../components/TableComponent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState, appActions } from "../../../store/app";
import AddIcon from "@mui/icons-material/Add";
import Storage from "../../../utils/storage";
import LoadingContainer from "../../../components/LoadingContainer";
import { useTranslation } from "../../../i18n";
import PopupMenu from "../../../components/PopupMenu";
import ConfirmModal from "../../../components/ConfirmModal";
import { deleteAction } from "../../../store/app/appActions";
import SearchComponent from "../../../components/SearchComponent";
import ChipComponent, { ChipData } from "../../../components/ChipComponent";
import { LoadingButton } from "@mui/lab";
import InsuranceView from "./InsuranceView";
import FilterInsurances from "./FilterInsurance";
import AddInsurance from "./AddInsurance";
import { expensesActions, ExpensesState } from "../../../store/expenses";
import { exportInsurancesAction, getInsurancesAction } from "../../../store/expenses/insurance/insuranceActions";
import { deleteInsuranceService, searchInsuranceService } from "../../../store/expenses/insurance/insuranceService";
import { Insurance, insuranceColumns, InsuranceRow } from "../../../interfaces/Expenses/insurance";


const Insurances = (props: any) => {
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(50);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [query, setQuery] = useState("");
  const [isInit, setIsInit] = useState(true);
  const dispatch = useDispatch();
  const appState: AppState = useSelector((state: any) => state.app);
  const expensesState: ExpensesState = useSelector((state: any) => state.expenses);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [chipData, setChipData] = useState([]);
  const [insurance, setInsurance] = useState<any>();
  const [viewInsurance, setViewInsurance] = useState<boolean>();
  const open = Boolean(anchorEl);
  const token = Storage.getToken();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(
      getInsurancesAction(`?page=${page}&sort=-createdAt&limit=${rowsPerPage}`, token)
    );
  }, []);

  useEffect(() => {
    if (!expensesState.fetchedInsurance) {
      dispatch(
        getInsurancesAction(
          `?page=${page}&sort=-createdAt&limit=${rowsPerPage}${query}`,
          token
        )
      );
    }
  }, [expensesState.fetchedInsurance]);

  useEffect(() => {
    if (!appState.isFetching) {
      setCount(appState.count);
    }
  }, [appState.isFetching, appState.count]);

  useEffect(() => {
    if (chipData.length > 0) {
      let queryString = "";
      chipData.forEach((data: any) => {
        queryString += `&${data.id}=${data.filter}`;
      });
      setQuery(queryString);
      dispatch(expensesActions.setFetchedInsurance(false));
    } else if (chipData.length === 0 && query !== "") {
      setQuery("");
      dispatch(expensesActions.setFetchedInsurance(false));
    }
  }, [chipData]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (searchText) {
        dispatch(searchInsuranceService(`?text=${searchText}`, token));
        setIsInit(false);
      } else {
        if (!appState.isFetching && !isInit) {
          dispatch(expensesActions.setFetchedInsurance(false));
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [searchText]);

  const createData = (insurance: Insurance): InsuranceRow => {
    return {
      insuranceCompany: insurance.insuranceCompany,
      pigs: insurance.pigs.length,
      startDate: insurance.startDate.substring(0,10),
      expiryDate: insurance.expiryDate.substring(0,10),
      unitCost: insurance.unitCost,
      totalValue: insurance.totalValue,
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setInsurance(insurance);
            setAnchorEl(event.currentTarget);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(expensesActions.setFetchedInsurance(false));
  };

  const filterHandler = (filterData: any) => {
    setChipData(filterData);
  };

  const handleChipDelete = (chipToDelete: ChipData) => {
    setChipData((chips) =>
      chips.filter((chip: ChipData) => chip.key !== chipToDelete.key)
    );
  };

  const exportExcelHandler = () => {
    dispatch(exportInsurancesAction("?limit=1000", token, t("Insurances")));
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(expensesActions.setFetchedInsurance(false));
  };

  const deleteInsuranceHandler = () => {
    dispatch(
      deleteAction(
        deleteInsuranceService,
        insurance?._id,
        token,
        t("Insurances"),
        () => {},
        () => dispatch(expensesActions.setFetchedInsurance(false))
      )
    );
  };

  const rows = expensesState.insurances?.map((insurance: Insurance) => createData(insurance));

  return (
    <>
      <Box className={styles.topContainer}>
        <Box>{t("welcome")}</Box>
        <Box className={styles.dFlex}>
          <SearchComponent setSearchText={setSearchText} />
          <LoadingButton
            variant="contained"
            startIcon={<SaveAltIcon />}
            loading={appState.isExportingExcel}
            onClick={exportExcelHandler}
            className={styles.exportExcelButton}
          >
            {t("exportExcel")}
          </LoadingButton>
        </Box>
      </Box>
      {appState.isFetching && <LoadingContainer />}
      {!appState.isFetching && (
        <>
          {chipData.length > 0 && (
            <ChipComponent
              chipData={chipData}
              handleDelete={handleChipDelete}
            />
          )}
          <Box className={styles.tableTopActions}>
            <Box>
              <Typography>{t("Insurances")}</Typography>
            </Box>
            <Box>
              <Button
                onClick={() => dispatch(appActions.setOpenFilter(true))}
                startIcon={<AddIcon />}
              >
                {t("filter")}
              </Button>
              <Button
                onClick={() =>
                  dispatch(
                    appActions.setOpenForm({ open: true, action: "add" })
                  )
                }
                startIcon={<AddIcon />}
              >
                {t("addNew")}
              </Button>
            </Box>
          </Box>
          <TableComponent
            rows={rows}
            columns={insuranceColumns}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
          {open && (
            <PopupMenu
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              onView={() => setViewInsurance(true)}
              onUpdate={() =>
                dispatch(
                  appActions.setOpenForm({ open: true, action: "update" })
                )
              }
              onDelete={() => dispatch(appActions.setIsDeleting(true))}
            />
          )}
          {appState.isDeleting && (
            <ConfirmModal
              onConfirm={() => deleteInsuranceHandler()}
              content={`${t("confirmDeleteDesc")} ${insurance?.description} ?`}
            />
          )}
          {appState.openForm.open && <AddInsurance insurance={insurance} />}
          {appState.openFilter && (
            <FilterInsurances
              open={appState.openFilter}
              close={() => dispatch(appActions.setOpenFilter(false))}
              filterHandler={filterHandler}
            />
          )}
          {viewInsurance && (
            <InsuranceView
              open={viewInsurance}
              onClose={() => setViewInsurance(false)}
              insurance={insurance}
            />
          )}
        </>
      )}
    </>
  );
};

export default Insurances;
