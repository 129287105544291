import { Typography } from 'antd';

export interface LabelValuePairProps {
  label: string;
  value: string;
}

export const LabelValuePair = (props: LabelValuePairProps) => {
  return (
    <div className="flex items-center justify-between w-full my-2">
      <Typography className="font-bold basis-2/6">{props.label}</Typography>
      <Typography className="text-left text-gray-400 basis-4/6 ">{props?.value}</Typography>
    </div>
  );
};

export const LabelValuePair2 = (props: LabelValuePairProps) => {
  return (
    <div className="flex justify-between w-full my-2">
      <div className="font-bold me-2">{props.label}</div>
      <div className="text-gray-500">{props?.value}</div>
    </div>
  );
};
