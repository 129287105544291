import { User } from "../Application/user";
import i18n from "../../i18n";

export interface ItemColumn {
  id: "name" | "price" | "unit" | "stock" | "action";
  label: string;
  minWidth?: number;
  align?: "center";
}

export const itemColumns: ItemColumn[] = [
  { id: "name", label: i18n.t("date"), align: "center" },
  { id: "price", label: i18n.t("price"), align: "center" },
  { id: "unit", label: i18n.t("unit"), align: "center" },
  { id: "stock", label: i18n.t("stock"), align: "center" },
  { id: "action", label: i18n.t("action"), align: "center" },
];

export interface Unit {
  name: string;
  _id: "L" | "Kg" | "m";
}

export const units: Unit[] = [
  {
    name: i18n.t("L"),
    _id: "L",
  },
  {
    name: i18n.t("Kg"),
    _id: "Kg",
  },
  {
    name: i18n.t("m"),
    _id: "m",
  },
];

export interface ItemRow {
  name: string;
  price: string;
  unit: string;
  stock: number;
  action: any;
}

export interface Item {
  _id: string;
  name: string;
  price: number;
  unit: string;
  stock: number;
  safetyStock: number;
  createdBy?: User;
  createdAt: string;
  updatedAt: string;
}
