import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "../../../../i18n";
import { AppState, appActions } from "../../../../store/app";
import Storage from "../../../../utils/storage";
import { expensesActions, ExpensesState } from "../../../../store/expenses";
import GenericForm from "../../../../components/GenericForm";
import { Pig } from "../../../../interfaces/Pig/pig";
import { Birth } from "../../../../interfaces/Pig/birth";
import { addAction, updateAction } from "../../../../store/app/appActions";
import {
  addVaccinationService,
  updateVaccinationService,
} from "../../../../store/expenses/vaccination/vaccinationService";
import { Vaccination } from "../../../../interfaces/Expenses/vaccination";
import { pigCategories, pigCategory } from "../../../../interfaces/Expenses/requisition";
import { fetchVaccinationReqAction } from "../../../../store/expenses/vaccination/vaccinationActions";
import { PigState } from "../../../../store/pigSlice";

export interface AddStaffProps {
  Vaccination?: Vaccination;
}

const AddVaccination = (props: AddStaffProps) => {
  const appState: AppState = useSelector((state: any) => state.app);
  const expensesState: ExpensesState = useSelector(
    (state: any) => state.expenses
  );
  const pigState: PigState = useSelector(
    (state: any) => state.pig
  );
  const token = Storage.getToken();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({
    title: "" as string,
    vaccine: {} as any,
    vaccineText: "" as string,
    date: new Date().toISOString().substring(0, 10) as string,
    category: pigCategories[1] as pigCategory | any,
    pig: {} as Pig | any,
    pigText: "" as string,
    birth: {} as any,
    birthText: "" as string,
  });

  React.useEffect(() => {
    dispatch(fetchVaccinationReqAction("", token));
    // if (appState.openForm.action === "update" && props.disease !== undefined) {
    //   setFormValues({
    //     name: props.disease.name,
    //     category: {
    //       name: props.disease.category,
    //     },
    //     categoryText: "",
    //     symptoms: props.disease.symptoms,
    //     severity: {
    //       name: props.disease.severity,
    //     },
    //     severityText: "",
    //     prevention: props.disease.prevention,
    //     Vaccination: props.disease.Vaccination,
    //   });
    // }
  }, []);

  const onBirthChanged = (birth: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({
        ...formValues,
        birth,
        birthText: "",
      });
    } else {
      setFormValues({
        ...formValues,
        birthText: birth,
        birth: null,
      });
    }
  };

  const onPigChanged = (pig: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({ ...formValues, pig, pigText: "" });
    } else {
      setFormValues({ ...formValues, pigText: pig, pig: null });
    }
  };

  const onVaccineChanged = (vaccine: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({ ...formValues, vaccine, vaccineText: "" });
    } else {
      setFormValues({ ...formValues, vaccineText: vaccine, vaccine: null });
    }
  };

  const submit = () => {
    const data = {
      title: formValues.title,
      vaccine: formValues.vaccine?._id,
      category: formValues.category.id,
      pig: formValues.pig?._id,
      birth: formValues.birth?._id,
    };

    if (appState.openForm.action === "add") {
      dispatch(
        addAction(
          addVaccinationService,
          data,
          token,
          () => {},
          () => dispatch(expensesActions.setFetchedVaccination(false))
        )
      );
    } else if (
      appState.openForm.action === "update" &&
      props.Vaccination !== undefined
    ) {
      dispatch(
        updateAction(
          updateVaccinationService,
          props.Vaccination._id,
          data,
          token,
          () => {},
          () => dispatch(expensesActions.setFetchedVaccination(false))
        )
      );
    }
  };

  const birthValues = pigState?.births?.map((birth: Birth) => {
    return {
      _id: birth._id,
      name: `${birth?.reproduction.sow.earTag} - ${birth?.date.substring(
        0,
        10
      )}`,
    };
  });

  const newVaccinationElement = [
    {
      label: t("title"),
      inputComponent: "input",
      placeholder: "Andika ubuvuzi bwatanzwe",
      required: true,
      value: formValues?.title,
      onChange: (title: any) =>
        setFormValues({ ...formValues, title }),
    },
    {
      label: t("vaccine"),
      identifier: "vaccine",
      inputComponent: "select",
      required: true,
      values: expensesState.diseases,
      value: formValues.vaccine?.name || formValues.vaccineText,
      onChange: (vaccine: any, found: boolean) =>
        onVaccineChanged(vaccine, found),
    },
    {
      label: t("date"),
      inputComponent: "date",
      placeholder: "Andika hano",
      required: true,
      value: formValues?.date || "",
      onChange: (date: any) =>
        setFormValues({ ...formValues, date }),
    },
    {
      label: t("birth"),
      identifier: "birth",
      inputComponent: "select",
      required: true,
      values: birthValues,
      value: formValues.birth?.name || formValues.birthText,
      valueLabel: "sow",
      onChange: (reproduction: any, found: boolean) =>
        onBirthChanged(reproduction, found),
    },
    {
      label: t("pig"),
      identifier: "pig",
      inputComponent: "select",
      required: true,
      values: pigState.pigs,
      value: formValues.pig?.earTag || formValues.pigText,
      valueLabel: "earTag",
      onChange: (pig: any, found: boolean) => onPigChanged(pig, found),
    },
  ];

  return (
    <GenericForm
      open={appState.openForm.open}
      close={() => dispatch(appActions.closeForm())}
      title={
        appState.openForm.action === "add"
          ? t("addVaccination")
          : t("updateDisease")
      }
      styles="horizontal"
      formElements={newVaccinationElement}
      onSubmit={submit}
    />
  );
};

export default AddVaccination;
