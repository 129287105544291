import { Box } from "@mui/material";
import ViewDrawer, { DetailItem } from "../../../../components/ViewDrawer";
import { useTranslation } from "../../../../i18n";
import { Disease } from "../../../../interfaces/Expenses/disease";
import styles from "../../../index.module.scss";

export interface DiseaseViewProps {
  open: boolean;
  onClose: Function;
  disease: Disease;
}

const DiseaseView = (props: DiseaseViewProps) => {
  const disease: Disease = props.disease;
  const { t } = useTranslation();
  let count = 1;

  return (
    <ViewDrawer open={props.open} onClose={props.onClose}>
      <Box className={styles.item_details}>
          <DetailItem
            index={count++}
            label={t("diseaseName")}
            value={disease?.name}
          />
          <DetailItem
          index={count++}
          label={t("category")}
          value={disease?.category}
        />
        <DetailItem
          index={count++}
          label={t("severity")}
          value={disease?.severity}
        />
        <DetailItem
          index={count++}
          label={t("diseasePrevention")}
          value={disease?.prevention}
        />
        <DetailItem
          index={count++}
          label={t("createdBy")}
          value={disease?.createdBy}
        />
        <DetailItem
          index={count++}
          label={t("createdAt")}
          value={disease?.createdAt.substring(0, 10)}
        />
        <DetailItem
          index={count++}
          label={t("updatedAt")}
          value={disease?.updatedAt.substring(0, 10)}
        />
      </Box>
    </ViewDrawer>
  );
};

export default DiseaseView;
