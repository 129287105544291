import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenericForm from "../../../components/GenericForm";
import { useTranslation } from "../../../i18n";
import { appActions } from "../../../store/app";
import Storage from "../../../utils/storage";
import { BusinessState, businessActions } from "../../../store/business";
import { Sale } from "../../../interfaces/Business/sales";
import { fetchClientsAndPigsAction } from "../../../store/business/sales/salesActions";
import { addAction, updateAction } from "../../../store/app/appActions";
import {
  addSalesService,
  updateSalesService,
} from "../../../store/business/sales/salesService";
import { PigState } from "../../../store/pigSlice";

export interface AddSalesProps {
  sales?: Sale;
}

const AddSales = (props: AddSalesProps) => {
  const appState = useSelector((state: any) => state.app);
  const token = Storage.getToken();
  const dispatch = useDispatch();
  const businessState: BusinessState = useSelector(
    (state: any) => state.business
  );

  const pigState: PigState = useSelector(
    (state: any) => state.pig
  );
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({
    date: new Date().toISOString().substring(0, 10),
    client: {} as any,
    clientText: "" as any,
    pig: {} as any,
    pigText: "" as any,
    costEstimate: 0 as number,
    weight: 0 as number,
    unitPrice: 3000 as number,
    totalPrice: 0 as number,
    paid: 0 as number,
  });

  console.log("pigState.pigs ", pigState.pigs)

  React.useEffect(() => {
    dispatch(fetchClientsAndPigsAction("", token));
    // if (appState.openForm.action === "update" && props.supplier !== undefined) {
    //   setFormValues({
    //     name: props.supplier.name,
    //     contact: props.supplier.contact,
    //     tinNumber: props.supplier.tinNumber,
    //   });
    // }
  }, []);

  const onClientChanged = (client: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({ ...formValues, client, clientText: "" });
    } else {
      setFormValues({ ...formValues, clientText: client, client: null });
    }
  };

  const onPigChanged = (pig: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({
        ...formValues,
        pig,
        costEstimate: pig.costEstimate,
        weight: pig.weight,
        totalPrice: pig.weight * formValues.unitPrice,
        paid: pig.weight * formValues.unitPrice,
        pigText: "",
      });
    } else {
      setFormValues({ ...formValues, pigText: pig, pig: null });
    }
  };

  const submit = () => {
    const data: any = {
      date: formValues.date,
      pig: formValues.pig._id,
      costEstimate: formValues.costEstimate,
      weight: formValues.weight,
      unitPrice: +formValues.unitPrice,
      paid: formValues.paid,
    };

    if (formValues.client.name) {
      data.client = formValues.client._id;
    } else {
      data.client = formValues.clientText;
    }

    // console.log("cccccccccccccc ", data);

    if (appState.openForm.action === "add") {
      dispatch(
        addAction(
          addSalesService,
          data,
          token,
          () => {},
          () => dispatch(businessActions.setFetchedSales(false))
        )
      );
    } else if (
      appState.openForm.action === "update" &&
      props.sales !== undefined
    ) {
      dispatch(
        updateAction(
          updateSalesService,
          props.sales._id,
          data,
          token,
          () => {},
          () => dispatch(businessActions.setFetchedSales(false))
        )
      );
    }
  };

  const newSalesElement = [
    {
      label: t("date"),
      inputComponent: "date",
      required: true,
      value: formValues?.date || "",
      onChange: (date: any) => setFormValues({ ...formValues, date }),
    },
    {
      label: t("client"),
      identifier: "client",
      inputComponent: "select",
      required: true,
      values: businessState.clients,
      value: formValues.client?.name || formValues.clientText,
      onChange: (client: any, found: boolean) => onClientChanged(client, found),
    },
    {
      label: t("pig"),
      identifier: "pig",
      inputComponent: "select",
      required: true,
      values: pigState.pigs,
      value: formValues.pig?.earTag || formValues.pigText,
      valueLabel: "earTag",
      onChange: (pig: any, found: boolean) => onPigChanged(pig, found),
    },
    {
      label: t("estimatedCost"),
      inputComponent: "input",
      placeholder: "Andika hano",
      type: "number",
      required: true,
      disabled: true,
      value: formValues?.costEstimate,
      onChange: (costEstimate: any) =>
        setFormValues({ ...formValues, costEstimate }),
    },
    {
      label: t("weight"),
      inputComponent: "input",
      placeholder: "Andika hano",
      type: "number",
      required: true,
      value: formValues?.weight,
      onChange: (weight: any) => setFormValues({ ...formValues, weight }),
    },
    {
      label: t("kgUnitCost"),
      inputComponent: "input",
      placeholder: "Andika hano",
      type: "number",
      required: true,
      value: formValues?.unitPrice,
      onChange: (unitPrice: any) =>
        setFormValues({
          ...formValues,
          unitPrice,
          totalPrice: unitPrice * formValues.weight,
          paid: unitPrice * formValues.weight,
        }),
    },
    {
      label: t("totalPrice"),
      inputComponent: "input",
      placeholder: "Andika hano",
      type: "number",
      value: formValues?.totalPrice,
      onChange: (totalPrice: any) =>
        setFormValues({ ...formValues, totalPrice }),
    },
    {
      label: t("paid"),
      inputComponent: "input",
      placeholder: "Andika hano",
      type: "number",
      value: formValues?.paid,
      onChange: (paid: any) => setFormValues({ ...formValues, paid }),
    },
  ];

  return (
    <GenericForm
      open={appState.openForm.open}
      close={() => dispatch(appActions.closeForm())}
      title={
        appState.openForm.action === "add" ? t("addSale") : t("updateSale")
      }
      styles={"horizontal"}
      formElements={newSalesElement}
      onSubmit={submit}
    />
  );
};

export default AddSales;
