import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenericForm from "../../../components/GenericForm";
import { useTranslation } from "../../../i18n";
import { appActions } from "../../../store/app";
import Storage from "../../../utils/storage";
import { liveStockActions } from "../../../store/pig";
import { Death } from "../../../interfaces/Pig/deaths";
import {
  pigCategories,
  pigCategory,
} from "../../../interfaces/Expenses/requisition";
import { Pig, PigGender } from "../../../interfaces/Pig/pig";
import { Birth } from "../../../interfaces/Pig/birth";
import { fetchBirthsAndPigs } from "../../../store/expenses/treatment/treatmentActions";
import { addAction, updateAction } from "../../../store/app/appActions";
import {
  addDeathService,
  updateDeathService,
} from "../../../store/pig/death/deathService";
import { PigState } from "../../../store/pigSlice";

export interface AddDeathProps {
  death?: Death;
}

const AddDeath = (props: AddDeathProps) => {
  const appState = useSelector((state: any) => state.app);
  const token = Storage.getToken();
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null as unknown);
  const pigState: PigState = useSelector((state: any) => state.pig);
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({
    date: new Date().toISOString().substring(0, 10) as string,
    category: pigCategories[1] as pigCategory | any,
    categoryText: "" as string | any,
    birth: {} as any,
    birthText: "" as string,
    pig: {} as Pig | any,
    pigText: "" as string,
    gender: {} as PigGender | any,
    genderText: "" as string | any,
    reason: "" as string,
    boars: 0 as number | any,
    sows: 0 as number | any,
    observation: "" as string,
  });

  React.useEffect(() => {
    dispatch(fetchBirthsAndPigs("", token));
    //   //get pigs
    //   // if (!liveStockState.fetchedPigs) {
    //   dispatch(fetchReproductionsAction("?limit=1000&born=false", token));
    //   // }

    //   if (appState.openForm.action === "update" && props.Death !== undefined) {
    //     setFormValues({
    //       reproduction: props.Death.reproduction,
    //       reproductionText: "",
    //       date: props.Death.date,
    //       boars: props.Death.boars,
    //       sows: props.Death.sows,
    //       weightEstimate: props.Death.weightEstimate,
    //     });
    //   }
  }, []);

  const onPigChanged = (pig: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({ ...formValues, pig, pigText: "" });
    } else {
      setFormValues({ ...formValues, pigText: pig, pig: null });
    }
  };

  const onBirthChanged = (birth: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({
        ...formValues,
        birth,
        birthText: "",
      });
    } else {
      setFormValues({
        ...formValues,
        birthText: birth,
        birth: null,
      });
    }
  };

  const onCategoryChanged = (category: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({ ...formValues, category, categoryText: "" });
    } else {
      setFormValues({ ...formValues, categoryText: category, category: null });
    }
  };

  const handleFileInputChange = (e: any) => {
    // @ts-ignore: Object is possibly 'null'.
    const file = (e.target as HTMLInputElement).files[0];
    setSelectedFile(file);
  };

  const birthValues = pigState?.births?.map((birth: Birth) => {
    return {
      _id: birth._id,
      race: birth?.reproduction?.boar?.race,
      earTag: birth?.reproduction?.sow?.earTag,
      name: `${birth?.reproduction?.sow.earTag} - ${birth?.date.substring(
        0,
        10
      )}`,
    };
  });

  const submit = () => {
    const data = {
      category: formValues.category.id,
      birth: formValues.birth?._id,
      pig: formValues?.pig?._id,
      gender: formValues.gender.id,
      reason: formValues.reason,
      observation: formValues.observation,
      boars: +formValues.boars,
      sows: +formValues.sows,
    };
    const formData = new FormData();
    formData.append("category", formValues.category.id);
    if (formValues.category.id === "piglet")
      formData.append("birth", formValues.birth?._id);
    formData.append("pig", formValues?.pig?._id);
    formData.append(
      "earTag",
      formValues.category.id === "pig"
        ? formValues?.pig?.earTag
        : formValues?.birth?.earTag
    );
    formData.append("date", formValues.date);
    formData.append("boars", formValues.boars);
    formData.append("sows", formValues.sows);
    formData.append("reason", formValues.reason);
    formData.append("observation", formValues.observation);
    formData.append("file", selectedFile as File);

    console.log("category", formValues.category.id);
    console.log("birth", formValues.birth?._id);
    console.log("pig", formValues?.pig?._id);
    console.log(
      "earTag",
      formValues.category.id === "pig"
        ? formValues?.pig?.earTag
        : formValues?.birth?.earTag
    );

    console.log("date", formValues.date);
    console.log("boars", formValues.boars);
    console.log("sows", formValues.sows);
    console.log("reason", formValues.reason);
    console.log("observation", formValues.observation);

    if (appState.openForm.action === "add") {
      dispatch(
        addAction(
          addDeathService,
          formData,
          token,
          () => {},
          () => dispatch(liveStockActions.setFetchedDeaths(false))
        )
      );
    } else if (
      appState.openForm.action === "update" &&
      props.death !== undefined
    ) {
      dispatch(
        updateAction(
          updateDeathService,
          props.death._id,
          formData,
          token,
          () => {},
          () => dispatch(liveStockActions.setFetchedDeaths(false))
        )
      );
    }
  };

  const pigLetDeathElements = [
    {
      label: t("dateOfDeath"),
      inputComponent: "date",
      placeholder: "Andika hano",
      required: true,
      value: formValues?.date || "",
      onChange: (date: any) => setFormValues({ ...formValues, date }),
    },
    {
      label: t("category"),
      identifier: "category",
      inputComponent: "select",
      required: true,
      values: pigCategories,
      value: formValues.category?.name || formValues.categoryText,
      onChange: (category: any, found: boolean) =>
        onCategoryChanged(category, found),
    },
    {
      label: t("birth"),
      identifier: "birth",
      inputComponent: "select",
      required: true,
      values: birthValues,
      value: formValues.birth?.name || formValues.birthText,
      onChange: (birth: any, found: boolean) => onBirthChanged(birth, found),
    },
    {
      label: t("numberOfBoars"),
      placeholder: t("numberOfBoars"),
      inputComponent: "input",
      type: "number",
      required: true,
      value: formValues.boars,
      onChange: (boars: number) => setFormValues({ ...formValues, boars }),
    },
    {
      label: t("numberOfSows"),
      placeholder: t("numberOfSows"),
      inputComponent: "input",
      type: "number",
      required: true,
      value: formValues.sows,
      onChange: (sows: number) => setFormValues({ ...formValues, sows }),
    },
    {
      label: t("reason"),
      placeholder: t("reason"),
      inputComponent: "input",
      required: true,
      value: formValues.reason,
      onChange: (reason: string) => setFormValues({ ...formValues, reason }),
    },
    {
      label: t("observation"),
      placeholder: t("observation"),
      inputComponent: "input",
      required: true,
      value: formValues.observation,
      onChange: (observation: string) =>
        setFormValues({ ...formValues, observation }),
    },
    {
      label: t("photo"),
      placeholder: "Ibiro",
      inputComponent: "file",
      type: "number",
      required: true,
      onChange: handleFileInputChange,
    },
  ];

  const pigDeathElements = [
    {
      label: t("dateOfDeath"),
      inputComponent: "date",
      placeholder: "Andika hano",
      required: true,
      value: formValues?.date || "",
      onChange: (date: any) => setFormValues({ ...formValues, date }),
    },
    {
      label: t("category"),
      identifier: "category",
      inputComponent: "select",
      required: true,
      values: pigCategories,
      value: formValues.category?.name || formValues.categoryText,
      onChange: (category: any, found: boolean) =>
        onCategoryChanged(category, found),
    },
    {
      label: t("pig"),
      identifier: "pig",
      inputComponent: "select",
      required: true,
      values: pigState.pigs,
      value: formValues.pig?.earTag || formValues.pigText,
      valueLabel: "earTag",
      onChange: (pig: any, found: boolean) => onPigChanged(pig, found),
    },
    {
      label: t("reason"),
      placeholder: t("reason"),
      inputComponent: "input",
      required: true,
      value: formValues.reason,
      onChange: (reason: string) => setFormValues({ ...formValues, reason }),
    },
    {
      label: t("observation"),
      placeholder: t("observation"),
      inputComponent: "input",
      required: true,
      value: formValues.observation,
      onChange: (observation: string) =>
        setFormValues({ ...formValues, observation }),
    },
    {
      label: t("photo"),
      placeholder: "Ibiro",
      inputComponent: "file",
      type: "number",
      required: true,
      onChange: handleFileInputChange,
    },
  ];

  return (
    <GenericForm
      open={appState.openForm.open}
      close={() => dispatch(appActions.closeForm())}
      title={
        appState.openForm.action === "add"
          ? `${t("add")} ${t("deaths")}`
          : t("updateReproduction")
      }
      styles="horizontal"
      formElements={
        formValues.category?.id === "piglet"
          ? pigLetDeathElements
          : pigDeathElements
      }
      onSubmit={submit}
    />
  );
};

export default AddDeath;
