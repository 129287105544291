import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getRequisitionsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/requisition${query}`, token);
};

export const searchRequisitionsService = async (
  query: string,
  token: string
) => {
  return await HttpRequest.get(
    `${SERVER_URL}/requisition/search${query}`,
    token
  );
};

export const updateRequisitionService = async (
  requisitionId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/requisition/${requisitionId}`,
    data,
    token
  );
};

export const addRequisitionService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/requisition`, data, token);
};

export const deleteRequisitionService = async (
  requisitionId: string,
  token: string
) => {
  return await HttpRequest.delete(
    `${SERVER_URL}/requisition/${requisitionId}`,
    token
  );
};
