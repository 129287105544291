import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenericForm from "../../../components/GenericForm";
import { useTranslation } from "../../../i18n";
import { appActions } from "../../../store/app";
import Storage from "../../../utils/storage";
import { Supplier } from "../../../interfaces/Business/supplier";
import { addAction, updateAction } from "../../../store/app/appActions";
import {
  addSupplierService,
  updateSupplierService,
} from "../../../store/business/supplier/supplierService";
import { businessActions } from "../../../store/business";

export interface AddSupplierProps {
  supplier?: Supplier;
}

const AddSupplier = (props: AddSupplierProps) => {
  const appState = useSelector((state: any) => state.app);
  const token = Storage.getToken();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({
    name: "" as string,
    contact: "" as string,
    tinNumber: null as number | any,
  });

  React.useEffect(() => {
    if (appState.openForm.action === "update" && props.supplier !== undefined) {
      setFormValues({
        name: props.supplier.name,
        contact: props.supplier.contact,
        tinNumber: props.supplier.tinNumber,
      });
    }
  }, []);

  const submit = () => {
    const data = {
      name: formValues.name,
      contact: formValues.contact,
      tinNumber: formValues.tinNumber,
    };

    if (appState.openForm.action === "add") {
      dispatch(
        addAction(
          addSupplierService,
          data,
          token,
          () => {},
          () => dispatch(businessActions.setFetchedSuppliers(false))
        )
      );
    } else if (
      appState.openForm.action === "update" &&
      props.supplier !== undefined
    ) {
      dispatch(
        updateAction(
          updateSupplierService,
          props.supplier._id,
          data,
          token,
          () => {},
          () => dispatch(businessActions.setFetchedSuppliers(false))
        )
      );
    }
  };

  const newStaffElement = [
    {
      label: t("name"),
      inputComponent: "input",
      placeholder: "Andika hano",
      required: true,
      value: formValues?.name || "",
      onChange: (name: any) => setFormValues({ ...formValues, name }),
    },
    {
      label: t("contact"),
      inputComponent: "input",
      placeholder: "Andika hano",
      required: true,
      value: formValues?.contact,
      onChange: (contact: any) => setFormValues({ ...formValues, contact }),
    },
    {
      label: t("tinNumber"),
      inputComponent: "input",
      type: "number",
      placeholder: "Andika hano",
      required: true,
      value: formValues?.tinNumber,
      onChange: (tinNumber: any) => setFormValues({ ...formValues, tinNumber }),
    },
  ];

  return (
    <GenericForm
      open={appState.openForm.open}
      close={() => dispatch(appActions.closeForm())}
      title={
        appState.openForm.action === "add"
          ? t("addSupplier")
          : t("updateSupplier")
      }
      styles={"vertical"}
      formElements={newStaffElement}
      onSubmit={submit}
    />
  );
};

export default AddSupplier;
