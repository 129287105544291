import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenericForm from "../../../components/GenericForm";
import { useTranslation } from "../../../i18n";
import { appActions } from "../../../store/app";
import Storage from "../../../utils/storage";
import { addAction, updateAction } from "../../../store/app/appActions";
import {
  expenseCategories,
  ExpenseCategory,
} from "../../../interfaces/Expenses/expense";
import { Requisition } from "../../../interfaces/Expenses/requisition";
import {
  addRequisitionService,
  updateRequisitionService,
} from "../../../store/expenses/requisitions/requisitionService";
import { expensesActions } from "../../../store/expenses";

export interface AddRequisitionProps {
  requisition?: Requisition;
}

const AddRequisition = (props: AddRequisitionProps) => {
  const appState = useSelector((state: any) => state.app);
  const token = Storage.getToken();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({
    category: {} as ExpenseCategory | any,
    categoryText: "" as string | any,
    description: "" as string,
    quantityRequested: 0 as number,
    unitCost: 0 as number,
  });

  // React.useEffect(() => {
  //   //get pigs
  //   if (!liveStockState.fetchedPigs) {
  //     dispatch(getSowAndBoarsAction("", token));
  //   }

  //   if (
  //     appState.openForm.action === "update" &&
  //     props.requisition !== undefined
  //   ) {
  //     setFormValues({
  //       category: props.requisition.category,
  //       sowText: "",
  //       boar: props.requisition.boar,
  //       boarText: "",
  //       requisitionType: {
  //         name:
  //           props.requisition.requisitionType === "mating"
  //             ? t("mating")
  //             : t("crossBreeding"),
  //         id:
  //           props.requisition.requisitionType === "mating"
  //             ? "mating"
  //             : "cross-breeding",
  //       },
  //       requisitionTypeText: "",
  //       date: props.requisition.date.toString().substring(0, 10),
  //       boarRace: {
  //         name: props.requisition.boarRace,
  //       },
  //       boarRaceText: "",
  //     });
  //   }
  // }, []);

  const onCategoryChanged = (category: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({
        ...formValues,
        category,
        categoryText: "",
      });
    } else {
      setFormValues({
        ...formValues,
        categoryText: category,
        category: null,
      });
    }
  };

  const submit = () => {
    const data = {
      category: formValues.category.id,
      description: formValues.description,
      quantity: formValues.quantityRequested,
      unitCost: formValues.unitCost,
    };

    if (appState.openForm.action === "add") {
      dispatch(
        addAction(
          addRequisitionService,
          data,
          token,
          () => {},
          () => dispatch(expensesActions.setFetchedRequisitions(false))
        )
      );
    } else if (
      appState.openForm.action === "update" &&
      props.requisition !== undefined
    ) {
      dispatch(
        updateAction(
          updateRequisitionService,
          props.requisition._id,
          data,
          token,
          () => {},
          () => dispatch(expensesActions.setFetchedRequisitions(false))
        )
      );
    }
  };

  const newStaffElement = [
    {
      label: t("category"),
      identifier: "category",
      inputComponent: "select",
      required: true,
      values: expenseCategories,
      value: formValues.category?.name || formValues.categoryText,
      onChange: (category: any, found: boolean) =>
        onCategoryChanged(category, found),
    },
    {
      label: t("description"),
      placeholder: "Andika hano",
      inputComponent: "input",
      required: true,
      value: formValues?.description,
      valueLabel: "description",
      onChange: (description: any) =>
        setFormValues({ ...formValues, description }),
    },
    {
      label: t("quantity"),
      placeholder: "Andika amafaranga",
      inputComponent: "input",
      type: "number",
      required: true,
      value: formValues.quantityRequested,
      onChange: (quantityRequested: number) =>
        setFormValues({ ...formValues, quantityRequested }),
    },
    {
      label: t("unitCost"),
      placeholder: "Andika amafaranga",
      inputComponent: "input",
      type: "number",
      required: true,
      value: formValues.unitCost,
      onChange: (unitCost: number) =>
        setFormValues({ ...formValues, unitCost }),
    },
  ];

  return (
    <GenericForm
      open={appState.openForm.open}
      close={() => dispatch(appActions.closeForm())}
      title={
        appState.openForm.action === "add"
          ? `${t("add")} ${t("requisition")}`
          : t("updateRequisition")
      }
      styles="vertical"
      formElements={newStaffElement}
      onSubmit={submit}
    />
  );
};

export default AddRequisition;
