import { Grid } from "@mui/material";
import DashboardCard from "./DashboardCard";
import {
  deleteRed2,
  primaryBlue,
  primaryColor,
  primaryGreen,
} from "../../../assets/colors/colors";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import DiamondIcon from "@mui/icons-material/Diamond";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import styles from "../../index.module.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardAction } from "../../../store/app/dashboard/dashboardActions";
import Storage from "../../../utils/storage";
import { AppState } from "../../../store/app";
import { numberWithCommas } from "../../../utils/functions";

const DashboardTopMetrics = () => {
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const appState: AppState = useSelector((state: any) => state.app);

  useEffect(() => {
    dispatch(getDashboardAction("", token));
  }, []);

  return (
    <Grid className={styles.statsCardContainer} container spacing={2}>
      <Grid item xs={3}>
        <DashboardCard
          title="Amatungo"
          value={appState.dashboard?.pigs}
          accentColor="#aeb5eb"
          icon={<DiamondIcon style={{ color: primaryColor }} />}
        ></DashboardCard>
      </Grid>
      <Grid item xs={3}>
        <DashboardCard
          title="Expenses"
          value={`${numberWithCommas(appState.dashboard?.expenses)} RWF`}
          accentColor="#fadcd7"
          icon={<MoneyOffIcon style={{ color: deleteRed2 }} />}
        />
      </Grid>
      <Grid item xs={3}>
        <DashboardCard
          title="Sales"
          value={`${numberWithCommas(appState.dashboard?.sales)} RWF`}
          accentColor="#c5fcd6"
          icon={<AccountBalanceWalletIcon style={{ color: primaryGreen }} />}
        />
      </Grid>
      <Grid item xs={3}>
        <DashboardCard
          title="Abakozi"
          value={appState.dashboard?.staff}
          accentColor="#bfc2f5"
          icon={<DiamondIcon style={{ color: primaryBlue }} />}
        />
      </Grid>
    </Grid>
  );
};

export default DashboardTopMetrics;
