import { createSlice } from "@reduxjs/toolkit";
import { Birth } from "../../interfaces/Pig/birth";
import { Death } from "../../interfaces/Pig/deaths";
import { Pig } from "../../interfaces/Pig/pig";
import { Reproduction } from "../../interfaces/Pig/reproduction";
import { PigWeight, Weight, WeightItem } from "../../interfaces/Pig/weight";

export interface LiveStockState {
  fetchedReproduction: boolean;
  fetchedBirth: boolean;
  fetchedBirthAt: Date;
  fetchedDeaths: boolean;
  fetchedWeights: boolean;
  fetchedWeightLists: boolean;
  reproduction: Reproduction[];
  weights: Weight[];
  weightItems: WeightItem[];
  pigsLeft: Pig[];
  deaths: Death[];
  pigWeights: PigWeight[];
  pigWeightsTotal: number;
}

const liveStockSlice = createSlice({
  name: "liveStock",
  initialState: {
    fetchedReproduction: false,
    fetchedBirth: false,
    fetchedDeaths: true,
    fetchedWeights: false,
    fetchedWeightLists: false,
    reproduction: [] as Reproduction[],
    pigsLeft: [] as Pig[],
    deaths: [] as Death[],
    weights: [] as Weight[],
    pigWeights: [] as PigWeight[],
    pigWeightsTotal: 0,
  } as LiveStockState,
  reducers: {
    setFetchedReproduction(state, action) {
      state.fetchedReproduction = action.payload;
    },
    setFetchedBirth(state, action) {
      state.fetchedBirth = action.payload;
    },
    setFetchedDeaths(state, action) {
      state.fetchedDeaths = action.payload;
    },
    setReproduction(state, action) {
      state.reproduction = action.payload;
    },
    setFetchedWeights(state, action) {
      state.fetchedWeights = action.payload;
    },
    setFetchedWeightLists(state, action) {
      state.fetchedWeightLists = action.payload;
    },
    setPigsLeft(state, action) {
      state.pigsLeft = action.payload;
    },
    setDeaths(state, action) {
      state.deaths = action.payload;
    },
    setWeights(state, action) {
      state.weights = action.payload;
    },
    setWeightItems(state, action) {
      state.weightItems = action.payload;
    },
    addPigWeights(state, action) {
      state.pigWeights.push(action.payload);
      state.pigWeightsTotal += action.payload.weight;
    },
    clearPigWeights(state) {
      state.pigWeights = [];
      state.pigWeightsTotal = 0;
    },
  },
});

export const liveStockActions = liveStockSlice.actions;

export default liveStockSlice.reducer;
