import axios from "axios";

import { appActions } from ".";
import { SERVER_URL } from "../../utils/constants";
import i18n from "../../i18n";
import { requestFailed, setResponseAction } from "../../utils/functions";

export const getShopProjectPurchases = () => {};

export const createProject = async (data: any) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${SERVER_URL}/project/projects`,
      data: {
        name: data.name,
        category: data.category,
      },
    });
    return response.data.data._id;
  } catch (err: any) {
    console.log(err.response.data.message);
  }
};

// crud action

export const addAction = (
  service: Function,
  data: any,
  token: string,
  refresh: Function,
  onSuccess: Function = () => {}
) => {
  return async (dispatch: any) => {
    dispatch(appActions.setIsSubmitting(true));
    const res = await service(data, token);
    if (requestFailed(res)) {
      dispatch(setResponseAction(res, "fail", res.message));
    } else {
      dispatch(setResponseAction(res, "success", res.message));
      onSuccess();
    }
    dispatch(appActions.setIsSubmitting(false));
    dispatch(appActions.setOpenForm({ open: false, action: "" }));
    refresh();
  };
};

export const updateAction = (
  service: Function,
  id: string,
  data: any,
  token: string,
  refresh: Function,
  onSuccess: Function = () => {}
) => {
  return async (dispatch: any) => {
    dispatch(appActions.setIsSubmitting(true));
    const res = await service(id, data, token);
    if (requestFailed(res)) {
      dispatch(setResponseAction(res, "fail", res.message));
    } else {
      dispatch(setResponseAction(res, "success", res.message));
      onSuccess();
    }
    dispatch(appActions.setIsSubmitting(false));
    dispatch(appActions.setOpenForm({ open: false, action: "" }));
    refresh();
  };
};

export const deleteAction = (
  service: Function,
  itemId: string,
  token: string,
  entity: string,
  refresh: Function,
  onSuccess: Function = () => {}
) => {
  return async (dispatch: any) => {
    dispatch(appActions.setIsSubmitting(true));
    const res = await service(itemId, token);
    if (requestFailed(res)) {
      dispatch(setResponseAction(res, "fail", res.message));
    } else {
      dispatch(
        setResponseAction(
          res,
          "success",
          `${entity} ${i18n.t("deleteSuccess")}`
        )
      );
      onSuccess();
    }
    dispatch(appActions.setIsSubmitting(false));
    dispatch(appActions.setIsDeleting(false));
    refresh();
  };
};
