import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectChangeEvent } from "@mui/material";
import { useTranslation } from "../../../../i18n";
import { AppState, appActions } from "../../../../store/app";
import Storage from "../../../../utils/storage";
import {
  Disease,
  diseaseCategories,
  DiseaseCategory,
  diseaseSeverities,
  DiseaseSeverity,
  Symptom,
} from "../../../../interfaces/Expenses/disease";
import { fetchSymptomssAction } from "../../../../store/expenses/diseases/diseasesActions";
import { expensesActions, ExpensesState } from "../../../../store/expenses";
import GenericForm from "../../../../components/GenericForm";
import { addAction, updateAction } from "../../../../store/app/appActions";
import {
  addDiseaseService,
  updateDiseaseService,
} from "../../../../store/expenses/diseases/diseaseService";

export interface AddStaffProps {
  disease?: Disease;
}

const AddDisease = (props: AddStaffProps) => {
  const appState: AppState = useSelector((state: any) => state.app);
  const expensesState: ExpensesState = useSelector(
    (state: any) => state.expenses
  );
  const token = Storage.getToken();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [personName, setPersonName] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
    let symptoms: Symptom[] = [];
    for (let v of value) {
      const index = expensesState.symptoms.findIndex(
        (symptom) => symptom.name === v
      );
      symptoms.push(expensesState.symptoms[index]);
    }
    console.log("Ss ", symptoms);
    setFormValues({ ...formValues, symptoms });
  };

  const [formValues, setFormValues] = useState({
    name: "" as string,
    category: {} as DiseaseCategory | any,
    categoryText: "" as string | any,
    symptoms: [] as Symptom[],
    severity: {} as DiseaseSeverity | any,
    severityText: "" as string | any,
    prevention: "" as string,
    treatment: "" as string,
  });

  React.useEffect(() => {
    dispatch(fetchSymptomssAction("", token));

    if (appState.openForm.action === "update" && props.disease !== undefined) {
      setFormValues({
        name: props.disease.name,
        category: {
          name: props.disease.category,
        },
        categoryText: "",
        symptoms: props.disease.symptoms,
        severity: {
          name: props.disease.severity,
        },
        severityText: "",
        prevention: props.disease.prevention,
        treatment: props.disease.treatment,
      });
    }
  }, []);

  const onSeverityChanged = (
    severity: string | DiseaseSeverity,
    isObject: boolean
  ) => {
    if (isObject) {
      setFormValues({ ...formValues, severity, severityText: "" });
    } else {
      setFormValues({ ...formValues, severityText: severity, severity: null });
    }
  };

  const onCategoryChanged = (
    category: string | DiseaseCategory,
    isObject: boolean
  ) => {
    if (isObject) {
      setFormValues({ ...formValues, category, categoryText: "" });
    } else {
      setFormValues({ ...formValues, categoryText: category, category: null });
    }
  };

  const submit = () => {
    const data = {
      name: formValues.name,
      category: formValues.category.name,
      severity: formValues.severity.name,
      prevention: formValues.prevention,
      symptoms: formValues.symptoms,
      treatment: formValues.treatment,
    };

    console.log("data ", data);

    if (appState.openForm.action === "add") {
      dispatch(
        addAction(
          addDiseaseService,
          data,
          token,
          () => {},
          () => dispatch(expensesActions.setFetchedDiseases(false))
        )
      );
    } else if (
      appState.openForm.action === "update" &&
      props.disease !== undefined
    ) {
      dispatch(
        updateAction(
          updateDiseaseService,
          props.disease._id,
          data,
          token,
          () => {},
          () => dispatch(expensesActions.setFetchedDiseases(false))
        )
      );
    }
  };

  const newDiseaseElement = [
    {
      label: t("diseaseName"),
      inputComponent: "input",
      placeholder: "Andika Indwara Nshya",
      required: true,
      value: formValues?.name || "",
      onChange: (name: any) => setFormValues({ ...formValues, name }),
    },
    {
      label: t("category"),
      identifier: "category",
      inputComponent: "select",
      required: true,
      values: diseaseCategories,
      value: formValues.category?.name || formValues.categoryText,
      onChange: (category: any, found: boolean) =>
        onCategoryChanged(category, found),
    },
    {
      label: t("severity"),
      identifier: "severity",
      inputComponent: "select",
      required: true,
      values: diseaseSeverities,
      value: formValues.severity?.name || formValues.severityText,
      onChange: (severity: any, found: boolean) =>
        onSeverityChanged(severity, found),
    },
    {
      label: t("treatment"),
      inputComponent: "input",
      placeholder: "Andika umuti uyivura",
      required: true,
      value: formValues?.treatment,
      onChange: (treatment: any) => setFormValues({ ...formValues, treatment }),
    },
    {
      label: t("diseasePrevention"),
      inputComponent: "input",
      placeholder: "Andika uburyo yakirindwa",
      required: true,
      value: formValues?.prevention || "",
      onChange: (prevention: any) =>
        setFormValues({ ...formValues, prevention }),
    },
    {
      label: t("symptoms"),
      inputComponent: "multi_select",
      placeholder: "Hitamo Ibimenyetso Ahangaha",
      required: true,
      value: personName,
      values: expensesState.symptoms,
      handleChange: handleChange,
    },
  ];

  return (
    <GenericForm
      open={appState.openForm.open}
      close={() => dispatch(appActions.closeForm())}
      title={
        appState.openForm.action === "add"
          ? t("addDisease")
          : t("updateDisease")
      }
      styles="horizontal"
      formElements={newDiseaseElement}
      onSubmit={submit}
    />
  );
};

export default AddDisease;
