import {
  Grid,
  Box,
  Tabs,
  Tab,
  CircularProgress,
} from "@mui/material";
import Layout from "../../../components/Layout";
import styles from "../../index.module.scss";
import { primaryBlue } from "../../../assets/colors/colors";
import LineChart from "../../../components/charts/LineChart";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getMonthlyExpensesAction } from "../../../store/app/dashboard/dashboardActions";
import Storage from "../../../utils/storage";
import { AppState } from "../../../store/app";
import DashboardTopMetrics from "./DashboardTopMetrics";
import Notifications from "./Notifications";
import { Typography } from "antd";

const Dashboard = () => {
  const [date, setDate] = useState(new Date().toISOString().substring(0, 7));
  const [activeTab, setActiveTab] = useState(0);
  const appState: AppState = useSelector((state: any) => state.app);
  const handleTabChange = (event: React.SyntheticEvent, tab: number) => {
    setActiveTab(tab);
  };
  const token = Storage.getToken();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMonthlyExpensesAction(`?month=${date}-05`, token));
  }, [date]);

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setDate(newValue);
  };

  const data = {
    labels: appState.monthlyExpenses?.map((expense: any) =>
      expense?._id.substring(8, 10)
    ),
    datasets: [
      {
        label: "My First Dataset",
        data: appState.monthlyExpenses?.map((expense: any) => expense?.total),
        backgroundColor: primaryBlue,
        hoverOffset: 4,
        borderWidth: 2,
        tension: 0.4,
        fill: true,
      },
    ],
  };

  return (
    <Layout>
      <div className="flex justify-between">
      <Typography className="font-bold my-4 text-2xl">Dashboard</Typography>
      <div className="flex items-center">
        <Typography className="font-bold">Month: </Typography>
      <input
                style={{ marginLeft: "1rem" }}
                value={date}
                onChange={onChange}
                type="month"
              />
      </div>
      </div>
      <DashboardTopMetrics />
      <Grid className={styles.statsCardContainer} container spacing={2}>
        <Grid item xs={8}>
          <Box className={styles.chart_container}>
            <Box className={styles.chartContainer}>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                scrollButtons={false}
                aria-label="scrollable prevent tabs example"
              >
                <Tab label="EXPENSES" />
                <Tab label="SALES" />
              </Tabs>
              
              {!appState.fetchingMonthlyExpenses && (
                <>
                  {appState.monthlyExpenses.length > 0 && (
                    <Box className={styles.pCenter}>
                      <div style={{ width: "46rem" }}>
                        {appState?.chartData && <LineChart chartData={data} />}
                      </div>
                    </Box>
                  )}
                  {appState.monthlyExpenses.length === 0 && (
                    <Box className={styles.pCenter}>
                      <div
                        style={{
                          width: "55rem",
                          display: "flex",
                          height: "21rem",
                          justifyContent: "center",
                          textTransform: "uppercase",

                          alignItems: "center",
                        }}
                      >
                        <Typography>No Expenses for this month</Typography>
                      </div>
                    </Box>
                  )}
                </>
              )}
              {appState.fetchingMonthlyExpenses && (
                <Box className={styles.center}>
                  <CircularProgress />
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Notifications />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Dashboard;
