import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getClientsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/client${query}`, token);
};

export const searchClientService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/client/search${query}`, token);
};

export const updateClientService = async (
  clientId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/client/${clientId}`,
    data,
    token
  );
};

export const addClientService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/client`, data, token);
};

export const deleteClientService = async (clientId: string, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/client/${clientId}`, token);
};
