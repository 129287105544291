import { Box, Typography, Avatar, Skeleton } from "@mui/material";
import { deleteRed } from "../../assets/colors/colors";
import styles from "../index.module.scss";
import FileInput from "./FileInput";
import FormCheckBox from "./FormCheckBox";
import FormDatePicker from "./FormDatePicker";
import FormInput from "./FormInput";
import FormMultiSelect from "./FormMultiSelect";
import FormSelect from "./FormSelect";

// export interface FormInputsProps {
//   style: String,

// }

const FormInputs = (props: any) => {
  return (
    <Box
      className={
        props?.style === "horizontal"
          ? styles.forms_input_horizontal
          : styles.forms_input_vertical
      }
    >
      <Box className={styles.display_flex}>
        <Typography className={styles.form_label}>
          {props?.element.label}
        </Typography>
        {props?.element.required && (
          <Avatar
            style={{
              height: 5,
              width: 5,
              display: "inline-block",
              marginRight: "5px",
              backgroundColor: deleteRed,
              // backgroundColor: status === 'closed' ? deleteRed : primaryGreen,
            }}
          >
            {" "}
          </Avatar>
        )}
      </Box>

      {props?.element.inputComponent === "input" && (
        <FormInput
          element={props?.element}
          style={props?.style === "vertical" ? "block" : "iniline"}
        />
      )}
      {props?.element.inputComponent === "select" && (
        <>
        {!props?.element.loading && <FormSelect element={props?.element} />}
        {props?.element.loading && <Skeleton
            className={`${styles.form_input} ${styles.form_select_skeleton}`}
          />}
        </>
      )}
      {props?.element.inputComponent === "date" && (
        <FormDatePicker
          label="Start Date"
          element={props?.element}
          placeholder="Provice Start Date"
          style={props?.style === "vertical" ? "block" : "iniline"}
        />
      )}
      {props?.element.inputComponent === "checkbox" && (
        <FormCheckBox element={props?.element} />
      )}
      {props?.element.inputComponent === "multi_select" && (
        <FormMultiSelect element={props?.element} />
      )}
      {props?.element.inputComponent === "file" && (
        <FileInput element={props?.element} />
      )}
    </Box>
  );
};

export default FormInputs;
