import { User } from "../Application/user";
import { Pig } from "./pig";
import i18n from "../../i18n";

export interface ReproductionColumn {
  id: "sow" | "boar" | "date" | "reproductionType" | "days" | "action";
  label: string;
  minWidth?: number;
  align?: "center";
}

export const reproductionColumns: ReproductionColumn[] = [
  { id: "sow", label: i18n.t("sow"), align: "center" },
  { id: "boar", label: i18n.t("boar"), align: "center" },
  { id: "date", label: i18n.t("date"), align: "center" },
  { id: "reproductionType", label: i18n.t("reproductionType"), align: "center" },
  { id: "days", label: i18n.t("days"), align: "center" },
  { id: "action", label: i18n.t("action"), align: "center" },
];

export interface ReproductionType {
  name: string;
  id: "mating" | "crossBreeding";
}

export const reproductionTypes: ReproductionType[] = [
  { name: i18n.t("mating"), id: "mating" },
  { name: i18n.t("crossBreeding"), id: "crossBreeding" },
];

export interface ReproductionRow {
  sow: string;
  boar: string;
  date: string;
  reproductionType: string;
  days: number;
  action: any;
}

export interface Reproduction {
  _id: string;
  sow: Pig,
  boar: Pig,
  reproductionType: string,
  boarRace: string,
  date: string,
  createdBy?: User;
  createdAt: string;
  updatedAt: string;
}
