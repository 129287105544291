import { Box } from "@mui/material";
import ViewDrawer, { DetailItem } from "../../../components/ViewDrawer";
import { Supplier } from "../../../interfaces/Business/supplier";
import { useTranslation } from "../../../i18n";
import styles from "../../index.module.scss";

export interface SupplierViewProps {
  open: boolean;
  onClose: Function;
  supplier: Supplier;
}

const SupplierView = (props: SupplierViewProps) => {
  const supplier: Supplier = props.supplier;
  const { t } = useTranslation();
  let count = 1;

  return (
    <ViewDrawer open={props.open} onClose={props.onClose}>
      <Box className={styles.item_details}>
      <DetailItem
          index={count}
          key={count++}
          label={t("name")}
          value={supplier?.name}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("contact")}
          value={supplier?.contact}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("tinNumber")}
          value={supplier?.tinNumber}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("createdBy")}
          value={supplier?.createdBy}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("createdAt")}
          value={supplier?.createdAt.substring(0, 10)}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("updatedAt")}
          value={supplier?.updatedAt.substring(0, 10)}
        />
      </Box>
    </ViewDrawer>
  );
};

export default SupplierView;
