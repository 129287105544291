import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "../../../../i18n";
import { AppState, appActions } from "../../../../store/app";
import Storage from "../../../../utils/storage";
import { expensesActions, ExpensesState } from "../../../../store/expenses";
import GenericForm from "../../../../components/GenericForm";
import { Vaccine } from "../../../../interfaces/Expenses/vaccine";
import { fetchDiseasesAction } from "../../../../store/expenses/vaccine/vaccinesActions";
import { addAction, updateAction } from "../../../../store/app/appActions";
import { addVaccineService, updateVaccineService } from "../../../../store/expenses/vaccine/vaccineService";

export interface AddStaffProps {
  vaccine?: Vaccine;
}

const AddVaccine = (props: AddStaffProps) => {
  const appState: AppState = useSelector((state: any) => state.app);
  const expensesState: ExpensesState = useSelector(
    (state: any) => state.expenses
  );
  const token = Storage.getToken();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // name: string;
  // controlledDisease: string;
  // description: string;
  // requiredRounds: number;
  // pigAge: number;
  // dosagePerRound: number;
  // roundCost: number;
  // administration: string;

  const [formValues, setFormValues] = useState({
    name: "" as string,
    sickness: {} as any,
    sicknessText: "" as string,
    description: "" as string,
    requiredRounds: 0 as number,
    pigAge: 0 as number,
    dosagePerRound: 0 as number,
    roundCost: 0 as number,
    administration: "" as string,
  });

  React.useEffect(() => {
    dispatch(fetchDiseasesAction("", token));
    // if (appState.openForm.action === "update" && props.disease !== undefined) {
    //   setFormValues({
    //     name: props.disease.name,
    //     category: {
    //       name: props.disease.category,
    //     },
    //     categoryText: "",
    //     symptoms: props.disease.symptoms,
    //     severity: {
    //       name: props.disease.severity,
    //     },
    //     severityText: "",
    //     prevention: props.disease.prevention,
    //     Vaccine: props.disease.Vaccine,
    //   });
    // }
  }, []);

  const onSicknessChanged = (sickness: any, isObject: boolean) => {
    if (isObject) {
      setFormValues({ ...formValues, sickness, sicknessText: "" });
    } else {
      setFormValues({ ...formValues, sicknessText: sickness, sickness: null });
    }
  };

  const submit = () => {
    const data = {
      name: formValues.name,
      controlledDisease: formValues.sickness._id,
      description: formValues.description,
      requiredRounds: +formValues.requiredRounds,
      pigAge: +formValues.pigAge,
      dosagePerRound: +formValues.dosagePerRound,
      administration: formValues.administration,
    };

    // console.log("Dataaa ", data);

    if (appState.openForm.action === "add") {
      dispatch(
        addAction(
          addVaccineService,
          data,
          token,
          () => {},
          () => dispatch(expensesActions.setFetchedVaccine(false))
        )
      );
    } else if (
      appState.openForm.action === "update" &&
      props.vaccine !== undefined
    ) {
      dispatch(
        updateAction(
          updateVaccineService,
          props.vaccine._id,
          data,
          token,
          () => {},
          () => dispatch(expensesActions.setFetchedVaccine(false))
        )
      );
    }
  };

  const newVaccineElement = [
    {
      label: t("name"),
      inputComponent: "input",
      placeholder: "Andika andi makuru",
      required: true,
      value: formValues?.name,
      onChange: (name: any) => setFormValues({ ...formValues, name }),
    },
    {
      label: t("sickness"),
      identifier: "sickness",
      inputComponent: "select",
      required: true,
      values: expensesState.diseases,
      value: formValues.sickness?.name || formValues.sicknessText,
      onChange: (sickness: any, found: boolean) =>
        onSicknessChanged(sickness, found),
    },
    {
      label: t("description"),
      inputComponent: "input",
      placeholder: "Andika ubuvuzi bwatanzwe",
      required: true,
      value: formValues?.description,
      onChange: (description: any) =>
        setFormValues({ ...formValues, description }),
    },
    {
      label: t("requiredRounds"),
      inputComponent: "input",
      placeholder: "Andika hano",
      required: true,
      value: formValues?.requiredRounds,
      onChange: (requiredRounds: any) =>
        setFormValues({ ...formValues, requiredRounds }),
    },
    {
      label: t("pigAge"),
      inputComponent: "input",
      placeholder: "Andika hano",
      required: true,
      value: formValues?.pigAge,
      onChange: (pigAge: any) => setFormValues({ ...formValues, pigAge }),
    },
    {
      label: t("dosagePerRound"),
      inputComponent: "input",
      placeholder: "Andika hano",
      required: true,
      value: formValues?.dosagePerRound,
      onChange: (dosagePerRound: any) =>
        setFormValues({ ...formValues, dosagePerRound }),
    },
    {
      label: t("roundCost"),
      inputComponent: "input",
      placeholder: "Andika hano",
      required: true,
      value: formValues?.roundCost,
      onChange: (roundCost: any) => setFormValues({ ...formValues, roundCost }),
    },
    {
      label: t("vaccinationDesc"),
      inputComponent: "input",
      placeholder: "Andika ubuvuzi bwatanzwe",
      required: true,
      value: formValues?.administration,
      onChange: (administration: any) =>
        setFormValues({ ...formValues, administration }),
    },
  ];

  return (
    <GenericForm
      open={appState.openForm.open}
      close={() => dispatch(appActions.closeForm())}
      title={
        appState.openForm.action === "add"
          ? t("addVaccine")
          : t("updateDisease")
      }
      styles="horizontal"
      formElements={newVaccineElement}
      onSubmit={submit}
    />
  );
};

export default AddVaccine;
