import { combineReducers, configureStore } from "@reduxjs/toolkit";
// import storage from "redux-persist/lib/storage";
// import { persistReducer, persistStore } from "redux-persist";
// import thunk from "redux-thunk";
import authReducer from "./auth";
import appReducer from "./app";
import liveStockReducer from "./pig";
import expensesReducer from "./expenses";
import businessReducer from "./business";
import pigReducer from "./pigSlice";

// const rootReducer = combineReducers({
//   auth: authReducer,
//   app: appReducer,
//   liveStock: liveStockReducer,
//   expenses: expensesReducer,
//   business: businessReducer,
// });

// const persistConfig = {
//   key: 'root',
//   storage
// }

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: [thunk]
// });

// const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: {
    auth: authReducer,
    app: appReducer,
    liveStock: liveStockReducer,
    expenses: expensesReducer,
    business: businessReducer,
    pig: pigReducer,
  },
});

export default store;

// export const persistor = persistStore(store);
