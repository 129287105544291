import { Typography, Box, Button } from "@mui/material";
import styles from "../../../index.module.scss";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import TableComponent from "../../../../components/TableComponent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState, appActions } from "../../../../store/app";
import AddIcon from "@mui/icons-material/Add";
import Storage from "../../../../utils/storage";
import LoadingContainer from "../../../../components/LoadingContainer";
import { useTranslation } from "../../../../i18n";
import PopupMenu from "../../../../components/PopupMenu";
import ConfirmModal from "../../../../components/ConfirmModal";
import { deleteAction } from "../../../../store/app/appActions";
import SearchComponent from "../../../../components/SearchComponent";
import ChipComponent, { ChipData } from "../../../../components/ChipComponent";
import { LoadingButton } from "@mui/lab";
import { expensesActions, ExpensesState } from "../../../../store/expenses";
import VaccinationView from "./VaccinationView";
import FilterVaccination from "./FilterVaccination";
import AddVaccination from "./AddVaccination";
import { numberWithCommas } from "../../../../utils/functions";
import {
  exportVaccinationAction,
  getVaccinationsAction,
  searchVaccinationAction,
} from "../../../../store/expenses/vaccination/vaccinationActions";
import {
  Vaccination,
  vaccinationColumns,
  VaccinationRow,
} from "../../../../interfaces/Expenses/vaccination";
import { deleteVaccinationService } from "../../../../store/expenses/vaccination/vaccinationService";

const Vaccinations = () => {
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(50);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [query, setQuery] = useState("");
  const [isInit, setIsInit] = useState(true);
  const dispatch = useDispatch();
  const appState: AppState = useSelector((state: any) => state.app);
  const expensesState: ExpensesState = useSelector(
    (state: any) => state.expenses
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [chipData, setChipData] = useState([]);
  const [vaccination, setVaccination] = useState<any>();
  const [viewVaccination, setViewVaccination] = useState<boolean>();
  const open = Boolean(anchorEl);
  const token = Storage.getToken();
  const { t } = useTranslation();

  console.log("Vaccination reload");

  useEffect(() => {
    dispatch(
      getVaccinationsAction(
        `?page=${page}&sort=-createdAt&limit=${rowsPerPage}`,
        token
      )
    );
  }, []);

  useEffect(() => {
    if (!expensesState.fetchedVaccinations) {
      dispatch(
        getVaccinationsAction(
          `?page=${page}&sort=-createdAt&limit=${rowsPerPage}${query}`,
          token
        )
      );
    }
  }, [
    dispatch,
    expensesState.fetchedVaccinations,
    page,
    query,
    rowsPerPage,
    token,
  ]);

  useEffect(() => {
    if (!appState.isFetching) {
      setCount(appState.count);
    }
  }, [appState.isFetching, appState.count]);

  useEffect(() => {
    if (chipData.length > 0) {
      let queryString = "";
      chipData.forEach((data: any) => {
        queryString += `&${data.id}=${data.filter}`;
      });
      setQuery(queryString);
      dispatch(expensesActions.setFetchedVaccination(false));
    } else if (chipData.length === 0 && query !== "") {
      setQuery("");
      dispatch(expensesActions.setFetchedVaccination(false));
    }
  }, [chipData, dispatch, query]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (searchText) {
        dispatch(searchVaccinationAction(`?text=${searchText}`, token));
        setIsInit(false);
      } else {
        if (!appState.isFetching && !isInit) {
          dispatch(expensesActions.setFetchedVaccination(false));
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [appState.isFetching, dispatch, isInit, searchText, token]);

  // date: string;
  // title: string;
  // vaccine: string;
  // pigs: number;
  // round: string;
  // totalValue: number;

  const createData = (vaccination: Vaccination): VaccinationRow => {
    return {
      date: vaccination?.date?.substring(0, 10),
      title: vaccination?.title,
      vaccine: vaccination?.vaccine?.name,
      pigs: 12,
      round: vaccination?.round,
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setVaccination(vaccination);
            setAnchorEl(event.currentTarget);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(expensesActions.setFetchedVaccination(false));
  };

  const filterHandler = (filterData: any) => {
    setChipData(filterData);
  };

  const handleChipDelete = (chipToDelete: ChipData) => {
    setChipData((chips) =>
      chips.filter((chip: ChipData) => chip.key !== chipToDelete.key)
    );
  };

  const exportExcelHandler = () => {
    dispatch(exportVaccinationAction("?limit=1000", token, t("Vaccinations")));
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(expensesActions.setFetchedVaccination(false));
  };

  const deleteStaffHandler = () => {
    dispatch(
      deleteAction(
        deleteVaccinationService,
        vaccination?._id,
        token,
        t("staff"),
        () => {},
        () => dispatch(expensesActions.setFetchedVaccination(false))
      )
    );
  };

  const rows = expensesState.vaccinations?.map((vaccination: Vaccination) =>
    createData(vaccination)
  );

  return (
    <>
      <Box className={styles.topContainer}>
        <Box>{t("welcome")}</Box>
        <Box className={styles.dFlex}>
          <SearchComponent setSearchText={setSearchText} />
          <LoadingButton
            variant="contained"
            startIcon={<SaveAltIcon />}
            loading={appState.isExportingExcel}
            onClick={exportExcelHandler}
            className={styles.exportExcelButton}
          >
            {t("exportExcel")}
          </LoadingButton>
        </Box>
      </Box>
      {appState.isFetching && <LoadingContainer />}
      {!appState.isFetching && (
        <>
          {chipData.length > 0 && (
            <ChipComponent
              chipData={chipData}
              handleDelete={handleChipDelete}
            />
          )}
          <Box className={styles.tableTopActions}>
            <Box>
              <Typography>{t("Vaccinations")}</Typography>
            </Box>
            <Box>
              <Button
                onClick={() => dispatch(appActions.setOpenFilter(true))}
                startIcon={<AddIcon />}
              >
                {t("filter")}
              </Button>
              <Button
                onClick={() =>
                  dispatch(
                    appActions.setOpenForm({ open: true, action: "add" })
                  )
                }
                startIcon={<AddIcon />}
              >
                {t("addNew")}
              </Button>
            </Box>
          </Box>
          <TableComponent
            rows={rows}
            columns={vaccinationColumns}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
          {open && (
            <PopupMenu
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              onView={() => setViewVaccination(true)}
              onUpdate={() =>
                dispatch(
                  appActions.setOpenForm({ open: true, action: "update" })
                )
              }
              onDelete={() => dispatch(appActions.setIsDeleting(true))}
            />
          )}
          {appState.isDeleting && (
            <ConfirmModal
              onConfirm={() => deleteStaffHandler()}
              content={`${t("confirmDeleteDesc")} ${
                vaccination?.sickness?.name
              } ?`}
            />
          )}
          {appState.openForm.open && (
            <AddVaccination Vaccination={vaccination} />
          )}
          {appState.openFilter && (
            <FilterVaccination
              open={appState.openFilter}
              close={() => dispatch(appActions.setOpenFilter(false))}
              filterHandler={filterHandler}
            />
          )}
          {viewVaccination && (
            <VaccinationView
              open={viewVaccination}
              onClose={() => setViewVaccination(false)}
              vaccination={vaccination}
            />
          )}
        </>
      )}
    </>
  );
};

export default Vaccinations;
