import { Avatar, Box, Typography } from "@mui/material";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Notification } from "../../../interfaces/Application/notification";
import moment from "moment";

export interface NotificationItemProps {
  notification: Notification;
}

const NotificationItem = (props: NotificationItemProps) => {
  return (
    <Box className="notification_item">
      <Avatar style={{ height: "30px", width: "30px", marginRight: "0.5rem" }}>
        K
      </Avatar>
      <Box>
        <Typography className="notification_message">
          {props.notification.message}
        </Typography>
        <Box className="notification_bottom_content">
          <Box></Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              width: "auto",
            }}
          >
            <ScheduleIcon style={{ height: "15px", width: "15px" }} />
            <Typography className="notification_time">{moment(props.notification.createdAt).fromNow()}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationItem;
