import { Chip, Stack } from "@mui/material";

export interface ChipData {
  key: number;
  filter: any;
  id: string;
  label: string;
  value: any;
}

export interface ChipComponentProps {
  chipData: ChipData[];
  handleDelete: Function;
}

const ChipComponent = (props: ChipComponentProps) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      style={{ marginTop: "1rem", marginBottom: "1.5rem" }}
    >
      {props.chipData.map((data: any) => {
        const value = data?.value ? data.value : data.filter;
        return (
          <Chip
            key={data.key}
            label={`${data.label}: ${value}`}
            onDelete={() => {
              console.log("dd ", data);
              props.handleDelete(data);
            }}
          />
        );
      })}
    </Stack>
  );
};

export default ChipComponent;
