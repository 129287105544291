import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getExpensesService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/expense${query}`, token);
};

export const searchExpensesService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/expense/search${query}`, token);
};

export const updateExpenseService = async (
  expenseId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/expense/${expenseId}`,
    data,
    token
  );
};

export const addExpenseService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/expense`, data, token);
};

export const deleteExpenseService = async (
  expenseId: string,
  token: string
) => {
  return await HttpRequest.delete(`${SERVER_URL}/expense/${expenseId}`, token);
};
