import { Typography, Box, Button } from "@mui/material";
import Layout from "../../../components/Layout";
import styles from "../../index.module.scss";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import TableComponent from "../../../components/TableComponent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  exportStaffAction,
  getStaffAction,
  searchStaffAction,
} from "../../../store/app/staff/staffActions";
import {
  staffColumns,
  StaffI,
  StaffRow,
} from "../../../interfaces/Application/staff";
import { AppState, appActions } from "../../../store/app";
import AddIcon from "@mui/icons-material/Add";
import Storage from "../../../utils/storage";
import AddStaff from "./AddStaff";
import LoadingContainer from "../../../components/LoadingContainer";
import { useTranslation } from "../../../i18n";
import PopupMenu from "../../../components/PopupMenu";
import ConfirmModal from "../../../components/ConfirmModal";
import { deleteAction } from "../../../store/app/appActions";
import { deleteStaffService } from "../../../store/app/staff/staffService";
import StaffView from "./StaffView";
import SearchComponent from "../../../components/SearchComponent";
import FilterStaff from "./FilterStaff";
import ChipComponent, { ChipData } from "../../../components/ChipComponent";
import { LoadingButton } from "@mui/lab";

const Staff = (props: any) => {
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(50);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [query, setQuery] = useState("");
  const [isInit, setIsInit] = useState(true);
  const dispatch = useDispatch();
  const appState: AppState = useSelector((state: any) => state.app);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [chipData, setChipData] = useState([]);
  const [staff, setStaff] = useState<any>();
  const [viewStaff, setViewStaff] = useState<boolean>();
  const open = Boolean(anchorEl);
  const token = Storage.getToken();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(
      getStaffAction(
        `?page=${page}&sort=-createdAt&limit=${rowsPerPage}`,
        token
      )
    );
  }, []);

  useEffect(() => {
    if (!appState.fetchedStaff) {
      dispatch(
        getStaffAction(
          `?page=${page}&sort=-createdAt&limit=${rowsPerPage}${query}`,
          token
        )
      );
    }
  }, [appState.fetchedStaff]);

  useEffect(() => {
    if (!appState.isFetching) {
      setCount(appState.count);
    }
  }, [appState.isFetching, appState.count]);

  useEffect(() => {
    if (chipData.length > 0) {
      let queryString = "";
      chipData.forEach((data: any) => {
        queryString += `&${data.id}=${data.filter}`;
      });
      setQuery(queryString);
      dispatch(appActions.setFetchedStaff(false));
    } else if (chipData.length === 0 && query !== "") {
      setQuery("");
      dispatch(appActions.setFetchedStaff(false));
    }
  }, [chipData]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (searchText) {
        dispatch(searchStaffAction(`?text=${searchText}`, token));
        setIsInit(false);
      } else {
        if (!appState.isFetching && !isInit) {
          dispatch(appActions.setFetchedStaff(false));
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [searchText]);

  const createData = (staff: StaffI): StaffRow => {
    return {
      _id: staff._id,
      firstName: staff.firstName,
      lastName: staff.lastName,
      email: staff.email ? staff.email : "Not Set",
      nationalID: staff.nationalID,
      position: staff.position,
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setStaff(staff);
            setAnchorEl(event.currentTarget);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(appActions.setFetchedStaff(false));
  };

  const filterHandler = (filterData: any) => {
    setChipData(filterData);
  };

  const handleChipDelete = (chipToDelete: ChipData) => {
    setChipData((chips) =>
      chips.filter((chip: ChipData) => chip.key !== chipToDelete.key)
    );
  };

  const exportExcelHandler = () => {
    dispatch(exportStaffAction("?limit=1000", token, t("staff")));
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(appActions.setFetchedStaff(false));
  };

  const deleteStaffHandler = () => {
    dispatch(
      deleteAction(
        deleteStaffService,
        staff?._id,
        token,
        t("staff"),
        () => {},
        () => dispatch(appActions.setFetchedStaff(false))
      )
    );
  };

  const rows = appState.staff?.map((staff: StaffI) => createData(staff));

  return (
    <Layout>
      <Box className={styles.topContainer}>
        <Box>{t("welcome")}</Box>
        <Box className={styles.dFlex}>
          <SearchComponent setSearchText={setSearchText} />
          <LoadingButton
            variant="contained"
            startIcon={<SaveAltIcon />}
            loading={appState.isExportingExcel}
            onClick={exportExcelHandler}
            className={styles.exportExcelButton}
          >
            {t("exportExcel")}
          </LoadingButton>
        </Box>
      </Box>
      {appState.isFetching && <LoadingContainer />}
      {!appState.isFetching && (
        <>
          {chipData.length > 0 && (
            <ChipComponent
              chipData={chipData}
              handleDelete={handleChipDelete}
            />
          )}
          <Box className={styles.tableTopActions}>
            <Box>
              <Typography>{t("staff")}</Typography>
            </Box>
            <Box>
              <Button
                onClick={() => dispatch(appActions.setOpenFilter(true))}
                startIcon={<AddIcon />}
              >
                {t("filter")}
              </Button>
              <Button
                onClick={() =>
                  dispatch(
                    appActions.setOpenForm({ open: true, action: "add" })
                  )
                }
                startIcon={<AddIcon />}
              >
                {t("addNew")}
              </Button>
            </Box>
          </Box>
          <TableComponent
            rows={rows}
            columns={staffColumns}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
          {open && (
            <PopupMenu
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              onView={() => setViewStaff(true)}
              onUpdate={() =>
                dispatch(
                  appActions.setOpenForm({ open: true, action: "update" })
                )
              }
              onDelete={() => dispatch(appActions.setIsDeleting(true))}
            />
          )}
          {appState.isDeleting && (
            <ConfirmModal
              onConfirm={() => deleteStaffHandler()}
              content={`${t("confirmDeleteDesc")} ${staff?.firstName} ${
                staff?.lastName
              } ?`}
            />
          )}
          {appState.openForm.open && <AddStaff staff={staff} />}
          {appState.openFilter && (
            <FilterStaff
              open={appState.openFilter}
              close={() => dispatch(appActions.setOpenFilter(false))}
              filterHandler={filterHandler}
            />
          )}
          {viewStaff && (
            <StaffView
              open={viewStaff}
              onClose={() => setViewStaff(false)}
              staff={staff}
            />
          )}
        </>
      )}
    </Layout>
  );
};

export default Staff;
