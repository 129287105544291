import { User } from "../Application/user";
import { Birth } from "../Pig/birth";
import { Pig } from "../Pig/pig";
import { Vaccine } from "./vaccine";
import i18n from "../../i18n";

export interface VaccinationColumn {
  id:
    | "date"
    | "title"
    | "vaccine"
    | "pigs"
    | "round"
    | "totalValue"
    | "action";
  label: string;
  minWidth?: number;
  align?: "center";
}

export const vaccinationColumns: VaccinationColumn[] = [
  {
    id: "date",
    label: i18n.t("date"),
    align: "center",
  },
  { id: "title", label: i18n.t("title"), align: "center" },
  { id: "vaccine", label: i18n.t("vaccine"), align: "center" },
  { id: "pigs", label: i18n.t("pigs"), align: "center" },
  { id: "round", label: i18n.t("round"), align: "center" },
  { id: "totalValue", label: i18n.t("totalValue"), align: "center" },
  { id: "action", label: i18n.t("action"), align: "center" },
];

export interface VaccinationRow {
  date: string;
  title: string;
  vaccine: string;
  pigs: number;
  round: number;
  action: any;
}
export interface Vaccination {
  _id: string;
  title: string;
  vaccine: Vaccine;
  date: string;
  round: number;
  pig: Pig;
  birthID: Birth;
  roundCost: number,
  createdBy?: User;
  createdAt: string;
  updatedAt: string;
}
