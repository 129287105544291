import { createSlice } from "@reduxjs/toolkit";
import { Pig } from "../../interfaces/Pig/pig";
import { Birth } from "../../interfaces/Pig/birth";

export interface PigState {
  fetchedPigs: boolean;
  fetchingForm: boolean;
  fetchedForm: boolean;
  pigs: Pig[];
  births: Birth[];
  boars: Pig[];
  sows: Pig[];
  count: number;
  fetchedPigsAt: Date;
}

const pigSlice = createSlice({
  name: "pig",
  initialState: {
    fetchedPigs: false,
    fetchingForm: false,
    fetchedForm: false,
    pigs: [],
    births: [],
    boars: [],
    sows: [],
    count: 0,
    fetchedPigsAt: new Date("1900-01-01"),
  } as PigState,
  reducers: {
    setFetchedPigs(state, action) {
      state.fetchedPigs = action.payload;
    },
    setFetchedPigsAt(state, action) {
      state.fetchedPigsAt = action.payload;
    },
    setPigs(state, action) {
      state.pigs = action.payload;
    },
    setFetchingForm(state, action) {
      state.fetchingForm = action.payload;
    },
    setFetchedForm(state, action) {
      state.fetchedForm = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    setBoars(state, action) {
      state.boars = action.payload;
    },
    setSows(state, action) {
      state.sows = action.payload;
    },
    setBirths(state, action) {
      state.births = action.payload;
    },
  },
});

export const pigActions = pigSlice.actions;

export default pigSlice.reducer;
