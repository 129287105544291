import { Box, Divider, Drawer } from "@mui/material";
import styles from "./index.module.scss";
import { Typography } from "antd";

export interface ViewDrawerProps {
  open: boolean;
  onClose: Function;
  children: any;
}

const ViewDrawer = (props: ViewDrawerProps) => {
  return (
    <Drawer
      PaperProps={{
        sx: { width: "50vw", padding: "2rem 1rem", background: "#f4f5f7" },
      }}
      anchor={"right"}
      open={props.open}
      onClose={() => props.onClose()}
    >
      {props.children}
    </Drawer>
  );
};

export default ViewDrawer;

interface detailItemProps {
  label: string;
  value: any;
  index: number;
}

export const DetailItem = (props: detailItemProps) => {
  console.log("dd ", props.label, props.value);
  return (
    <>
      <Box
        className={styles.item_detail}
      >
        <Typography className="font-bold">{props.label}</Typography>
        <Typography>{props.value}</Typography>
      </Box>
      <Divider />
    </>
  );
};
