import { Box } from "@mui/material";
import ViewDrawer, { DetailItem } from "../../../components/ViewDrawer";
import { useTranslation } from "../../../i18n";
import styles from "../../index.module.scss";
import { Sale } from "../../../interfaces/Business/sales";

export interface SupplierViewProps {
  open: boolean;
  onClose: Function;
  sales: Sale;
}

const SalesView = (props: SupplierViewProps) => {
  const sales: Sale = props.sales;
  const { t } = useTranslation();
  let count = 1;

  return (
    <ViewDrawer open={props.open} onClose={props.onClose}>
      <Box className={styles.item_details}>
      <DetailItem
          index={count}
          key={count++}
          label={t("date")}
          value={sales?.date}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("client")}
          value={sales?.client?.name}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("pig")}
          value={sales?.pig?.earTag}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("price")}
          value={sales?.unitPrice}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("createdBy")}
          value={sales?.createdBy}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("createdAt")}
          value={sales?.createdAt.substring(0, 10)}
        />
        <DetailItem
          index={count}
          key={count++}
          label={t("updatedAt")}
          value={sales?.updatedAt.substring(0, 10)}
        />
      </Box>
    </ViewDrawer>
  );
};

export default SalesView;
