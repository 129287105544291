import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getDiseasesService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/disease${query}`, token);
};

export const getSymptomsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/disease/symptoms${query}`, token);
};

export const searchDiseasesService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/disease/search${query}`, token);
};

export const updateDiseaseService = async (
  diseaseId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/disease/${diseaseId}`,
    data,
    token
  );
};

export const addDiseaseService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/disease`, data, token);
};

export const deleteDiseaseService = async (
  diseaseId: string,
  token: string
) => {
  return await HttpRequest.delete(`${SERVER_URL}/disease/${diseaseId}`, token);
};
