import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import Layout from "../../../components/Layout";
import Vaccinations from "./vaccinations/Vaccinations";
import Vaccines from "./vaccines/Vaccines";

const VaccinationMain = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        scrollButtons={false}
        aria-label="sure false"
      >
        <Tab label="Vaccination" />
        <Tab label="Vaccines" />
      </Tabs>
      {activeTab === 0 && <Vaccinations />}
      {activeTab === 1 && <Vaccines />}
    </>
  );
};
export default VaccinationMain;
