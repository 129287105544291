import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenericForm from "../../../components/GenericForm";
import { useTranslation } from "../../../i18n";
import { appActions } from "../../../store/app";
import Storage from "../../../utils/storage";
import { addAction, updateAction } from "../../../store/app/appActions";
import { businessActions } from "../../../store/business";
import { Client } from "../../../interfaces/Business/client";
import {
  addClientService,
  updateClientService,
} from "../../../store/business/client/clientService";

export interface AddclientProps {
  client?: Client;
}

const AddClient = (props: AddclientProps) => {
  const appState = useSelector((state: any) => state.app);
  const token = Storage.getToken();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({
    name: "" as string,
    contact: "" as string,
    tinNumber: null as number | any,
  });

  React.useEffect(() => {
    if (appState.openForm.action === "update" && props.client !== undefined) {
      setFormValues({
        name: props.client.name,
        contact: props.client.contact,
        tinNumber: props.client.tinNumber,
      });
    }
  }, []);

  const submit = () => {
    const data = {
      name: formValues.name,
      contact: formValues.contact,
      tinNumber: formValues.tinNumber,
    };

    if (appState.openForm.action === "add") {
      dispatch(
        addAction(
          addClientService,
          data,
          token,
          () => {},
          () => dispatch(businessActions.setFetchedClients(false))
        )
      );
    } else if (
      appState.openForm.action === "update" &&
      props.client !== undefined
    ) {
      dispatch(
        updateAction(
          updateClientService,
          props.client._id,
          data,
          token,
          () => {},
          () => dispatch(businessActions.setFetchedClients(false))
        )
      );
    }
  };

  const newStaffElement = [
    {
      label: t("name"),
      inputComponent: "input",
      placeholder: "Andika hano",
      required: true,
      value: formValues?.name || "",
      onChange: (name: any) => setFormValues({ ...formValues, name }),
    },
    {
      label: t("contact"),
      inputComponent: "input",
      placeholder: "Andika hano",
      required: true,
      value: formValues?.contact,
      onChange: (contact: any) => setFormValues({ ...formValues, contact }),
    },
    {
      label: t("tinNumber"),
      inputComponent: "input",
      type: "number",
      placeholder: "Andika hano",
      required: true,
      value: formValues?.tinNumber,
      onChange: (tinNumber: any) => setFormValues({ ...formValues, tinNumber }),
    },
  ];

  return (
    <GenericForm
      open={appState.openForm.open}
      close={() => dispatch(appActions.closeForm())}
      title={
        appState.openForm.action === "add" ? t("addclient") : t("updateclient")
      }
      styles={"vertical"}
      formElements={newStaffElement}
      onSubmit={submit}
    />
  );
};

export default AddClient;
